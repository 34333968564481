// fetch the large, unoptimized version of the SDK
import Client from 'shopify-buy/index.unoptimized.umd';
import { gql } from 'babel-plugin-graphql-js-client-transform';

// Used for My Page
const headlessClient = Client.buildClient({
  domain: process.env.REACT_APP_SHOPIFY_NAME,
  storefrontAccessToken: process.env.REACT_APP_HEADLESS_ACCESS_TOKEN,
});
const headlessClientGraphQLClient = headlessClient.graphQLClient;

// Used for Merch designer
const customAppClient = Client.buildClient({
  domain: process.env.REACT_APP_SHOPIFY_NAME,
  storefrontAccessToken: process.env.REACT_APP_CUSTOM_APP_ACCESS_TOKEN,
});
const customAppGraphQLClient = customAppClient.graphQLClient;

export const getEnabledProducts = async (colTitle) => {
  const query = gql(headlessClientGraphQLClient)`
    query getProductsInCollection($handle: String!) {
      collectionByHandle(handle: $handle) {
        products(first: 10) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          edges {
            node {
              id
              title
              images(first: 10) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                edges {
                  node {
                    src
                  }
                }
              }
              variants(first: 10) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                edges {
                  node {
                    title
                    price {
                      amount
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
  const response = await headlessClientGraphQLClient.send(query, {
    handle: colTitle,
  });
  console.log('response:', response);
  const { model } = response;
  const products =
    model && model.collectionByHandle ? model.collectionByHandle.products : [];
  return products;
};

export const getAllProducts = async (colTitle) => {
  const query = gql(customAppGraphQLClient)`
    query getProductsInCollection($handle: String!) {
      collectionByHandle(handle: $handle) {
        products(first: 10) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          edges {
            node {
              id
              title
              images(first: 10) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                edges {
                  node {
                    src
                  }
                }
              }
              variants(first: 10) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                edges {
                  node {
                    title
                    price {
                      amount
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
  const { model } = await customAppGraphQLClient.send(query, {
    handle: colTitle,
  });
  const products =
    model && model.collectionByHandle ? model.collectionByHandle.products : [];
  return products;
};

export const createCheckout = async () => {
  // Create an empty checkout
  const checkout = await headlessClient.checkout.create();
  return checkout;
};

export const addItemsToCheckout = async (checkoutID, lineItems) => {
  const checkout = await headlessClient.checkout.addLineItems(
    checkoutID,
    lineItems
  );
  return checkout;
};

export const removeItemsFromCheckout = async (checkoutID, lineItemIDs) => {
  const checkout = await headlessClient.checkout.removeLineItems(
    checkoutID,
    lineItemIDs
  );
  return checkout;
};

export const productIdNumToUri = (num) => {
  return `gid://shopify/Product/${num}`;
};

export const productIdUriToNum = (url) => {
  const parts = url.split('/');
  return parts[parts.length - 1];
};
