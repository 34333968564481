import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import moment from 'moment'
import { Table, Tooltip, Avatar, Spin } from 'antd'
import { NavLink } from 'react-router-dom'
import {
  UserOutlined,
  LinkOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux'
import { getAdminPayouts, getAdminPayoutPayments, abortAdminPayoutPayments } from '../../../../actions'
import { EmptyTableMessage } from '../../../../utils/CustomIcons'
import './PayoutsList.scss'

export default function PayoutsList() {
  const { isLoading, data, pagination, rehydrated } = useSelector(state => state.admin.payouts)
  const s3Base = useSelector(state => state.config.s3Base)
  const dispatch = useDispatch()
  const [expandedRows, setExpandedRows] = useState([]);

  useEffect(() => {
    if(!rehydrated){
      // Requesting the payments on first load.
      dispatch(getAdminPayouts({
        sortField: 'createdAt',
        sortOrder: 'descend',
        pagination
      }))
    }
  }, [dispatch, pagination, rehydrated])


  const onRow = (record, rowIndex) => expandedRows.includes(record.id) && { className: "expanded" }

  const onExpandedRowsChange = (expandedRows) => {
    setExpandedRows(expandedRows)
  }

  const onExpand = (expanded, record) => {
    if(expanded){
      dispatch(getAdminPayoutPayments(record.id, record.paymentIds));
    } else if(record.payments.isLoading) {
      // Here we force to stop the request if the user collapce the element.
      dispatch(abortAdminPayoutPayments(record.id))
    }
  }

  const columns = [
    {
      title: '',
      dataIndex: 'musicianName',
      // sorter: (a, b) => a.amount - b.amount,
      render: (musician, record) => {
        if(record.avatar){
          return (
            <NavLink
              className=""
              activeClassName="active"
              to={`/u/${record.slug}`}
              href={`/u/${record.slug}`}
            >
              <section className="status-value">
                <div className="custom-row-avatar" style={{ backgroundImage: `url(${s3Base + record.avatar})`}}></div>
              </section>
            </NavLink>)
        }
        else if(!record.avatar){
          return (
            <NavLink
              className=""
              activeClassName="active"
              to={`/u/${record.slug}`}
              href={`/u/${record.slug}`}
            >
              <section className="status-value"><Avatar icon={<UserOutlined />} /></section>
            </NavLink>)
        }
      },
      width: '60px'
    },
    {
      title: "Band Name",
      dataIndex: 'musicianName',
      // sorter: (a, b) => {
      //     var textA = a.firstName ? a.firstName.toLowerCase() : '';
      //     var textB = b.firstName ? b.firstName.toLowerCase() : '';
      //     return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      // },
      sorter: true,
      render: (musicianName, record) => (<section className="date-value">

        <NavLink
          className=""
          activeClassName="active"
          to={`/u/${record.slug}`}
          href={`/u/${record.slug}`}
        >
          <Tooltip title="Go to Profile">
            {musicianName} <LinkOutlined />
          </Tooltip>

        </NavLink>
      </section>),
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      sorter: (a, b) => moment(`${a.month} ${a.year}`, 'M YYYY').format('YYYYMM') - moment(`${b.month} ${b.year}`, 'M YYYY').format('YYYYMM'),
      // sorter: true,
      defaultSortOrder: 'descend',
      render: (createdAt, record) => (<section className="date-value">{moment(`${record.month} ${record.year}`, 'M YYYY').format('MMMM YYYY')}</section>),
    },


    {
      title: 'Source Amount',
      dataIndex: 'sourceAmount',
      sorter: (a, b) => a.sourceAmount - b.sourceAmount,
      render: (sourceAmount, record) => (<section className="earnings-value">${sourceAmount.toFixed(2)} <span style={{textTransform: 'uppercase'}}>{record.currency}</span></section>),
    },

    {
      title: 'Sent Payout',
      dataIndex: 'amountAfterStripeAndAppFees',
      sorter: (a, b) => a.amountAfterStripeAndAppFees - b.amountAfterStripeAndAppFees,
      render: (amountAfterStripeAndAppFees, record) => (<section className="earnings-value">${amountAfterStripeAndAppFees.toFixed(2)} <span style={{textTransform: 'uppercase'}}>{record.currency}</span></section>),
    },

    // {
    //   title: 'Stripe & App Fees',
    //   dataIndex: 'stripeAndApplicationFees',
    //   sorter: (a, b) => a.stripeAndApplicationFees - b.stripeAndApplicationFees,
    //   render: (stripeAndApplicationFees, record) => (<section className="earnings-value">
    //     ${stripeAndApplicationFees } <span style={{textTransform: 'uppercase'}}>{record.currency}</span>
    //     <Tooltip placement="right" className="extra-info" title={`Stripe Fee Applied: ${record.appFeePercent}%`}><InfoCircleOutlined /></Tooltip>
    //   </section>),
    // },

    {
      title: 'Stripe Fees',
      dataIndex: 'stripeFee',
      sorter: (a, b) => a.stripeFee - b.stripeFee,
      render: (stripeFee, record) => (<section className="earnings-value">
        ${stripeFee.toFixed(2)} <span style={{textTransform: 'uppercase'}}>{record.currency}</span>
        <Tooltip placement="right" className="extra-info" title={`Stripe Fee Applied: ${record.stripeFeePercent.toFixed(2)}% + ${record.stripeFeeFixed}¢`}><InfoCircleOutlined /></Tooltip>
      </section>),
    },

    {
      title: 'App Fees',
      dataIndex: 'appFee',
      sorter: (a, b) => a.appFee - b.appFee,
      render: (appFee, record) => (<section className="earnings-value">
        ${appFee.toFixed(2)} <span style={{textTransform: 'uppercase'}}>{record.currency}</span>
        <Tooltip placement="right" className="extra-info" title={`App Fee Applied: ${record.appFeePercent.toFixed(2)}%`}><InfoCircleOutlined /></Tooltip>
      </section>),
    },

    // {
    //   title: 'Date',
    //   dataIndex: 'createdAt',
    //   // sorter: (a, b) => a.amount - b.amount,
    //   sorter: true,
    //   defaultSortOrder: 'descend',
    //   render: (createdAt, record) => (<section className="date-value">{moment(createdAt).format('L hh:mm A')}</section>),
    // },
    // {
    //   title: 'Bank Account',
    //   dataIndex: 'stripe',
    //   // sorter: (a, b) => a.amount - b.amount,
    //   sorter: true,
    //   render: (stripe, record) => {
    //     if(stripe.connected){
    //       return (<section className="status-value"><Tag icon={<BankOutlined />} color="success">Connected</Tag></section>)
    //     }
    //     else if(!stripe.connected){
    //       return (<section className="status-value"><Tag icon={<BankOutlined />} color="error">No Connected</Tag></section>)
    //     }
    //   },
    // },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    dispatch(getAdminPayouts({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      filters,
    }))
  }


  const payoutPaymentsTable = (payout) => {
    const columns = [
      {
        title: 'Date',
        dataIndex: 'createdAt',
        sorter: (a, b) => moment(a.createdAt).diff(b.createdAt),
        defaultSortOrder: 'descend',
        render: (createdAt, record) => (<section className="date-value">{moment(createdAt).format('L hh:mm A')}</section>),
      },
      // {
      //   title: 'Backer',
      //   dataIndex: 'displayName',
      //   // sorter: (a, b) => a.amount - b.amount,
      //   sorter: false,
      //   render: (displayName) => (<section className="displayName-value">{displayName ? displayName : 'Unknown'}</section>),
      // },
      // {
      //   title: "Message",
      //   dataIndex: 'supportMessage',
      //   // sorter: (a, b) => {
      //   //     var textA = a.supportMessage ? a.supportMessage.toLowerCase() : '';
      //   //     var textB = b.supportMessage ? b.supportMessage.toLowerCase() : '';
      //   //     return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      //   // },
      //   sorter: false,
      //   render: supportMessage => {
      //     return supportMessage ?
      //       (<section className="supportMessage-value">{supportMessage}</section>) :
      //       (<section className="supportMessage-value no-message">- - -</  section>)
      //   },
      //   width: '200px',
      // },
      {
        title: 'Received Donation',
        dataIndex: 'amount',
        sorter: (a, b) => a.amount - b.amount,
        render: (amount, record) => (<section className="earnings-value">${(amount / 100).toFixed(2)} <span style={{textTransform: 'uppercase'}}>{record.currency}</span></section>),
      },
      {
        title: 'Stripe Fees',
        dataIndex: 'stripeFee',
        // sorter: (a, b) => a.stripeFee - b.stripeFee,
        render: (stripeFee, record) => {
          const theStripeFee = ((record.amount / 100) * (payout.stripeFeePercent.toFixed(2) / 100)) + (payout.stripeFeeFixed);
          return (<section className="earnings-value">
            ${theStripeFee.toFixed(2)} <span style={{textTransform: 'uppercase'}}>{payout.currency}</span>
            <Tooltip placement="right" className="extra-info" title={`Stripe Fee Applied: ${payout.stripeFeePercent.toFixed(2)}% + ${payout.stripeFeeFixed}¢`}><InfoCircleOutlined /></Tooltip>
          </section>);
        },
      },

      {
        title: 'App Fees',
        dataIndex: 'appFee',
        // sorter: (a, b) => a.appFee - b.appFee,
        render: (appFee, record) => {
          const theAppFee = (record.amount / 100) * (payout.appFeePercent.toFixed(2) / 100);
          return (<section className="earnings-value">
            ${theAppFee.toFixed(2)} <span style={{textTransform: 'uppercase'}}>{record.currency}</span>
            <Tooltip placement="right" className="extra-info" title={`App Fee Applied: ${payout.appFeePercent.toFixed(2)}%`}><InfoCircleOutlined /></Tooltip>
          </section>);
        },
      },
      {
        title: 'Donation After Fees',
        dataIndex: 'donationAfterFees',
        // sorter: (a, b) => a.appFee - b.appFee,
        render: (donationAfterFees, record) => {
          const theStripeFee = ((record.amount / 100) * (payout.stripeFeePercent.toFixed(2) / 100)) + (payout.stripeFeeFixed);
          const theAppFee = (record.amount / 100) * (payout.appFeePercent.toFixed(2) / 100);
          const theDonationAfterFees = ((record.amount / 100) - (theStripeFee + theAppFee));
          return (<section className="earnings-value">
            ${theDonationAfterFees.toFixed(2)} <span style={{textTransform: 'uppercase'}}>{record.currency}</span>
          </section>);
        },
      },
    ];

    return <Table
      locale={{ emptyText: EmptyTableMessage }}
      columns={columns}
      dataSource={payout.payments.data}
      pagination={false}
      summary={pageData => {

        return (
          <>
            <Table.Summary.Row className="TheSummaryRow">
              <Table.Summary.Cell>Total</Table.Summary.Cell>
              <Table.Summary.Cell>
                ${payout.sourceAmount.toFixed(2)}  <span style={{textTransform: 'uppercase'}}>{payout.currency}</span>
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                ${payout.stripeFee.toFixed(2)} <span style={{textTransform: 'uppercase'}}>{payout.currency}</span>
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                ${payout.appFee.toFixed(2)} <span style={{textTransform: 'uppercase'}}>{payout.currency}</span>
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                ${payout.amountAfterStripeAndAppFees.toFixed(2)} <span style={{textTransform: 'uppercase'}}>{payout.currency}</span>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </>
        );
      }}
    />;
  };

  return (
    <section className="PayoutsList">
      <Table
        locale={{ emptyText: EmptyTableMessage }}
        onRow={onRow}
        columns={columns}
        rowKey={record => record.id}
        dataSource={data}
        pagination={pagination}
        loading={isLoading}
        onChange={handleTableChange}
        expandable={{
          indentSize: 30,
          expandRowByClick: true,
          expandedRowRender: record => {
            return (<section className={classNames('ExpandedDetailsPayoutPayments', { isLoading: record.payments.isLoading })}>
              {(!record.payments.isLoading && record.payments.data) && <section className="PayoutPaymentDetails">
                  { payoutPaymentsTable(record) }
              </section>}
              {(record.payments.isLoading) && <div className="Loading" onClick={() => {
                dispatch(abortAdminPayoutPayments(record.id))
              }}><Spin tip="Loading Payments..." /></div>}
            </section>)
          },
          rowExpandable: record => {
            // record.message !== 'Not Expandable'
            return true
          },
          onExpandedRowsChange: onExpandedRowsChange,
          onExpand: onExpand,
        }}
      />
    </section>
  )
}
