import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import moment from 'moment'
import { Table, Space, Tooltip, Tag } from 'antd'
import {
  CheckCircleOutlined,
  SyncOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  ClockCircleOutlined,
  MinusCircleOutlined,
  ExportOutlined,
  BankOutlined,
} from '@ant-design/icons'
import { useSelector, useDispatch } from 'react-redux'
import { getPayouts } from '../../../actions'
import { EmptyTableMessage } from '../../../utils/CustomIcons'
import './PayoutsList.scss'

const { Column, ColumnGroup } = Table;


export default function PayoutsList() {
  const { isLoading, data, pagination, errors, rehydrated } = useSelector(state => state.user.payouts)
  const dispatch = useDispatch()
  const [expandedRows, setExpandedRows] = useState([]);

  useEffect(() => {
    if(!rehydrated){
      // Requesting the payments on first load.
      dispatch(getPayouts({
        sortField: 'createdAt',
        sortOrder: 'descend',
        pagination
      }))
    }
  }, [dispatch, pagination, rehydrated])

  const onRow = (record, rowIndex) => expandedRows.includes(record.id) && { className: "expanded" }

  const onExpandedRowsChange = (expandedRows) => {
    setExpandedRows(expandedRows)
  }

  const columns = [
    {
      title: 'Description',
      dataIndex: 'description',
      sorter: true,
      render: (description, record) => (<section className="amount-value">{ record.transfer.description }</section>),
    },
    {
      title: 'Transfer Created',
      dataIndex: 'createdAt',
      // sorter: (a, b) => a.amount - b.amount,
      sorter: true,
      defaultSortOrder: 'descend',
      render: (createdAt, record) => (<section className="date-value">{moment(createdAt).format('L')}</section>),
    },
    {
      title: 'In your bank account at',
      dataIndex: 'createdAtAlt',
      defaultSortOrder: 'descend',
      render: (createdAtAlt, record) => (<section className="date-value">{moment(record.createdAt).add(4, 'days').format('L')}</section>),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      sorter: (a, b) => a.amount - b.amount,
      render: (amount, record) => (<section className="amount-value">${record.transfer.amount / 100}</section>),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status, record) => {
        if(moment() > moment(record.createdAt).add(4, 'days')){
          return (<section className="status-value"><Tag icon={<BankOutlined />} color="success">In your bank account</Tag></section>)
        }
        else {
          return (<section className="status-value"><Tag icon={<ExportOutlined />} color="processing">On the way to your bank account</Tag></section>)
        }
      },
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    dispatch(getPayouts({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      filters,
    }))
  }

  return (
    <section className="PayoutsList">
      <Table
        locale={{ emptyText: EmptyTableMessage }}
        onRow={onRow}
        columns={columns}
        rowKey={record => record.id}
        dataSource={data}
        pagination={{ ...pagination, position: ['none', 'bottomRight'] }}
        loading={isLoading}
        onChange={handleTableChange}
        expandable={{
          indentSize: 30,
          expandRowByClick: true,
          expandedRowRender: record => {
            return (<section className="ExpandedDetails">
              <p style={{ margin: 0 }}>{record.message}</p>
            </section>)
          },
          rowExpandable: record => {
           // record.message !== 'Not Expandable',
            return false
          },
          onExpandedRowsChange: onExpandedRowsChange,
        }}
      />
    </section>
  )
}
