import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Tag } from 'antd';
import moment from 'moment';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  RollbackOutlined,
} from '@ant-design/icons';
import { getMerchSales } from '../../../actions';
import { EmptyTableMessage } from '../../../utils/CustomIcons';
import './MerchSalesList.scss';

const MerchSalesList = () => {
  const { isLoading, data, pagination, rehydrated } = useSelector(
    (state) => state.user.merchSales
  );
  const dispatch = useDispatch();
  const [expandedRows, setExpandedRows] = useState([]);

  useEffect(() => {
    if (!rehydrated) {
      // Requesting the payments on first load.
      dispatch(
        getMerchSales({
          pagination,
        })
      );
    }
  }, [dispatch, pagination, rehydrated]);

  const onRow = (record) =>
    expandedRows.includes(record.id) && { className: 'expanded' };

  const onExpandedRowsChange = (expandedRows) => {
    setExpandedRows(expandedRows);
  };

  const columns = [
    {
      title: 'Customer',
      dataIndex: 'customer',
      sorter: false,
      render: (customer) => (
        <section>{customer ? customer.displayName : 'Unknown'}</section>
      ),
    },
    {
      title: 'Order',
      dataIndex: 'lineItems',
      sorter: false,
      render: (lineItems) => {
        return lineItems.nodes.map((lineItem, index) => {
          const msg = `${lineItem.quantity} x ${lineItem.name}`;
          return <section key={index}>{msg}</section>;
        });
      },
      width: '400px',
    },
    {
      title: 'Date',
      dataIndex: 'processedAt',
      sorter: true,
      defaultSortOrder: 'descend',
      render: (processedAt) => (
        <section>{moment(processedAt).format('L hh:mm A')}</section>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'subtotalPrice',
      sorter: false,
      render: (subtotalPrice) => <section>${subtotalPrice}</section>,
    },
    {
      title: 'Status',
      dataIndex: 'displayFulfillmentStatus',
      width: '300px',
      render: (displayFulfillmentStatus, record) => {
        if (displayFulfillmentStatus === 'FULFILLED') {
          return (
            <section className='status-value'>
              <Tag icon={<CheckCircleOutlined />} color='success'>
                Fulfilled
              </Tag>
            </section>
          );
        }
        if (record.totalRefunded !== '0.00') {
          return (
            <section className='status-value'>
              <Tag icon={<RollbackOutlined />} color='processing'>
                Refunded
              </Tag>
            </section>
          );
        }
        // Place this second to last in cases of cancel without refund
        if (record.cancelledAt !== null) {
          return (
            <section className='status-value'>
              <Tag icon={<CloseCircleOutlined />} color='error'>
                Cancelled
              </Tag>
            </section>
          );
        }
        // Otherwise just mark as unfulfilled
        return (
          <section className='status-value'>
            <Tag icon={<ExclamationCircleOutlined />} color='warning'>
              Unfulfilled
            </Tag>
          </section>
        );
      },
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    dispatch(
      getMerchSales({
        sortField: sorter.field,
        sortOrder: sorter.order,
        pagination,
        filters,
      })
    );
  };

  return (
    <section className='PaymentsList'>
      <Table
        locale={{ emptyText: EmptyTableMessage }}
        onRow={onRow}
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={data}
        pagination={pagination}
        loading={isLoading}
        onChange={handleTableChange}
        expandable={{
          indentSize: 30,
          expandRowByClick: true,
          expandedRowRender: (record) => {
            return (
              <section className='ExpandedDetails'>
                <p style={{ margin: 0 }}>{record.message}</p>
              </section>
            );
          },
          rowExpandable: () => {
            return false;
          },
          onExpandedRowsChange: onExpandedRowsChange,
        }}
      />
    </section>
  );
};

export default MerchSalesList;
