import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import {
  Form,
  Input,
  Button,
  Checkbox,
  Divider,
  message,
  Upload,
  Row,
  Col,
  notification,
  Tooltip,
  Tag,
  DatePicker,
  Menu,
  Dropdown,
} from 'antd';
import { QRCode } from 'react-qrcode-logo';
import {
  LoadingOutlined,
  PlusOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined,
  MinusCircleOutlined,
  CloudDownloadOutlined,
} from '@ant-design/icons';
import { NavLink, useHistory } from 'react-router-dom';
import {
  FacebookIcon,
  SoundCloudIcon,
  TwitterIcon,
  BandCampIcon,
  InstagramIcon,
  SpotifyIcon,
  YoutubeIcon,
  VenmoIcon,
  GlobeIcon,
  LinkIcon,
} from '../../../utils/CustomIcons';
import MerchContent from '../merch/MerchContent';
import {
  updateProfileInfo,
  updateProfileInfoReset,
  getStripeOAuthLink,
} from '../../../actions';
import './ProfileForm.scss';
import AvatarUploader from './AvatarUploader';
import CoverUploader from './CoverUploader';
import poweredByStripe from './powered_by_stripe.svg';
import qrLogo from './qr-logo.png';

// import { jsPDF } from "jspdf";

// Default export is a4 paper, portrait, using millimeters for units
// const doc = new jsPDF({ unit: "px" });

// // JSPDF Helpers
// const centeredText = function (text, y, options) {
//   var textWidth = doc.getStringUnitWidth(text) * doc.internal.getFontSize() / doc.internal.scaleFactor;
//   var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
//   doc.text(textOffset, y, text, options);
// }

// const centeredImage = function (image, width, height, y) {
//   const x = (doc.internal.pageSize.width - width) / 2;
//   doc.addImage(image, 'JPEG', x, y, width, height);
// }

const patternURL = new RegExp(
  '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$',
  'i'
); // fragment locator

const patternSlug = new RegExp('^[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*$', 'i');

const patternSSN = new RegExp('^[0-9]{4}$', 'i');

const { TextArea } = Input;

export default function ProfileForm() {
  const [form] = Form.useForm();
  const history = useHistory();
  const {
    isLoading,
    errors: authErrors,
    user,
    updated,
  } = useSelector((state) => state.auth);
  const {
    isStripeLoading,
    errors: stripeErrors,
    connectOAuthLink,
  } = useSelector((state) => state.auth.stripe);
  const dispatch = useDispatch();
  const [changePassword, setChangePassword] = useState(false);

  const [socialLinks, setSocialLinks] = useState([]);

  const inputRef = useRef(null);

  const focusLastSNElement = () => {
    console.log(inputRef);
    setTimeout(() => {
      console.log(inputRef);
      inputRef.current.focus();
    }, 500);
  };

  const onImageDownload = (musicianName) => {
    console.log(musicianName);
    const canvas = document.getElementById('react-qrcode-logo');
    // const image = canvas.toDataURL("image/png")
    const pngFile = canvas.toDataURL('image/png');
    const downloadLink = document.createElement('a');
    downloadLink.download = `${musicianName}_QR_320x320`;
    downloadLink.href = `${pngFile}`;
    downloadLink.click();
  };

  useEffect(() => {
    if (!isStripeLoading && connectOAuthLink && !stripeErrors.hasError) {
      window.location.replace(connectOAuthLink);
    }
  }, [isStripeLoading, connectOAuthLink, stripeErrors]);

  const onFinish = (values) => {
    // Removing the lost data prevention modal.
    if (typeof window.unblock === 'function') {
      // Unblocking the route change when the component is unmounted.
      window.unblock();
    }
    dispatch(updateProfileInfo(values));
  };

  useEffect(() => {
    if (updated) {
      form.setFieldsValue({
        currentPassword: '',
        newPassword: '',
      });
      setChangePassword(false);
      // Creating visual confirmation for user.
      notification.success({
        message: 'Successfully Updated',
        description: 'Your info was successfully updated.',
        duration: 5,
        key: 'form-notification',
        onClick: () => {
          notification.close('form-notification');
        },
      });
      dispatch(updateProfileInfoReset()); // Reseting the flag to catch when the profile info was successfully updated
    }
  }, [dispatch, form, updated]);

  const onCheckboxChange = (e) => {
    setChangePassword(e.target.checked);
    // console.log(e.target.checked, changePassword);
  };

  // // Server-side validations catched here:
  useEffect(() => {
    if (authErrors.hasError) {
      if (
        ['EMAIL_IN_USE', 'EMAIL_NOT_VALID', 'EMAIL_REQUIRED'].includes(
          authErrors.code
        )
      ) {
        form.setFields([
          {
            name: 'email',
            errors: [authErrors.message],
          },
        ]);
      }

      if (
        ['SLUG_NAME_IN_USE', 'E_SLUG_CHANGE_NOT_ALLOWED'].includes(
          authErrors.code
        )
      ) {
        form.setFields([
          {
            name: 'slug',
            errors: [authErrors.message],
          },
        ]);
      }

      if (
        ['E_CURRENT_PASSWORD_DOES_NOT_MATCH', "PASSWORDS_DON'T_MATCH"].includes(
          authErrors.code
        )
      ) {
        form.setFields([
          {
            name: 'currentPassword',
            errors: [authErrors.message],
          },
        ]);
      }
    }
  }, [authErrors, form]);

  const isReadyToRequestBankAccount =
    user.firstName &&
    user.lastName &&
    user.musician.name &&
    user.musician.biography &&
    user.phone &&
    user.musician.slug;

  const emailField = (
    <Form.Item
      name='email'
      hasFeedback
      rules={[
        {
          required: true,
          message: 'Email is required',
        },
        {
          type: 'email',
          message: 'Please enter a valid email',
        },
      ]}
    >
      <Input placeholder='Email*' />
    </Form.Item>
  );

  // cDM, cDU & cWD
  useEffect(() => {
    return () => {
      if (typeof window.unblock === 'function') {
        // Unblocking the route change when the component is unmounted.
        window.unblock();
      }
    };
  }, []);

  // cDM, cDU & cWD
  useEffect(() => {
    // Cleaning the musician when the component is unmounted:
    setSocialLinks(user.musician.socialLinks ? user.musician.socialLinks : []);
  }, []);

  const getSocialIcon = (socialNetwork) => {
    const iconStyles = {
      width: '40px',
      borderRight: '1px solid #CCC',
      paddingRight: 10,
      marginRight: 10,
    };
    switch (socialNetwork) {
      case 'facebook':
        return <FacebookIcon style={iconStyles} />;
      case 'youtube':
        return <YoutubeIcon style={iconStyles} />;
      case 'twitter':
        return <TwitterIcon style={iconStyles} />;
      case 'instagram':
        return <InstagramIcon style={iconStyles} />;
      case 'spotify':
        return <SpotifyIcon style={iconStyles} />;
      case 'soundCloud':
        return <SoundCloudIcon style={iconStyles} />;
      case 'website':
        return <GlobeIcon style={iconStyles} />;
      case 'link':
        return <LinkIcon style={iconStyles} />;
      default:
        return <React.Fragment />;
    }
  };

  const getSocialPlaceholder = (socialNetwork) => {
    switch (socialNetwork) {
      case 'facebook':
        return 'Facebook URL';
      case 'youtube':
        return 'Youtube URL';
      case 'twitter':
        return 'Twitter URL';
      case 'instagram':
        return 'Instagram URL';
      case 'spotify':
        return 'Spotify URL';
      case 'soundCloud':
        return 'SoundCloud URL';
      case 'website':
        return 'Website URL';
      case 'link':
        return 'General Link';
      default:
        return '';
    }
  };

  // useEffect(() => {
  //   console.log(history.location.hash);
  //   if(history.location.hash === '#connect-account'){
  //     connectAccount.current.scrollIntoView(true);
  //   }
  //   if(history.location.hash === '#connect-account-lg'){
  //     connectAccountLG.current.scrollIntoView(true);
  //   }
  // }, [])

  return (
    <Form
      className='profile-form'
      layout='vertical'
      size='large'
      form={form}
      name='profile'
      onFinish={onFinish}
      onFieldsChange={(changedFields, allFields) => {
        if (changedFields.length > 0) {
          console.log(changedFields);
          if (typeof window.unblock === 'function') {
            // Unblocking the route change when the component is unmounted.
            window.unblock();
          }
          // Preventing data loses when users modify something in the form
          window.unblock = history.block(
            'Do you want to leave this page without saving? Your edits will be lost.'
          );
        }
      }}
      initialValues={{
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        musicianName: user.musician.name,
        biography: user.musician.biography,
        phone: user.phone,
        slug: user.musician.slug,
        facebook: user.musician.facebook,
        youtube: user.musician.youtube,
        twitter: user.musician.twitter,
        instagram: user.musician.instagram,
        spotify: user.musician.spotify,
        soundcloud: user.musician.soundcloud,
        title: user.musician.title,
        last4ssn: user.last4ssn ? user.last4ssn : null,
        dob: user.dob ? moment(user.dob) : null,
        venmo: user.musician.venmo,
        socialLinks: user.musician.socialLinks ? user.musician.socialLinks : [],
      }}
      scrollToFirstError
    >
      <Row>
        <Col xs={24} sm={24} md={24} lg={12} xl={10} xxl={10}>
          <h2>1. Bio Information</h2>
          <CoverUploader />
          <AvatarUploader />

          <Form.Item
            className='BandNameField'
            name='musicianName'
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Display Name is required',
              },
            ]}
          >
            <Input placeholder='Display Name*' />
          </Form.Item>
          <Form.Item
            className='BandTitleField'
            name='title'
            hasFeedback
            rules={[
              {
                required: true,
                message:
                  'Artist Title is required (ex: "Guitarist", "Rock Band", "Vocalist")',
              },
            ]}
          >
            <Input
              placeholder={`Artist Title (ex: "Guitarist", "Rock Band", "Vocalist")`}
            />
          </Form.Item>
          <Form.Item
            className='BiographyField'
            name='biography'
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Your biography is required',
              },
            ]}
          >
            <TextArea
              autoSize={{ minRows: 3 }}
              placeholder='Tell your audience about you or your project'
            />
          </Form.Item>

          <section className='SpecialFieldBlock show-on-lg'>
            <h2 className='SpecialFieldBlock__title'>2. Claim your Link</h2>
            <p className='SpecialFieldBlock__details'>
              Link must be unique and have no spaces or special characters aside
              from '-'.
            </p>
            <Form.Item
              className='SlugField'
              name='slug'
              rules={[
                {
                  required: true,
                  message: 'Your page name is required',
                },
                {
                  validator: (_, value) =>
                    value
                      ? !!patternSlug.test(value)
                        ? Promise.resolve()
                        : Promise.reject('Please enter a valid page name')
                      : Promise.resolve(),
                },
              ]}
            >
              <Input
                prefix={<div className='MuseitURLPrefix'>app.museit.co/u/</div>}
                placeholder='your-name-here'
                suffix={
                  <Tooltip title='Once you choose your URL you will have to contact an administrator to edit it.'>
                    <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                  </Tooltip>
                }
              />
            </Form.Item>
          </section>

          <section className='SpecialFieldBlock show-on-lg'>
            <h2>3. Personal Information</h2>
            <Form.Item
              name='firstName'
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'First Name is required',
                },
              ]}
            >
              <Input placeholder='First Name*' />
            </Form.Item>
            <Form.Item
              name='lastName'
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Last Name is required',
                },
              ]}
            >
              <Input placeholder='Last Name*' />
            </Form.Item>
            {emailField}
            <Form.Item
              name='phone'
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Phone Number is required',
                },
              ]}
            >
              <Input placeholder='Phone Number*' />
            </Form.Item>
            <Form.Item
              name='dob'
              hasFeedback
              rules={[
                {
                  required: false,
                  message: 'Date of Birth is required',
                },
              ]}
            >
              <DatePicker
                format={'MM/DD/YYYY'}
                placeholder='Date of Birth - MM/DD/YYYY'
              />
            </Form.Item>
            <Form.Item
              className='SlugField'
              name='last4ssn'
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Your last 4 SSN are required',
                },
                {
                  validator: (_, value) =>
                    value
                      ? !!patternSSN.test(value)
                        ? Promise.resolve()
                        : Promise.reject(
                            'Please enter a valid value for your SSN last 4 digits'
                          )
                      : Promise.resolve(),
                },
              ]}
            >
              <Input
                prefix={
                  <div
                    className='MuseitURLPrefix'
                    style={{ marginRight: '5px' }}
                  >
                    SSN ••• – •• –
                  </div>
                }
                placeholder='0000'
                suffix={
                  <Tooltip title='Last 4 digits of SSN are needed for fraud protection.'>
                    <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                  </Tooltip>
                }
              />
            </Form.Item>
            <Form.Item className='changePassword'>
              <Checkbox checked={changePassword} onChange={onCheckboxChange}>
                Change Password?
              </Checkbox>
            </Form.Item>
            {changePassword && (
              <Form.Item
                name='currentPassword'
                rules={[
                  {
                    required: true,
                    message: 'Current password is required!',
                  },
                  {
                    min: 8,
                    message:
                      'Your current password should at least have 8 characters',
                  },
                  {
                    max: 40,
                    message: 'You are exceeding the password characters limit',
                  },
                ]}
                hasFeedback
              >
                <Input.Password
                  placeholder='Current Password*'
                  autoComplete='on'
                />
              </Form.Item>
            )}
            {changePassword && (
              <Form.Item
                name='newPassword'
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Please type your new password!',
                  },
                  {
                    min: 8,
                    message:
                      'Your new password should at least have 8 characters',
                  },
                  {
                    max: 40,
                    message: 'You are exceeding the password characters limit',
                  },
                ]}
              >
                <Input.Password placeholder='New Password*' autoComplete='on' />
              </Form.Item>
            )}
          </section>

          <section
            className='SpecialFieldBlock show-on-lg'
            id='connect-account-lg'
          >
            <h2 className='SpecialFieldBlock__title'>
              4. Get Paid{' '}
              {user.stripe && user.stripe.connected && (
                <Tag color='green' icon={<CheckCircleOutlined />}>
                  Connected
                </Tag>
              )}
            </h2>
            {isReadyToRequestBankAccount && (
              <>
                {(!user.stripe || !user.stripe.connected) && (
                  <p className='SpecialFieldBlock__details'>
                    Connect your bank account for payouts. Your profile will not
                    be public until you have finished this step, this is to
                    ensure that we can pay you the money that you have
                    collected.
                  </p>
                )}
                {user.stripe && user.stripe.connected && (
                  <p className='SpecialFieldBlock__details'>
                    You're all set! Your profile is now public and can start
                    receiving tips!
                  </p>
                )}

                {(!user.stripe || !user.stripe.connected) && (
                  <Button
                    onClick={() => dispatch(getStripeOAuthLink())}
                    className='ConnectYourBankAccount'
                    type='primary'
                  >
                    Connect your bank account
                  </Button>
                )}
              </>
            )}
            {!isReadyToRequestBankAccount && (
              <>
                <p className='SpecialFieldBlock__details'>
                  Complete your personal info to get access to connect your bank
                  account.
                </p>
              </>
            )}
            <section className='poweredByStripe'>
              <img src={poweredByStripe} alt='Powered By Stripe' />
            </section>
          </section>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={{ span: 11, offset: 1 }}
          xl={{ span: 10, offset: 3 }}
          xxl={{ span: 9, offset: 2 }}
        >
          <h2 style={{ marginBottom: '15px' }}>
            5. Display your Social links on your Profile
          </h2>
          <Form.List name='socialLinks'>
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field, index) => (
                    <div
                      key={field.key}
                      className='ProfileForm__SocialLinks--Group'
                    >
                      <Form.Item
                        name={[field.name, 'link']}
                        fieldKey={[field.fieldKey, 'link']}
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                          },
                          {
                            validator: (_, value) =>
                              value
                                ? !!patternURL.test(value)
                                  ? Promise.resolve()
                                  : Promise.reject('Please enter a valid URL')
                                : Promise.resolve(),
                          },
                        ]}
                      >
                        {fields.length - 1 === index ? (
                          <Input
                            prefix={getSocialIcon(
                              socialLinks && socialLinks[index]
                                ? socialLinks[index].type
                                : ''
                            )}
                            placeholder={getSocialPlaceholder(
                              socialLinks && socialLinks[index]
                                ? socialLinks[index].type
                                : ''
                            )}
                            ref={inputRef}
                          />
                        ) : (
                          <Input
                            prefix={getSocialIcon(
                              socialLinks && socialLinks[index]
                                ? socialLinks[index].type
                                : ''
                            )}
                            placeholder={getSocialPlaceholder(
                              socialLinks && socialLinks[index]
                                ? socialLinks[index].type
                                : ''
                            )}
                          />
                        )}
                      </Form.Item>
                      <MinusCircleOutlined
                        style={{
                          margin: '0 12px',
                          fontSize: '24px',
                          color: '#7b88a8',
                        }}
                        onClick={() => {
                          const cloneSocialLinks = [...socialLinks];
                          cloneSocialLinks.splice(index, 1);
                          setSocialLinks([...cloneSocialLinks]);
                          remove(index);
                        }}
                      />
                    </div>
                  ))}
                  <Form.Item>
                    <Dropdown
                      overlay={
                        <Menu
                          onClick={(e) => {
                            add(
                              { type: e.key },
                              setSocialLinks([...socialLinks, { type: e.key }])
                            );
                            focusLastSNElement();
                          }}
                        >
                          <Menu.Item key='facebook'>Facebook</Menu.Item>
                          <Menu.Item key='youtube'>Youtube</Menu.Item>
                          <Menu.Item key='twitter'>Twitter</Menu.Item>
                          <Menu.Item key='instagram'>Instagram</Menu.Item>
                          <Menu.Item key='spotify'>Spotify</Menu.Item>
                          <Menu.Item key='soundCloud'>SoundCloud</Menu.Item>
                          <Menu.Item key='website'>Website</Menu.Item>
                          <Menu.Item key='link'>General Link</Menu.Item>
                        </Menu>
                      }
                      placement='bottomLeft'
                      arrow
                    >
                      <Button>
                        <PlusOutlined /> Add Social Link
                      </Button>
                    </Dropdown>
                  </Form.Item>
                </div>
              );
            }}
          </Form.List>
          <h2 style={{ marginBottom: '15px' }}>6. Share your QR code.</h2>
          <section className='QRContainer'>
            {user && user.musician && user.musician.slug && (
              <QRCode
                size={320}
                value={`${window.location.origin}/u/${user.musician.slug}`}
                logoImage={qrLogo}
                logoWidth={103}
                logoHeight={127}
                logoOpacity={0.8}
                ecLevel='H'
              />
            )}
            <div
              className='download-layer'
              onClick={() =>
                onImageDownload(user.musician.name.replace(/\s/g, '_'))
              }
            >
              <CloudDownloadOutlined />
              <span>Download</span>
            </div>
          </section>
          <section className='SpecialFieldBlock hide-on-lg'>
            <h2 className='SpecialFieldBlock__title'>2. Claim your Link</h2>
            <p className='SpecialFieldBlock__details'>
              Link must be unique and have no spaces or special characters aside
              from '-'.
            </p>
            <Form.Item
              name='slug'
              className='SlugField'
              hasFeedback
              rules={[
                { required: true },
                {
                  validator: (_, value) =>
                    value
                      ? !!patternSlug.test(value)
                        ? Promise.resolve()
                        : Promise.reject('Please enter a valid page name')
                      : Promise.resolve(),
                },
              ]}
            >
              <Input
                prefix={<div className='MuseitURLPrefix'>app.museit.co/u/</div>}
                placeholder='your-name-here'
                suffix={
                  <Tooltip title='Once you choose your URL you will have to contact an administrator to edit it.'>
                    <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                  </Tooltip>
                }
              />
            </Form.Item>
          </section>
          <section className='SpecialFieldBlock hide-on-lg'>
            <h2>3. Personal Information</h2>
            <Form.Item
              name='firstName'
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'First Name is required',
                },
              ]}
            >
              <Input placeholder='First Name*' />
            </Form.Item>
            <Form.Item
              name='lastName'
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Last Name is required',
                },
              ]}
            >
              <Input placeholder='Last Name*' />
            </Form.Item>
            {emailField}
            <Form.Item
              name='phone'
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Phone Number is required',
                },
              ]}
            >
              <Input placeholder='Phone Number*' />
            </Form.Item>
            <Form.Item
              name='dob'
              hasFeedback
              rules={[
                {
                  required: false,
                  message: 'Date of Birth is required',
                },
              ]}
            >
              <DatePicker
                format={'MM/DD/YYYY'}
                placeholder='Date of Birth - MM/DD/YYYY'
              />
            </Form.Item>
            <Form.Item
              className='SlugField'
              name='last4ssn'
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Your last 4 SSN are required',
                },
                {
                  validator: (_, value) =>
                    value
                      ? !!patternSSN.test(value)
                        ? Promise.resolve()
                        : Promise.reject(
                            'Please enter a valid value for your SSN last 4 digits'
                          )
                      : Promise.resolve(),
                },
              ]}
            >
              <Input
                prefix={
                  <div
                    className='MuseitURLPrefix'
                    style={{ marginRight: '5px' }}
                  >
                    SSN ••• – •• –
                  </div>
                }
                placeholder='0000'
                suffix={
                  <Tooltip title='Last 4 digits of SSN are needed for fraud protection.'>
                    <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                  </Tooltip>
                }
              />
            </Form.Item>
            <Form.Item className='changePassword'>
              <Checkbox checked={changePassword} onChange={onCheckboxChange}>
                Change Password?
              </Checkbox>
            </Form.Item>
            {changePassword && (
              <Form.Item
                name='currentPassword'
                rules={[
                  {
                    required: true,
                    message: 'Current password is required!',
                  },
                  {
                    min: 8,
                    message:
                      'Your current password should at least have 8 characters',
                  },
                  {
                    max: 40,
                    message: 'You are exceeding the password characters limit',
                  },
                ]}
                hasFeedback
              >
                <Input.Password
                  placeholder='Current Password*'
                  autoComplete='on'
                />
              </Form.Item>
            )}
            {changePassword && (
              <Form.Item
                name='newPassword'
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Please type your new password!',
                  },
                  {
                    min: 8,
                    message:
                      'Your new password should at least have 8 characters',
                  },
                  {
                    max: 40,
                    message: 'You are exceeding the password characters limit',
                  },
                ]}
              >
                <Input.Password placeholder='New Password*' autoComplete='on' />
              </Form.Item>
            )}
          </section>
          <section
            className='SpecialFieldBlock hide-on-lg'
            id='connect-account'
          >
            <h2 className='SpecialFieldBlock__title'>
              4. Get Paid{' '}
              {user.stripe && user.stripe.connected && (
                <Tag color='green' icon={<CheckCircleOutlined />}>
                  Connected
                </Tag>
              )}
            </h2>
            {isReadyToRequestBankAccount && (
              <>
                {(!user.stripe || !user.stripe.connected) && (
                  <p className='SpecialFieldBlock__details'>
                    Connect your bank account for payouts. Your profile will not
                    be public until you have finished this step, this is to
                    ensure that we can pay you the money that you have
                    collected.
                  </p>
                )}
                {user.stripe && user.stripe.connected && (
                  <p className='SpecialFieldBlock__details'>
                    You're all set! Your profile is now public and can start
                    receiving tips!
                  </p>
                )}
                {(!user.stripe || !user.stripe.connected) && (
                  <Button
                    onClick={() => dispatch(getStripeOAuthLink())}
                    className='ConnectYourBankAccount'
                    type='primary'
                  >
                    Connect your bank account
                  </Button>
                )}
              </>
            )}
            {!isReadyToRequestBankAccount && (
              <>
                <p className='SpecialFieldBlock__details'>
                  Complete your personal info to get access to connect your bank
                  account.
                </p>
              </>
            )}
            <section className='poweredByStripe'>
              <img src={poweredByStripe} alt='Powered By Stripe' />
            </section>
          </section>
          {isReadyToRequestBankAccount && false && (
            <section className='SpecialFieldBlock'>
              <h2 className='SpecialFieldBlock__title'>
                6. Receive Donations With Venmo
              </h2>
              <p className='SpecialFieldBlock__details'>
                Please provide your Venmo name to receive Venmo payments.
              </p>
              <Form.Item name='venmo'>
                <Input
                  prefix={
                    <VenmoIcon
                      style={{
                        width: '40px',
                        borderRight: '1px solid #CCC',
                        paddingRight: 10,
                        marginRight: 10,
                      }}
                    />
                  }
                  placeholder='Venmo Username'
                />
              </Form.Item>
            </section>
          )}

          <section className='SpecialFieldBlock'>
            <h2 className='SpecialFieldBlock__title merchTitle'>
              7. Design your Merch
            </h2>
            <MerchContent />
          </section>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={23} xxl={21}>
          <Form.Item justify='center' noStyle>
            <Button
              className='btn-custom-submit ProfileFormSubmit'
              type='primary'
              htmlType='submit'
              disabled={isLoading}
              loading={isLoading}
            >
              Save Profile
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}
