import React, { useEffect, useState } from "react";
// import classNames from 'classnames';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { message } from 'antd';
import { recoverValidate } from '../../actions'

// Visual Components and Styles:
import './Recover.scss';
import RecoverForm from './RecoverForm';
import './RecoverForm.scss';
import RecoverPasswordForm from './RecoverPasswordForm';
import './RecoverPasswordForm.scss';

export default function Recover (){
  const { token, isAuthenticated, errors: authErrors } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const history = useHistory()
  const { recoveryToken } = useParams()
  const [ globalErrors ] = useState(['E_RECOVERY_TOKEN_BROKEN', 'E_RECOVERY_TOKEN_NOT_PROVIDED', 'E_UPDATING_USER_PASSWORD', 'E_SENDING_EMAILS', 'E_REQUEST_RATE_EXCEEDED'])

  // Redirect User if is already authenticated.
  useEffect(() => {
    if(token && isAuthenticated){
      message.success('Password updated successfully!, We sent you an email to notify you about this change, don\'t have to reply.');
      history.replace('/my-account')
    }
  }, [token, isAuthenticated, history])

  // If we receive the payment token we send the recovery token action to validate server side.
  useEffect(() => {
    // Verify if the token is not empty
    if(recoveryToken){
      dispatch(recoverValidate(recoveryToken))
    }
  }, [recoveryToken, dispatch])

  //Catching global errors related with auth/recover.
  useEffect(() => {
    if(authErrors.hasError){
      if(['E_RECOVERY_TOKEN_BROKEN'].includes(authErrors.code)){
        history.replace('/recover')
      }
    }
  }, [authErrors, history])

  return (
    <section className="Recover">
      {(!isAuthenticated) && (
        <section className="Recover__wrapper">
          {(authErrors.hasError && authErrors.code && globalErrors.includes(authErrors.code)) && <section className="Recover__errors">{authErrors.message}</section>}
          {(!recoveryToken) && <RecoverForm />}
          {(recoveryToken) && <RecoverPasswordForm />}
        </section>
      )}
    </section>
  )
}
