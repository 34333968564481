/* eslint-disable import/prefer-default-export */

export const getMusician = (musicianSlug) => ({
  type: 'GET_MUSICIAN',
  payload: {
    musicianSlug,
  },
});

export const sendPayment = (payload) => ({
  type: 'SEND_PAYMENT',
  payload: {
    ...payload,
  },
});

export const cleanCurrentMusician = () => ({
  type: 'CLEAN_CURRENT_MUSICIAN',
});

export const getPaymentButtonClientSecret = (payload) => ({
  type: 'GET_PAYMENT_BUTTON_CLIENT_SECRET',
  payload: {
    ...payload,
  },
});

export const cleanPayment = () => ({
  type: 'CLEAN_PAYMENT',
});

export const setButtonPayExtraAttrs = (attrs) => ({
  type: 'SET_BUTTON_PAY_EXTRA_ATTRS',
  payload: {
    ...attrs,
  },
});

export const cleanButtonPayExtraAttrs = () => ({
  type: 'CLEAN_BUTTON_PAY_EXTRA_ATTRS',
});
