/* eslint-disable import/prefer-default-export */

export const getInitialInfo = () => ({
  type: 'GET_INITIAL_INFO',
});

export const getPayouts = (params) => {
  return {
    type: 'GET_PAYOUTS',
    payload: {
      params,
    },
  };
};

export const getPayments = (params) => ({
  type: 'GET_PAYMENTS',
  payload: {
    params,
  },
});

export const getMerchSales = (params) => ({
  type: 'GET_MERCH_SALES',
  payload: {
    params,
  },
});

export const getMerchCache = () => ({
  type: 'GET_MERCH_CACHE',
});

export const setMerchCacheRehydrated = (isRehydrated) => ({
  type: 'SET_MERCH_CACHE_REHYDRATED',
  payload: {
    isRehydrated,
  },
});

export const getEarningsByMonth = (params) => ({
  type: 'GET_EARNINGS_BY_MONTH',
  payload: {
    params,
  },
});

export const getEarningsByMonthCSV = (month, year) => ({
  type: 'GET_EARNINGS_BY_MONTH_CSV',
  payload: {
    month,
    year,
  },
});

export const getEarnings = () => ({
  type: 'GET_EARNINGS',
});
