import api from '../utils/api';
import { getErrorResponse } from '../utils/apiErrorHandler';
import { ofType } from 'redux-observable';
import {
  map,
  takeUntil,
  defaultIfEmpty,
  startWith,
  catchError,
  mergeMap,
} from 'rxjs/operators';
import { from } from 'rxjs';

export const getInitialInfo = (action$) =>
  action$.pipe(
    //fixed
    ofType('GET_INITIAL_INFO'),
    mergeMap((action) => {
      return from(api.getInitialInfo()).pipe(
        map((response) => ({
          type: 'GET_INITIAL_INFO_SUCCESS',
          payload: response.response,
        })),
        startWith({
          type: 'GET_INITIAL_INFO_PENDING',
          payload: action.payload,
        }),
        takeUntil(action$.pipe(ofType('GET_INITIAL_INFO_ABORTED'))),
        catchError((error) =>
          getErrorResponse({ type: 'GET_INITIAL_INFO_ERROR', error })
        ),
        defaultIfEmpty({ type: 'GET_INITIAL_INFO_CANCELED' })
      );
    })
  );

export const getPayouts = (action$) =>
  action$.pipe(
    //fixed
    ofType('GET_PAYOUTS'),
    mergeMap((action) => {
      return from(api.getPayouts(action.payload.params)).pipe(
        map((response) => ({
          type: 'GET_PAYOUTS_SUCCESS',
          payload: response.response,
        })),
        startWith({ type: 'GET_PAYOUTS_PENDING', payload: action.payload }),
        takeUntil(action$.pipe(ofType('GET_PAYOUTS_ABORTED'))),
        catchError((error) =>
          getErrorResponse({ type: 'GET_PAYOUTS_ERROR', error })
        ),
        defaultIfEmpty({ type: 'GET_PAYOUTS_CANCELED' })
      );
    })
  );

export const getPayments = (action$) =>
  action$.pipe(
    //fixed
    ofType('GET_PAYMENTS'),
    mergeMap((action) => {
      return from(api.getPayments(action.payload.params)).pipe(
        map((response) => ({
          type: 'GET_PAYMENTS_SUCCESS',
          payload: response.response,
        })),
        startWith({ type: 'GET_PAYMENTS_PENDING', payload: action.payload }),
        takeUntil(action$.pipe(ofType('GET_PAYMENTS_ABORTED'))),
        catchError((error) =>
          getErrorResponse({ type: 'GET_PAYMENTS_ERROR', error })
        ),
        defaultIfEmpty({ type: 'GET_PAYMENTS_CANCELED' })
      );
    })
  );

export const getMerchSales = (action$) =>
  action$.pipe(
    ofType('GET_MERCH_SALES'),
    mergeMap((action) => {
      return from(api.getMerchSales(action.payload.params)).pipe(
        map((response) => ({
          type: 'GET_MERCH_SALES_SUCCESS',
          payload: response.response,
        })),
        startWith({ type: 'GET_MERCH_SALES_PENDING', payload: action.payload }),
        takeUntil(action$.pipe(ofType('GET_MERCH_SALES_ABORTED'))),
        catchError((error) =>
          getErrorResponse({ type: 'GET_MERCH_SALES_ERROR', error })
        ),
        defaultIfEmpty({ type: 'GET_MERCH_SALES_CANCELED' })
      );
    })
  );

export const getMerchCache = (action$) =>
  action$.pipe(
    ofType('GET_MERCH_CACHE'),
    mergeMap((action) => {
      return from(api.getMerchCache()).pipe(
        map((response) => ({
          type: 'GET_MERCH_CACHE_SUCCESS',
          payload: response.response,
        })),
        startWith({
          type: 'GET_MERCH_CACHE_PENDING',
          payload: action.payload,
        }),
        takeUntil(action$.pipe(ofType('GET_MERCH_CACHE_ABORTED'))),
        catchError((error) =>
          getErrorResponse({ type: 'GET_MERCH_CACHE_ERROR', error })
        ),
        defaultIfEmpty({ type: 'GET_MERCH_CACHE_CANCELED' })
      );
    })
  );

export const getEarningsByMonth = (action$) =>
  action$.pipe(
    //fixed
    ofType('GET_EARNINGS_BY_MONTH'),
    mergeMap((action) => {
      return from(api.getEarningsByMonth(action.payload.params)).pipe(
        map((response) => ({
          type: 'GET_EARNINGS_BY_MONTH_SUCCESS',
          payload: response.response,
        })),
        startWith({
          type: 'GET_EARNINGS_BY_MONTH_PENDING',
          payload: action.payload,
        }),
        takeUntil(action$.pipe(ofType('GET_EARNINGS_BY_MONTH_ABORTED'))),
        catchError((error) =>
          getErrorResponse({ type: 'GET_EARNINGS_BY_MONTH_ERROR', error })
        ),
        defaultIfEmpty({ type: 'GET_EARNINGS_BY_MONTH_CANCELED' })
      );
    })
  );

export const getEarnings = (action$) =>
  action$.pipe(
    //fixed
    ofType('GET_EARNINGS'),
    mergeMap((action) => {
      return from(api.getEarnings()).pipe(
        map((response) => ({
          type: 'GET_EARNINGS_SUCCESS',
          payload: response.response,
        })),
        startWith({ type: 'GET_EARNINGS_PENDING', payload: action.payload }),
        takeUntil(action$.pipe(ofType('GET_EARNINGS_ABORTED'))),
        catchError((error) =>
          getErrorResponse({ type: 'GET_EARNINGS_ERROR', error })
        ),
        defaultIfEmpty({ type: 'GET_EARNINGS_CANCELED' })
      );
    })
  );
