export const getEnv = () => {
  let env = 'development';
  switch (window.location.host) {
    case 'staging.museit.co':
      env = 'staging';
      break;
    case 'app.museit.co':
      env = 'production';
      break;
    default:
      env = 'development';
  }
  return env;
};
