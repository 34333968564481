import api from '../utils/api';
import { getErrorResponse } from '../utils/apiErrorHandler';

import { ofType } from 'redux-observable';
import { map, takeUntil, defaultIfEmpty, startWith, catchError, mergeMap } from 'rxjs/operators'
import { from } from 'rxjs'


export const getUsers = action$ =>
  action$.pipe(  //fixed
    ofType('GET_USERS'),
    mergeMap(action => {
    return from(api.getUsers(action.payload.params)).pipe(
        map(response => ({ type: 'GET_USERS_SUCCESS', payload: response.response })),
        startWith({ type: 'GET_USERS_PENDING', payload: action.payload }),
        takeUntil(action$.pipe(
          ofType('GET_USERS_ABORTED')
        )),
        catchError(error => getErrorResponse({ type: 'GET_USERS_ERROR', error })),
        defaultIfEmpty({ type: 'GET_USERS_CANCELED' }),
      );
    })
  );


export const getAdminPayouts = action$ =>
  action$.pipe(  //fixed
    ofType('GET_ADMIN_PAYOUTS'),
    mergeMap(action => {
    return from(api.getAdminPayouts(action.payload.params)).pipe(
        map(response => ({ type: 'GET_ADMIN_PAYOUTS_SUCCESS', payload: response.response })),
        startWith({ type: 'GET_ADMIN_PAYOUTS_PENDING', payload: action.payload }),
        takeUntil(action$.pipe(
          ofType('GET_ADMIN_PAYOUTS_ABORTED')
        )),
        catchError(error => getErrorResponse({ type: 'GET_ADMIN_PAYOUTS_ERROR', error })),
        defaultIfEmpty({ type: 'GET_ADMIN_PAYOUTS_CANCELED' }),
      );
    })
  );

export const getAdminPayoutPayments = action$ =>
  action$.pipe(  //fixed
    ofType('GET_ADMIN_PAYOUT_PAYMENTS'),
    mergeMap(action => {
    return from(api.getAdminPayoutPayments(action.payload.payoutId)).pipe(
        map(response => ({ type: 'GET_ADMIN_PAYOUT_PAYMENTS_SUCCESS', payload: response.response })),
        startWith({ type: 'GET_ADMIN_PAYOUT_PAYMENTS_PENDING', payload: action.payload }),
        takeUntil(action$.pipe(
          ofType('GET_ADMIN_PAYOUT_PAYMENTS_ABORTED')
        )),
        catchError(error => getErrorResponse({ type: 'GET_ADMIN_PAYOUT_PAYMENTS_ERROR', error })),
        defaultIfEmpty({ type: 'GET_ADMIN_PAYOUT_PAYMENTS_CANCELED' }),
      );
    })
  );

  export const getAdminPayments = action$ =>
    action$.pipe(  //fixed
      ofType('GET_ADMIN_PAYMENTS'),
      mergeMap(action => {
      return from(api.getAdminPayments(action.payload.params)).pipe(
          map(response => ({ type: 'GET_ADMIN_PAYMENTS_SUCCESS', payload: response.response })),
          startWith({ type: 'GET_ADMIN_PAYMENTS_PENDING', payload: action.payload }),
          takeUntil(action$.pipe(
            ofType('GET_ADMIN_PAYMENTS_ABORTED')
          )),
          catchError(error => getErrorResponse({ type: 'GET_ADMIN_PAYMENTS_ERROR', error })),
          defaultIfEmpty({ type: 'GET_ADMIN_PAYMENTS_CANCELED' }),
        );
      })
    );
