import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getAllProducts } from '../../../utils/shopify';
import Designer from './Designer';
import { getMerchCache } from '../../../actions';
import { getEnv } from '../../../utils/common';

import './MerchContent.scss';

const Merch = () => {
  const [templates, setTemplates] = useState(null);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const {
    isLoading,
    data: merchCache,
    pagination,
    rehydrated,
  } = useSelector((state) => state.user.merchCache);
  const s3Base = useSelector((state) => state.config.s3Base);

  console.log('merchCache:', merchCache);

  useEffect(() => {
    if (!rehydrated) {
      console.log('dispatching getLatestMerch');
      dispatch(getMerchCache());
    }
  }, [dispatch, pagination, rehydrated]);

  useEffect(() => {
    const retrieveProducts = async () => {
      const t = await getAllProducts('__templates');
      setTemplates(() => t);
    };

    retrieveProducts();
  }, [user]);

  if (!templates || !merchCache) {
    return null;
  }

  const designers = templates.map((template) => {
    const product = merchCache.find(
      (p) => p.title === template.title && p.mainImg
    );
    const initProductImg = product
      ? s3Base + product.mainImg
      : template.images[0].src;
    const enabled = merchCache.some(
      (p) => p.title === template.title && p.enabled
    );
    return (
      <Designer
        key={`designer-${template.title}`}
        title={template.title}
        initProductImg={initProductImg}
        templateImg={template.images[0].src}
        enabled={enabled}
      />
    );
  });

  return (
    <div>
      <ul className='copy'>
        <li>
          Upload your logo as a PNG file with a minimum resolution of 300dpi.
        </li>
        <li>
          Once your logo is uploaded, you can resize it and move it to your
          desired location on the shirt.
        </li>
        <li>
          Once your design is saved, click the “Enable Sales” button to go live
          with your merch!
        </li>
      </ul>
      {getEnv() === 'production' && !user.stripe.connected ? (
        <div className='connectMsg'>
          Connect bank account in <b>My Profile</b> in order to design and sell
          merch.
        </div>
      ) : (
        <div>{designers}</div>
      )}
    </div>
  );
};

export default Merch;
