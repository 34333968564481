import React, { useState, useEffect } from 'react';
import { Col } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { getAllProducts } from '../../../utils/shopify';
import { getMerchCache } from '../../../actions';
import MerchContent from './MerchContent';

import './Merch.scss';

const Merch = () => {
  const [templates, setTemplates] = useState(null);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const {
    isLoading,
    data: merchCache,
    pagination,
    rehydrated,
  } = useSelector((state) => state.user.merchCache);

  console.log('merchCache:', merchCache);

  useEffect(() => {
    if (!rehydrated) {
      console.log('dispatching getLatestMerch');
      dispatch(getMerchCache());
    }
  }, [dispatch, pagination, rehydrated]);

  useEffect(() => {
    const retrieveProducts = async () => {
      const t = await getAllProducts('__templates');
      setTemplates(() => t);
    };

    retrieveProducts();
  }, [user]);

  if (!templates || !merchCache) {
    return null;
  }

  return (
    <section className='merch'>
      <Col xs={24} sm={24} md={24} lg={22} xl={20} xxl={18}>
        <h1 className='title'>Design Your Merch.</h1>
        <MerchContent />
      </Col>
    </section>
  );
};

export default Merch;
