import React, { useEffect } from 'react'
import ProfileForm from './ProfileForm'
import { useSelector, useDispatch } from 'react-redux'
import qs from 'qs'
import { authorizeStripeOAuth, getInitialInfo } from '../../../actions'

export default function Profile(props) {
  const dispatch = useDispatch()
  const parameters = qs.parse(props.location.search, { ignoreQueryPrefix: true })

  useEffect(() => {
    console.log(parameters);
    if(parameters.code && parameters.state){
      dispatch(authorizeStripeOAuth(parameters.code, parameters.state))
    }
  }, [dispatch, parameters])
  
  useEffect(() => {
    dispatch(getInitialInfo())
  }, [])

  return (
    <section className="Profile">
      <ProfileForm />
    </section>
  )
}
