import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { message, Upload } from 'antd'
import classNames from 'classnames'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { getAvatarUploadURL, uploadAvatar } from '../../../actions'
import ImageTools from '../../../utils/ImageTools'

import './AvatarUploader.scss'

const MyImageTools = new ImageTools();

export default function AvatarUploader () {
  const { isLoading, errors: authErrors, isRequestingAvatarURL, isUploadingAvatar, signedAvatarUploadURL, uploadedAvatarKey, token } = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const s3Base = useSelector(state => state.config.s3Base)
  const user = useSelector(state => state.auth.user)
  const [avatarFile, setAvatarFile] = useState()
  const [isAvatarHovered, setIsAvatarHovered] = useState(false)


  // Here we catch when we get the signed url from backend.
  useEffect(() => {
    if(!isUploadingAvatar && signedAvatarUploadURL && avatarFile){
      dispatch(uploadAvatar(avatarFile, signedAvatarUploadURL))
    }
  }, [isUploadingAvatar, avatarFile, signedAvatarUploadURL, dispatch])

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG or PNG file!');
    }

    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
      message.error('Image must smaller than 10MB!');
    }
    //
    // if(isJpgOrPng && isLt2M){
    //   const format = (file.type === 'image/jpeg') ? 'jpg' : 'png'
    //   setAvatarFile(file) // Store the avatar file to upload it after we get a signed url.
    //   dispatch(getAvatarUploadURL(format, token)) // Here we request for the avatar upload url.
    // }

    MyImageTools.resize(file, { width: 500, height: 500 }).then((blob) => {
      setAvatarFile(blob) // Store the avatar file to upload it after we get a signed url.
      dispatch(getAvatarUploadURL(token)) // Here we request for the avatar upload url.
    })

    return false // Return false to prevent Upload Component submit.
  }

  const uploadButton = (
    <div className="Avatar__upload-button">
      {(isUploadingAvatar || isRequestingAvatarURL) ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="ant-upload-text">{(isUploadingAvatar || isRequestingAvatarURL) ? 'Uploading...' : 'Upload'}</div>
    </div>
  );

  return (
    <section className="Avatar">
      <Upload
        name="avatar"
        // listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        beforeUpload={beforeUpload}
        disabled={(isUploadingAvatar || isRequestingAvatarURL)}
      >
        <section
          className={classNames("Avatar__wrapper", { disabled: (isRequestingAvatarURL || isUploadingAvatar)})}
          onMouseEnter={() => setIsAvatarHovered(true)}
          onMouseLeave={() => setIsAvatarHovered(false)}
        >
          <section className="Avatar__profile-image">
            {(user && user.musician && user.musician.avatar) && <div className="bg-profile-image" style={{ width: '100%', backgroundImage: `url(${(s3Base + user.musician.avatar)})` }} />}
            {(isRequestingAvatarURL || isUploadingAvatar || isAvatarHovered || !user.musician.avatar) && uploadButton}
          </section>
          {/*
          <section className="Avatar__content">
            <h3>Upload profile image</h3>
            <p>Recomended size 200x200 | 1mb</p>
          </section>
          */}
        </section>
      </Upload>
    </section>
  )
}
