import baseAttributes from "../storeBaseAttributes";

const homeReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_MUSICIAN_PENDING":
      return {
        ...state,
        currentMusician: {
          ...state.currentMusician,
          ...baseAttributes,
          isLoading: true,
        },
      };
    case "GET_MUSICIAN_ERROR":
      return {
        ...state,
        currentMusician: {
          ...state.currentMusician,
          ...baseAttributes,
          errors: {
            ...state.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case "GET_MUSICIAN_ABORTED":
      return {
        ...state,
        currentMusician: {
          ...state.currentMusician,
          ...baseAttributes,
        },
      };
    case "GET_MUSICIAN_SUCCESS":
      return {
        ...state,
        currentMusician: {
          ...state.currentMusician,
          ...baseAttributes,
          data: action.payload.musician,
        },
      };
    case "CLEAN_CURRENT_MUSICIAN":
      return {
        ...state,
        currentMusician: {
          ...state.currentMusician,
          ...baseAttributes,
          isLoading: true,
        },
      };
    case "SEND_PAYMENT_PENDING":
      return {
        ...state,
        payment: {
          ...state.payment,
          ...baseAttributes,
          isLoading: true,
          success: false,
        },
      };
    case "SEND_PAYMENT_ERROR":
      return {
        ...state,
        payment: {
          ...state.payment,
          ...baseAttributes,
          success: false,
          errors: {
            ...state.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case "SEND_PAYMENT_ABORTED":
      return {
        ...state,
        payment: {
          ...state.payment,
          ...baseAttributes,
          success: false,
        },
      };
    case "SEND_PAYMENT_SUCCESS":
      return {
        ...state,
        payment: {
          ...state.payment,
          ...baseAttributes,
          requires_action: action.payload.requires_action
            ? action.payload.requires_action
            : false,
          payment_intent_client_secret: action.payload.requires_action
            ? action.payload.payment_intent_client_secret
            : "",
          success: action.payload.success ? true : false,
        },
      };
    case "CLEAN_PAYMENT":
      return {
        ...state,
        payment: {
          ...state.payment,
          ...baseAttributes,
          success: false,
        },
      };
    case "SET_BUTTON_PAY_EXTRA_ATTRS":
      return {
        ...state,
        buttonPayExtraAttrs: {
          ...state.buttonPayExtraAttrs,
          ...action.payload,
          rehydrated: true,
        },
      };
    case "CLEAN_BUTTON_PAY_EXTRA_ATTRS":
      return {
        ...state,
        buttonPayExtraAttrs: {
          ...state.buttonPayExtraAttrs,
          amount: null,
          displayName: "",
          supportMessage: "",
          currentMusician: null,
          rehydrated: false,
        },
      };

    default:
      return state;
  }
};

export default homeReducer;
