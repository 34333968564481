import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { Form, Input, Button, Checkbox, Divider, notification, Select, Steps, Alert, Result } from 'antd'
import { MailOutlined } from '@ant-design/icons'

import { getData } from 'country-list'
import { NavLink } from 'react-router-dom'
import { signUp, subscribe, resetSubscribeFlag } from '../../actions'
import './SignUpForm.scss'
import classNames from "classnames"

const { Option } = Select;
const { Step } = Steps;
const countries = [{ code: "US", name: "United States of America" }, ...getData().filter(country => country.code !== "US").sort((a, b) => a.name.localeCompare(b.name)) ]

const allowedCountries = ['US']

export default function SignUpForm () {
  const [form] = Form.useForm()
  const { subscribed, isLoading, errors: authErrors } = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const [step, setStep] = useState(1)
  const [formFields, setFormFields] = useState({ firstName: undefined, lastName: undefined, country: undefined, email: undefined })

  const onFinish = values => {
    if (allowedCountries.includes(values.country)){
      dispatch(signUp(values))
    } else {
      dispatch(subscribe(values))
    }
  };

  const onValuesChange = (changedValues, allValues) => {
    setFormFields(allValues)
  };

  const resetForm = () => {
    form.resetFields()
    setFormFields({ firstName: undefined, lastName: undefined, country: undefined, email: undefined })
    setStep(1)
  }

  useEffect(() => {
    if(subscribed){
      setStep(3)
      // setTimeout(() => {
      //   form.resetFields()
      //   setFormFields({ firstName: undefined, lastName: undefined, country: undefined, email: undefined })
      //   setStep(1)
      //   dispatch(resetSubscribeFlag())
      // }, 5000)
    }
  })

  // Server-side validations catched here:
  useEffect(() => {
    if(authErrors.hasError){
      if(['EMAIL_IN_USE', 'EMAIL_NOT_VALID', 'EMAIL_REQUIRED'].includes(authErrors.code)){
        form.setFields([
           {
             name: 'email',
             errors: [authErrors.message],
           },
        ]);
        // form.setFields('email', authErrors.code, authErrors.message)
      }
      else if(['MUSICIAN_NAME_IN_USE', 'MUSICIAN_NAME_REQUIRED'].includes(authErrors.code)){
        form.setFields([
           {
             name: 'musicianName',
             errors: [authErrors.message],
           },
        ]);
        // form.setFields('email', authErrors.code, authErrors.message)
      }
      else if(['PASSWORD_CONFIRMATION_REQUIRED', 'PASSWORDS_DON\'T_MATCH'].includes(authErrors.code)){
        form.setFields([
           {
             name: 'rePassword',
             errors: [authErrors.message],
           },
        ]);
        // form.setFields('rePassword', authErrors.code, authErrors.message)
      }
      else {
        notification.error({
          message: 'Error',
          description: authErrors.message,
          duration: 0,
          key: 'sign-in-notification',
          onClick: () => {
            notification.close('sign-in-notification')
          }
        })
      }
    }
  }, [authErrors, form])

  return (
    <Form
      layout="vertical"
      size="large"
      form={form}
      name="signup"
      onFinish={onFinish}
      initialValues={{
        // country: 'US'
      }}
      onValuesChange={onValuesChange}
      scrollToFirstError
    >
      <section className={`form-wrapper step-${step}`}>
        <div className={classNames("general-info", { active: (step === 1), hide: (step !== 1) })}>
          <Form.Item
            name="firstName"
            hasFeedback
            rules={[
              {
                required: true,
                message: 'First Name is required',
              },
            ]}
          >
            <Input placeholder="First Name*" />
          </Form.Item>
          <Form.Item
            name="lastName"
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Last Name is required',
              },
            ]}
          >
            <Input placeholder="Last Name*" />
          </Form.Item>
          <Form.Item
            name="email"
            hasFeedback
            rules={[
              {
                type: 'email',
                message: 'Please enter a valid Email',
              },
              {
                required: true,
                message: 'Email is required',
              },
            ]}
          >
            <Input type="email" placeholder="Email*" autoCorrect="off" autoCapitalize="none" />
          </Form.Item>
          <Form.Item
            name="country"
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Country is required',
              },
            ]}
          >
            <Select
              size={'large'}
              placeholder="Country"
              showSearch
              filterOption={(input, option) => {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              }>
              {countries.map((country) => (<Option key={country.code} value={country.code}>{country.name}</Option>))}
            </Select>
          </Form.Item>
          {/* Next Button */}
          <Form.Item justify="center" noStyle>
            <Button className={'btn-custom-submit'} disabled={(!formFields.firstName || !formFields.lastName || !formFields.country || !formFields.email)} type="primary" htmlType="button" onClick={() => setStep(2) }>
              {(!formFields.firstName || !formFields.lastName || !formFields.country) ? 'Sign Up' : 'Next'}
            </Button>
          </Form.Item>
        </div>
        <div className={classNames("specific-info", { active: (step === 2), hide: (step !== 2) })}>
          <Steps className="signup-steps" size="small" current={step - 1}>
            <Step title="Your Info" className="interactive-step" onClick={() => setStep(1)}/>
            <Step title={(formFields.country === 'US') ? 'Account' : 'Status'} />
            <Step title={(formFields.country === 'US') ? 'Complete' : 'Subscribed'} />
          </Steps>
          {(formFields.country === 'US') && 
            (<div className={classNames("allowed-countries-form", { active: (formFields.country === 'US'), hide: (formFields.country !== 'US')})}>
              <Form.Item
                name="musicianName"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Display Name is required',
                  },
                ]}
              >
                <Input placeholder="Display Name" />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Password is required',
                  },
                  {
                    min: 8,
                    message: 'Your password should at least have 8 characters',
                  },
                  {
                    max: 40,
                    message: 'You are exceeding the password characters limit',
                  },
                ]}
                hasFeedback
              >
                <Input.Password placeholder="Password*" autoComplete="on" />
              </Form.Item>
              <Form.Item
                name="rePassword"
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your password',
                  },
                  {
                    min: 8,
                    message: 'Your password should at least have 8 characters',
                  },
                  {
                    max: 40,
                    message: 'You are exceeding the password characters limit',
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject('The two passwords that you entered do not match');
                    },
                  }),
                ]}
              >
                <Input.Password placeholder="Confirm Password*" autoComplete="on" />
              </Form.Item>
              <Form.Item>
                <Form.Item
                  name="us-bank-ssn"
                  valuePropName="checked"
                  noStyle
                  rules={[
                    { validator: (_, value) => value ? Promise.resolve() : Promise.reject('Please accept our US Bank Account and SSN agreement.') },
                  ]}
                >
                  <Checkbox>In order to receive payouts you <u>must</u> connect a <strong>US bank account</strong> as well as provide the last 4 digits of your <strong>US social security number</strong> for identity verification.</Checkbox>
                </Form.Item>
              </Form.Item>
              <Form.Item>
                <Form.Item
                  name="agree"
                  valuePropName="checked"
                  noStyle
                  rules={[
                    { validator: (_, value) => value ? Promise.resolve() : Promise.reject('Please accept our Terms and Privacy Policy.') },
                  ]}
                >
                  <Checkbox>By joining, you agree to Museit's <a href="https://www.museit.co/legal/terms-and-conditions" target="_blank">Terms and Conditions</a> and <a href="https://www.museit.co/legal/privacy" target="_blank">Privacy Policy</a></Checkbox>
                </Form.Item>
              </Form.Item>
              <Form.Item justify="center" noStyle>
                <Button className={'btn-custom-submit'} type="primary" htmlType="submit" disabled={isLoading} loading={isLoading}>
                  Sign Up
                </Button>
              </Form.Item>
            </div>
          )}
          <div className={classNames("not-allowed-countries subscription-form", { active: (formFields.country !== 'US'), hide: (formFields.country === 'US') })}>
              
            <Alert
              message="Country Not Available"
              description="We're sorry Museit is not yet available in your country."
              type="warning"
              showIcon
              closable={false}
            />

            <Form.Item justify="center" noStyle>
              <Button className="btn-custom-submit btn-subscribe" icon={<MailOutlined />} type="primary" htmlType="submit" disabled={isLoading} loading={isLoading}>
                Notify me when available
              </Button>
            </Form.Item>
            <Form.Item className="signin-link">
              <NavLink onClick={() => resetForm() } to="#">No Thanks</NavLink>
            </Form.Item>
          </div>
        </div>
        <div className={classNames("confirmation-message", { active: subscribed, hide: !subscribed })}>
          <Result
            status="success"
            title="Thank you for subscribing!"
            subTitle="We'll keep you updated on new Museit news"
          />
        </div>
      </section>
      <Divider plain>or</Divider>
      <Form.Item  className="signin-link">
        <NavLink to="/signin">Sign in to your Museit account</NavLink>
      </Form.Item>
    </Form>
  )
}
