import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom';
import { message, Button, Form, Input, notification } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { recoverChangePassword } from '../../actions'
import './RecoverPasswordForm.scss'



export default function RecoverPasswordForm () {
  const [form] = Form.useForm()
  const { isLoading, errors: authErrors } = useSelector(state => state.auth);
  let { recoveryToken } = useParams()
  const dispatch = useDispatch()

  const onFinish = values => {
    dispatch(recoverChangePassword(recoveryToken, values.password, values.rePassword))
  };

  useEffect(() => {
    if(authErrors.hasError){
      if(['E_PASSWORD_FIELD_NOT_PROVIDED'].includes(authErrors.code)){
        form.setFields([
           {
             name: 'password',
             errors: [authErrors.message],
           },
        ]);
      }
      else if(['E_RE_PASSWORD_FIELD_NOT_PROVIDED', 'PASSWORDS_DON\'T_MATCH'].includes(authErrors.code)){
        form.setFields([
           {
             name: 'rePassword',
             errors: [authErrors.message],
           },
        ]);
      }
      else {
        notification.error({
          message: 'Error',
          description: authErrors.message,
          duration: 0,
          key: 'recover-notification',
          onClick: () => {
            notification.close('recover-notification')
          }
        })
      }
    }
  }, [authErrors, form])

  return (
    <Form
      layout="vertical"
      size="large"
      form={form}
      name="signin"
      onFinish={onFinish}
      initialValues={{}}
      scrollToFirstError
    >
      <h1 className="Recover__title">Access Recovery</h1>

      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: 'Password is required!',
          }
        ]}
      >
        <Input.Password placeholder="Password*" autoComplete="on" />
      </Form.Item>
      <Form.Item
        name="rePassword"
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Please confirm your password',
          },
          {
            min: 8,
            message: 'Your password should at least have 8 characters',
          },
          {
            max: 40,
            message: 'You are exceeding the password characters limit',
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject('The two passwords that you entered do not match');
            },
          }),
        ]}
      >
        <Input.Password placeholder="Confirm Password*" autoComplete="on" />
      </Form.Item>
      <Form.Item justify="center" noStyle>
        <Button className={'btn-custom-submit'} type="primary" htmlType="submit" disabled={isLoading} loading={isLoading}>
          Save New Password
        </Button>
      </Form.Item>
    </Form>
  );
}
