import { ajax } from 'rxjs/ajax';
import qs from 'qs';
import { getToken } from '../store';

const api = {
  /* Auth Endpoints: */
  signIn: (credentials) =>
    ajax({
      url: '/api/v1/auth/sign-in',
      method: 'POST',
      body: credentials,
    }),
  signUp: (userInfo) =>
    ajax({
      url: '/api/v1/auth/sign-up',
      method: 'POST',
      body: userInfo,
    }),
  subscribe: (subscriberInfo) =>
    ajax({
      url: '/api/v1/auth/subscribe',
      method: 'POST',
      body: subscriberInfo,
    }),
  recover: (identity) =>
    ajax({
      url: '/api/v1/auth/recover',
      method: 'POST',
      body: {
        identity,
      },
    }),
  recoverValidate: (recoveryToken) =>
    ajax({
      url: '/api/v1/auth/recover/validate',
      method: 'POST',
      body: {
        recoveryToken,
      },
    }),
  recoverChangePassword: (recoveryToken, password, rePassword) =>
    ajax({
      url: '/api/v1/auth/recover/change-password',
      method: 'POST',
      body: {
        recoveryToken,
        password,
        rePassword,
      },
    }),

  /* Data Management Endpoints:  */
  getInitialInfo: () =>
    ajax({
      url: '/api/v1/get-initial-info',
      method: 'GET',
      headers: {
        Authorization: `JWT ${getToken()}`,
        'Content-Type': 'application/json',
      },
    }),

  getMusician: (musicianSlug) =>
    ajax({
      url: `/api/v1/musician/${musicianSlug}`,
      method: 'GET',
      headers: {
        Authorization: `JWT ${getToken()}`,
      },
    }),

  getStoreName: () =>
    ajax({
      url: '/api/v1/shopify/get-store-name',
      method: 'GET',
    }),

  updateProduct: (updates) =>
    ajax({
      url: `/api/v1/shopify/product`,
      method: 'PUT',
      body: updates,
      headers: {
        Authorization: `JWT ${getToken()}`,
        'Content-Type': 'application/json',
      },
    }),

  getAvatarUploadURL: () =>
    ajax({
      url: `/api/v1/profile/request-avatar-upload-url/`,
      method: 'GET',
      headers: {
        Authorization: `JWT ${getToken()}`,
        'Content-Type': 'application/json',
      },
    }),

  uploadAvatar: (file, signedURL) =>
    ajax({
      url: signedURL,
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': 'image/jpeg',
      },
    }),

  getCoverUploadURL: () =>
    ajax({
      url: '/api/v1/profile/request-cover-upload-url',
      method: 'GET',
      headers: {
        Authorization: `JWT ${getToken()}`,
        'Content-Type': 'application/json',
      },
    }),

  uploadCover: (file, signedURL) =>
    ajax({
      url: signedURL,
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': 'image/jpeg',
      },
    }),

  updateProfileInfo: (profileInfo) =>
    ajax({
      url: '/api/v1/profile',
      method: 'PUT',
      body: {
        ...profileInfo,
      },
      headers: {
        Authorization: `JWT ${getToken()}`,
        'Content-Type': 'application/json',
      },
    }),

  getPayouts: (params) =>
    ajax({
      url: `/api/v1/user/get-payouts?${qs.stringify(params)}`,
      method: 'GET',
      headers: {
        Authorization: `JWT ${getToken()}`,
        'Content-Type': 'application/json',
      },
    }),

  getPayments: (params) =>
    ajax({
      url: `/api/v1/user/get-payments?${qs.stringify(params)}`,
      method: 'GET',
      headers: {
        Authorization: `JWT ${getToken()}`,
        'Content-Type': 'application/json',
      },
    }),

  getMerchSales: (params) =>
    ajax({
      url: `/api/v1/shopify/get-merch-sales?${qs.stringify(params)}`,
      method: 'GET',
      headers: {
        Authorization: `JWT ${getToken()}`,
        'Content-Type': 'application/json',
      },
    }),

  getMerchCache: () =>
    ajax({
      url: `/api/v1/shopify/get-merch-cache`,
      method: 'GET',
      headers: {
        Authorization: `JWT ${getToken()}`,
        'Content-Type': 'application/json',
      },
    }),

  getEarningsByMonth: (params) =>
    ajax({
      url: `/api/v1/user/get-earnings-by-month?${qs.stringify(params)}`,
      method: 'GET',
      headers: {
        Authorization: `JWT ${getToken()}`,
        'Content-Type': 'application/json',
      },
    }),

  getEarningsByMonthCSV: (month, year) =>
    ajax({
      url: `/api/v1/user/get-earnings-by-month/${month}/${year}`,
      method: 'GET',
      headers: {
        Authorization: `JWT ${getToken()}`,
        'Content-Type': 'application/json',
      },
    }),

  sendPayment: (params) =>
    ajax({
      url: `/api/v1/pay`,
      method: 'POST',
      body: params,
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  getStripeOAuthLink: () =>
    ajax({
      url: `/api/v1/auth/get-stripe-oauth-link`,
      method: 'GET',
      headers: {
        Authorization: `JWT ${getToken()}`,
        'Content-Type': 'application/json',
      },
    }),

  authorizeStripeOAuth: (code, state) =>
    ajax({
      url: `/api/v1/auth/authorize-stripe-oauth?${qs.stringify({
        code,
        state,
      })}`,
      method: 'GET',
      headers: {
        Authorization: `JWT ${getToken()}`,
        'Content-Type': 'application/json',
      },
    }),

  getEarnings: () =>
    ajax({
      url: `/api/v1/user/get-earnings`,
      method: 'GET',
      headers: {
        Authorization: `JWT ${getToken()}`,
        'Content-Type': 'application/json',
      },
    }),

  getUsers: (params) =>
    ajax({
      url: `/api/v1/admin/get-users?${qs.stringify(params)}`,
      method: 'GET',
      headers: {
        Authorization: `JWT ${getToken()}`,
        'Content-Type': 'application/json',
      },
    }),

  getAdminPayouts: (params) =>
    ajax({
      url: `/api/v1/admin/money/get-payouts?${qs.stringify(params)}`,
      method: 'GET',
      headers: {
        Authorization: `JWT ${getToken()}`,
        'Content-Type': 'application/json',
      },
    }),

  getAdminPayoutPayments: (payoutId) =>
    ajax({
      url: `/api/v1/admin/money/get-payout-payments?${qs.stringify({
        payoutId,
      })}`,
      method: 'GET',
      headers: {
        Authorization: `JWT ${getToken()}`,
        'Content-Type': 'application/json',
      },
    }),

  getAdminPayments: (params) =>
    ajax({
      url: `/api/v1/admin/money/get-payments?${qs.stringify(params)}`,
      method: 'GET',
      headers: {
        Authorization: `JWT ${getToken()}`,
        'Content-Type': 'application/json',
      },
    }),
};

export default api;
