/* eslint-disable import/prefer-default-export */

export const signIn = (credentials) => ({
  type: 'SIGN_IN',
  payload: {
    credentials,
  },
});

export const signUp = (userInfo) => ({
  type: 'SIGN_UP',
  payload: {
    userInfo,
  },
});

export const subscribe = (subscriberInfo) => ({
  type: 'SUBSCRIBE',
  payload: {
    subscriberInfo,
  },
});

export const resetSubscribeFlag = () => ({
  type: 'RESET_SUBSCRIBE',
});

export const recover = (identity) => ({
  type: 'RECOVER',
  payload: {
    identity,
  },
});

export const recoverValidate = (recoveryToken) => ({
  type: 'RECOVER_VALIDATE',
  payload: {
    recoveryToken,
  },
});

export const recoverChangePassword = (recoveryToken, password, rePassword) => ({
  type: 'RECOVER_CHANGE_PASSWORD',
  payload: {
    recoveryToken,
    password,
    rePassword,
  },
});

export const clearRecoverEmailFlag = () => ({
  type: 'CLEAR_RECOVER_EMAIL_FLAG',
});

export const logout = () => ({
  type: 'LOGOUT',
});

export const updateUserInfo = (userInfo, token) => ({
  type: 'UPDATE_USER_INFO',
  payload: {
    userInfo,
    token,
  },
});

export const setGlobalInit = () => ({
  type: 'GLOBAL_INIT',
});

export const getAvatarUploadURL = (token) => ({
  type: 'GET_AVATAR_UPLOAD_URL',
  payload: {
    token,
  },
});

export const uploadAvatar = (file, signedURL) => ({
  type: 'UPLOAD_AVATAR',
  payload: {
    file,
    signedURL,
  },
});

export const clearUploadedAvatarKey = () => ({
  type: 'CLEAR_UPLOADED_AVATAR_KEY',
});

export const getCoverUploadURL = (token) => ({
  type: 'GET_COVER_UPLOAD_URL',
  payload: {
    token,
  },
});

export const uploadCover = (file, signedURL) => ({
  type: 'UPLOAD_COVER',
  payload: {
    file,
    signedURL,
  },
});

export const clearUploadedCoverKey = () => ({
  type: 'CLEAR_UPLOADED_COVER_KEY',
});

export const updateProfileInfo = (profileInfo) => ({
  type: 'UPDATE_PROFILE_INFO',
  payload: {
    profileInfo,
  },
});

export const updateProfileInfoReset = () => ({
  type: 'UPDATE_PROFILE_INFO_RESET',
});

export const getStripeOAuthLink = () => ({
  type: 'GET_STRIPE_OAUTH_LINK',
});

export const authorizeStripeOAuth = (code, state) => ({
  type: 'AUTHORIZE_STRIPE_OAUTH',
  payload: {
    code,
    state,
  },
});

export const updateProduct = (updates) => ({
  type: 'UPDATE_PRODUCT',
  payload: {
    updates,
  },
});
