import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col } from 'antd';
import QueueAnim from 'rc-queue-anim';
import {
  DownOutlined,
  MinusCircleOutlined,
  ShoppingCartOutlined,
  LeftOutlined,
} from '@ant-design/icons';
import { Dropdown, Button } from 'antd';
import {
  getEnabledProducts,
  createCheckout,
  addItemsToCheckout,
  removeItemsFromCheckout,
} from '../../utils/shopify';
import { getStoreName } from '../../actions/shopifyActions';

import './Merch.scss';

const Merch = () => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [products, setProducts] = useState([]);
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedQuantity, setSelectedQuantity] = useState(null);
  const [checkout, setCheckout] = useState(null);
  const { data: currentMusician } = useSelector(
    (state) => state.home.currentMusician
  );
  const { data: storeName } = useSelector((state) => state.shopify.storeName);
  const dispatch = useDispatch();

  console.log('DEBUG (shopify Museit API): storeName:', storeName);

  const defaultSize = useMemo(() => {
    return selectedProduct ? selectedProduct.variants[0].title : null;
  }, [selectedProduct]);

  const defaultQuantity = 1;

  useEffect(() => {
    dispatch(getStoreName());
  }, [dispatch]);

  useEffect(() => {
    const getProducts = async () => {
      const p = await getEnabledProducts(currentMusician.owner);
      console.log('currentMusician:', currentMusician);
      console.log('products:', p);
      setProducts(() => p);
    };

    const setupCheckout = async () => {
      const cOut = await createCheckout();
      setCheckout(cOut);
    };

    if (currentMusician.owner) {
      getProducts();
      setupCheckout();
    }
  }, [currentMusician.owner]);

  useEffect(() => {
    switch (currentStep) {
      case 1:
        setSelectedProduct(null);
        setSelectedSize(null);
        setSelectedQuantity(null);
        break;
      default:
        break;
    }
  }, [currentStep]);

  const handleSizeSelect = (selection) => {
    setSelectedSize(selection.key);
  };

  const handleQuantitySelect = (selection) => {
    setSelectedQuantity(parseInt(selection.key));
  };

  const handleSelectionsSubmit = useCallback(async () => {
    const size = selectedSize || defaultSize;
    const variant = selectedProduct.variants.find(
      (variant) => variant.title === size
    );
    const lineItems = [
      {
        variantId: variant.id,
        quantity: selectedQuantity || defaultQuantity,
      },
    ];
    const newCheckout = await addItemsToCheckout(checkout.id, lineItems);
    setCheckout(newCheckout);

    setCurrentStep(3);
  }, [checkout, selectedSize, defaultSize, selectedProduct, selectedQuantity]);

  const handleCheckoutSubmit = useCallback(() => {
    if (checkout) {
      window.open(checkout.webUrl, '_blank');
      setCurrentStep(4);
    }
  }, [checkout]);

  const handleCartClick = () => {
    setCurrentStep(3);
  };

  const handleBackClick = () => {
    setCurrentStep(1);
  };

  const handleRemoveFromCartClick = useCallback(
    async (index) => {
      const lineItemIDs = [checkout.lineItems[index].id];
      const newCheckout = await removeItemsFromCheckout(
        checkout.id,
        lineItemIDs
      );
      setCheckout(newCheckout);
    },
    [checkout]
  );

  const sizeItems = selectedProduct
    ? selectedProduct.variants.map((variant) => ({
        key: variant.title,
        label: variant.title,
      }))
    : [];
  const quantityItems = [1, 2, 3, 4].map((quantity) => ({
    key: quantity,
    label: quantity,
  }));

  const productElms = products.map((product, index) => {
    if (currentStep !== 1) return null;
    return (
      <div
        className='products__product'
        onClick={() => {
          setSelectedProduct(product);
          setCurrentStep(2);
        }}
        key={`2-${index}`}
      >
        <img src={product.images[0].src} className='product__img' alt=''></img>
        <p className='product__title'>{product.title}</p>
        <p className='product__price'>${product.variants[0].price.amount}</p>
      </div>
    );
  });

  const cartElms = !checkout
    ? null
    : checkout.lineItems.map((lineItem, index) => {
        return (
          <div className='merch__product-in-cart' key={`2-${index}`}>
            <div className='product__remove'>
              <MinusCircleOutlined
                style={{ fontWeight: 'bold', color: 'red', cursor: 'pointer' }}
                onClick={() => {
                  handleRemoveFromCartClick(index);
                }}
              />
            </div>
            <div className='product__quantity'>{`${lineItem.quantity} x `}</div>
            <img
              src={lineItem.variant.image.src}
              className='product__img'
              alt=''
            />
            <div className='product__price-size'>
              <div className='price'>${lineItem.variant.price.amount}</div>
              <div className='size'>{lineItem.variant.size}</div>
            </div>
          </div>
        );
      });

  if (products.length === 0) return null;

  return (
    <>
      {/* Creates the general bounds */}
      <Col
        className='merch-wrapper'
        xs={24}
        sm={24}
        md={24}
        lg={24}
        xl={24}
        xxl={24}
        justify='center'
        align='middle'
      >
        {/* Centers and defines additional bounds */}
        <div className='merch'>
          {/* We need because multiple QueueAnims due to products grid */}
          <section className='step-1'>
            <QueueAnim
              type={['bottom', 'top']}
              delay={[1600, 500]}
              interval={[100, 100]}
              duration={[400, 400]}
              appear={false}
            >
              {currentStep === 1 && (
                <h4 key='1' className='merch__title'>
                  Merch
                </h4>
              )}
            </QueueAnim>
            <QueueAnim
              className={'step-1__products'}
              type={['bottom', 'top']}
              delay={[1600, 500]}
              interval={[100, 100]}
              duration={[400, 400]}
              appear={false}
            >
              {productElms}
            </QueueAnim>
          </section>

          {/* Positioned absolutely because we want to overlay on top of it freely */}
          <QueueAnim
            className={'beyond-step-1__background'}
            type={['bottom', 'top']}
            delay={[1600, 500]}
            interval={[100, 100]}
            duration={[400, 400]}
            appear={false}
          >
            {currentStep > 1 && (
              <div className='merch__background' key='0'></div>
            )}
          </QueueAnim>

          {/* We use to keep things organized and consistent */}
          <section className='step-2'>
            <QueueAnim
              type={['bottom', 'top']}
              // delay={[500, 500]}
              delay={[1600, 500]}
              interval={[100, 100]}
              duration={[400, 400]}
              className={'step-2__selections'}
            >
              {currentStep === 2 && [
                <h2 key='1' className='merch__title'>
                  {selectedProduct.title}
                </h2>,
                <div key='2' className='selections__dropdown'>
                  <div className='dropdown__label'>Size</div>
                  <Dropdown
                    menu={{
                      items: sizeItems,
                      selectable: true,
                      defaultSelectedKeys: [defaultSize],
                      onSelect: handleSizeSelect,
                    }}
                    overlayClassName='dropdown__overlay'
                  >
                    <Button className='dropdown__btn'>
                      {selectedSize || defaultSize}
                      <div className='btn__icon'>
                        <DownOutlined />
                      </div>
                    </Button>
                  </Dropdown>
                </div>,
                <div key='3' className='selections__dropdown'>
                  <div className='dropdown__label'>Quantity</div>
                  <Dropdown
                    menu={{
                      items: quantityItems,
                      selectable: true,
                      defaultSelectedKeys: [defaultQuantity],
                      onSelect: handleQuantitySelect,
                    }}
                    overlayClassName='dropdown__overlay'
                  >
                    <Button className='dropdown__btn'>
                      {selectedQuantity || defaultQuantity}
                      <div className='btn__icon'>
                        <DownOutlined />
                      </div>
                    </Button>
                  </Dropdown>
                </div>,
                <hr key='4' className='merch__line' />,

                <div key='5' className='merch__product-in-cart'>
                  <div className='product__quantity'>{`${
                    selectedQuantity || defaultQuantity
                  } x `}</div>
                  <img
                    src={selectedProduct.images[0].src}
                    className='product__img'
                    alt=''
                  />
                  <div className='product__price-size'>
                    <div className='price'>
                      ${selectedProduct.variants[0].price.amount}
                    </div>
                    <div className='size'>{selectedSize || defaultSize}</div>
                  </div>
                </div>,
                <Button
                  key='6'
                  justify='center'
                  className={'btn-custom-submit merch__submit-btn'}
                  type='primary'
                  htmlType='button'
                  onClick={handleSelectionsSubmit}
                >
                  Add to Cart
                </Button>,
              ]}
            </QueueAnim>
          </section>
          <section className='step-3'>
            <QueueAnim
              type={['bottom', 'top']}
              delay={[1600, 500]}
              interval={[100, 100]}
              duration={[400, 400]}
              className='step-3__cart'
              // onEnd={handleStep3Finished}
            >
              {currentStep === 3 && [
                <h2 key='1' className='merch__title'>
                  Merch Cart
                </h2>,
                cartElms,
                <hr key='2' className='merch__line' />,
                <Button
                  key='3'
                  justify='center'
                  className={'btn-custom-submit merch__submit-btn'}
                  type='primary'
                  htmlType='button'
                  onClick={handleCheckoutSubmit}
                >
                  Proceed to Checkout
                </Button>,
              ]}
            </QueueAnim>
          </section>
          <section className='step-4'>
            <QueueAnim
              type={['bottom', 'top']}
              delay={[1600, 500]}
              interval={[100, 100]}
              duration={[400, 400]}
              className='step-4__checkout'
            >
              {currentStep === 4 && [
                <div key='1' className='next-tab'>
                  <p className='main-text'>Checkout in next tab.</p>
                  <p className='subtext'>
                    Don't see a link? Click{' '}
                    <a href={checkout.webUrl} target='_blank'>
                      here
                    </a>
                    .
                  </p>
                </div>,
              ]}
            </QueueAnim>
          </section>
          <QueueAnim
            type={['top', 'top']}
            delay={[1600, 500]}
            interval={[100, 100]}
            duration={[400, 400]}
          >
            {currentStep !== 1 && (
              <div
                className='merch__floating-btn back'
                onClick={handleBackClick}
                key='1'
              >
                <LeftOutlined className='floating-btn__back-icon' />
                <div className='floating-btn__text'>Shop</div>
              </div>
            )}
          </QueueAnim>
          <QueueAnim
            type={['top', 'top']}
            delay={[1600, 500]}
            interval={[100, 100]}
            duration={[400, 400]}
          >
            {currentStep !== 3 && (
              <div
                className='merch__floating-btn cart'
                onClick={handleCartClick}
                key='2'
              >
                <div className='floating-btn__cart-icon-wrapper'>
                  <ShoppingCartOutlined className='floating-btn__cart-icon' />
                  {checkout && checkout.lineItems.length > 0 && (
                    <div className='floating-btn__cart-filled-indicator' />
                  )}
                </div>
              </div>
            )}
          </QueueAnim>
        </div>
      </Col>
    </>
  );
};

export default Merch;
