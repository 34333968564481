import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Tooltip } from 'antd';
import {
  CrownOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  LogoutOutlined,
  IdcardOutlined,
  WalletOutlined,
  CalendarOutlined,
  InfoCircleOutlined,
  QuestionCircleOutlined,
  LoginOutlined,
  UserAddOutlined,
  BankOutlined,
  DollarOutlined,
  QrcodeOutlined,
  SkinOutlined,
} from '@ant-design/icons';
import { BellIcon } from '../../utils/CustomIcons';
import { NavLink, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toggleMainMenu, closeMainMenu, logout } from '../../actions';
import './MyAccountMenu.scss';
import logo from '../images/logo.svg';
import bgWaves from '../images/my-account-wave-bg.svg';

export default function MyAccountMenu() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (!isAuthenticated) {
      history.replace('/signin');
    }
  }, [isAuthenticated, history]);

  const showTooltip = !(
    user &&
    user.musician &&
    user.musician.avatar &&
    user.musician.biography &&
    user.musician.slug &&
    user.stripe &&
    user.stripe.connected === true &&
    user.last4ssn
  );

  return (
    <section
      className='MyAccountMenu'
      style={{ backgroundImage: `url(${bgWaves})` }}
    >
      <a className='logo' rel='noopener noreferrer' href='https://museit.co'>
        <figure>
          <img src={logo} alt='' />
        </figure>
      </a>
      <nav className={classNames('navigation')}>
        {isAuthenticated && (
          <ul className='navigation__list'>
            <li className=''>
              <NavLink
                className=''
                activeClassName='active'
                to='/my-account/edit-profile'
                href='/my-account/edit-profile'
                onClick={() => {
                  dispatch(closeMainMenu());
                }}
              >
                <UserOutlined className='nav-item-icon' /> My Profile{' '}
                {showTooltip && (
                  <Tooltip
                    placement='right'
                    className='extra-info'
                    title='Please finish setting up your account'
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                )}
              </NavLink>
            </li>
            {/*
          <li className="">
            <NavLink
              className=""
              activeClassName="active"
              to="/my-account/messages"
              href="/my-account/messages"
              onClick={() => { dispatch(closeMainMenu()) }}
            >
              <BellIcon className="nav-item-icon" /> Messages
            </NavLink>
          </li>
          */}
            {user && user.musician && user.musician.slug && (
              <li className=''>
                <NavLink
                  className=''
                  activeClassName='active'
                  to={`/u/${user.musician.slug}`}
                  href={`/u/${user.musician.slug}`}
                  onClick={() => {
                    dispatch(closeMainMenu());
                  }}
                >
                  <IdcardOutlined className='nav-item-icon' /> My Page
                </NavLink>
              </li>
            )}
            <li className=''>
              <NavLink
                className=''
                activeClassName='active'
                to='/my-account/my-money'
                href='/my-account/my-money'
                onClick={() => {
                  dispatch(closeMainMenu());
                }}
              >
                <WalletOutlined className='nav-item-icon' /> My Money
              </NavLink>
            </li>
            {false && (
              <li className=''>
                <NavLink
                  className=''
                  activeClassName='active'
                  to='/my-account/my-events'
                  href='/my-account/my-events'
                  onClick={() => {
                    dispatch(closeMainMenu());
                  }}
                >
                  <CalendarOutlined className='nav-item-icon' /> My Events
                </NavLink>
              </li>
            )}
            <li className=''>
              <a href='https://www.museit.co/faq' target='_blank'>
                <QuestionCircleOutlined className='nav-item-icon' /> FAQ
              </a>
            </li>
            {user.role === 'admin' && (
              <li className=''>
                <NavLink
                  className=''
                  activeClassName='active'
                  to='/my-account/admin/users'
                  href='/my-account/admin/users'
                  onClick={() => {
                    dispatch(closeMainMenu());
                  }}
                >
                  <UsergroupAddOutlined className='nav-item-icon' /> Users
                </NavLink>
              </li>
            )}
            {user.role === 'admin' && (
              <li className=''>
                <NavLink
                  className=''
                  activeClassName='active'
                  to='/my-account/admin/payments'
                  href='/my-account/admin/payments'
                  onClick={() => {
                    dispatch(closeMainMenu());
                  }}
                >
                  <DollarOutlined className='nav-item-icon' /> Payments
                </NavLink>
              </li>
            )}
            {user.role === 'admin' && (
              <li className=''>
                <NavLink
                  className=''
                  activeClassName='active'
                  to='/my-account/admin/payouts'
                  href='/my-account/admin/payouts'
                  onClick={() => {
                    dispatch(closeMainMenu());
                  }}
                >
                  <BankOutlined className='nav-item-icon' /> Payouts
                </NavLink>
              </li>
            )}
            <li className=''>
              <NavLink
                className=''
                activeClassName='active'
                to='/my-account/marketing'
                href='/my-account/marketing'
                onClick={() => {
                  dispatch(closeMainMenu());
                }}
              >
                <CrownOutlined className='nav-item-icon' /> Marketing
              </NavLink>
            </li>
            <li className=''>
              <NavLink
                className=''
                activeClassName='active'
                to='/my-account/merch'
                href='/my-account/merch'
                onClick={() => {
                  dispatch(closeMainMenu());
                }}
              >
                <SkinOutlined className='nav-item-icon' /> Merch
              </NavLink>
            </li>
            <li className='li-logout'>
              <NavLink
                onClick={() => {
                  history.replace('/');
                }}
                className='logout'
                activeClassName='active'
                to='/logout'
                href='/logout'
              >
                <LogoutOutlined className='nav-item-icon' /> Log out
              </NavLink>
            </li>
          </ul>
        )}
        {!isAuthenticated && (
          <ul className='navigation__list'>
            <li className=''>
              <NavLink
                className=''
                activeClassName='active'
                to='/signin'
                href='/signin'
                onClick={() => {
                  dispatch(closeMainMenu());
                }}
              >
                <LoginOutlined className='nav-item-icon' /> Sign In
              </NavLink>
            </li>
            <li className=''>
              <NavLink
                className=''
                activeClassName='active'
                to='/signup'
                href='/signup'
                onClick={() => {
                  dispatch(closeMainMenu());
                }}
              >
                <UserAddOutlined className='nav-item-icon' /> Sign Up
              </NavLink>
            </li>
            <li className=''>
              <a href='https://www.museit.co/faq' target='_blank'>
                <QuestionCircleOutlined className='nav-item-icon' /> FAQ
              </a>
            </li>
          </ul>
        )}
      </nav>
    </section>
  );
}
