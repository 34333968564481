import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { message, Upload } from 'antd'
import classNames from 'classnames'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { getCoverUploadURL, uploadCover } from '../../../actions'
import ImageTools from '../../../utils/ImageTools'
import './CoverUploader.scss'


const MyImageTools = new ImageTools();

export default function CoverUploader () {
  const { isLoading, errors: authErrors, isRequestingCoverURL, isUploadingCover, signedCoverUploadURL, uploadedCoverKey, token } = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const s3Base = useSelector(state => state.config.s3Base)
  const { musician } = useSelector(state => state.auth.user)
  const [coverFile, setCoverFile] = useState()
  const [isCoverHovered, setIsCoverHovered] = useState(false)

  // Here we catch when we get the signed url from backend.
  useEffect(() => {
    if(!isUploadingCover && signedCoverUploadURL && coverFile){
      dispatch(uploadCover(coverFile, signedCoverUploadURL))
    }
  }, [isUploadingCover, coverFile, signedCoverUploadURL, dispatch])

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG or PNG file!');
    }
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
      message.error('Image must smaller than 10MB!');
    }

    MyImageTools.resize(file, { width: 1920, height: 1080 }).then((blob) => {
       // upload blob to server
       setCoverFile(blob) // Store the avatar file to upload it after we get a signed url.
       dispatch(getCoverUploadURL(token)) // Here we request for the avatar upload url.
    })

    // if(isJpgOrPng && isLt2M){
    //   const format = (file.type === 'image/jpeg') ? 'jpg' : 'png'
    //   setCoverFile(file) // Store the avatar file to upload it after we get a signed url.
    //   dispatch(getCoverUploadURL(format, token)) // Here we request for the avatar upload url.
    // }

    return false // Return false to prevent Upload Component submit.
  }

  const uploadButton = (
    <div className="Cover__upload-button">
      {(isUploadingCover || isRequestingCoverURL) ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="ant-upload-text">{(isUploadingCover || isRequestingCoverURL) ? 'Uploading...' : 'Upload Cover Image'}</div>
    </div>
  );

  return (
    <section className="Cover">
      <Upload
        name="cover"
        // listType="picture-card"
        className="cover-uploader"
        showUploadList={false}
        beforeUpload={beforeUpload}
        disabled={(isUploadingCover || isRequestingCoverURL)}
      >
        <section
          className={classNames("Cover__wrapper", { disabled: (isRequestingCoverURL || isUploadingCover)})}
          onMouseEnter={() => setIsCoverHovered(true)}
          onMouseLeave={() => setIsCoverHovered(false)}
        >
          <section className="Cover__profile-image">
            {(musician.cover) && <div className="bg-profile-image" style={{ width: '100%', backgroundImage: `url(${(s3Base + musician.cover)})` }} />}
            {(isRequestingCoverURL || isUploadingCover || isCoverHovered || !musician.cover) && uploadButton}
          </section>

        </section>
      </Upload>
    </section>
  )
}
