import React, { useEffect } from 'react'
import './Logout.scss'
import { useDispatch } from 'react-redux'
import { closeMainMenu, logout } from '../../actions'
import { useHistory } from 'react-router-dom'

export default function Logout() {
    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(()=> {
      dispatch(logout())
      dispatch(closeMainMenu())
      history.replace('/signin')
    }, [dispatch, history])

    return (
      <section className="Logout">
        <h1>Logged Out.</h1>
      </section>
    );
}
