/* eslint-disable import/prefer-default-export */
import api from '../utils/api';
import { getErrorResponse } from '../utils/apiErrorHandler';

import { ofType } from 'redux-observable';
import { map, takeUntil, defaultIfEmpty, startWith, catchError, mergeMap } from 'rxjs/operators'
import { from } from 'rxjs'

export const getMusician = action$ =>
  action$.pipe(  //fixed
    ofType('GET_MUSICIAN'),
    mergeMap(action => {
    return from(api.getMusician(action.payload.musicianSlug)).pipe(
        map(response => ({ type: 'GET_MUSICIAN_SUCCESS', payload: response.response })),
        startWith({ type: 'GET_MUSICIAN_PENDING', payload: action.payload }),
        takeUntil(action$.pipe(
          ofType('GET_MUSICIAN_ABORTED')
        )),
        catchError(error => getErrorResponse({ type: 'GET_MUSICIAN_ERROR', error })),
        defaultIfEmpty({ type: 'GET_MUSICIAN_CANCELED' }),
      );
    })
  );

export const sendPayment = action$ =>
  action$.pipe(  //fixed
    ofType('SEND_PAYMENT'),
    mergeMap(action => {
    return from(api.sendPayment(action.payload)).pipe(
        map(response => ({ type: 'SEND_PAYMENT_SUCCESS', payload: response.response })),
        startWith({ type: 'SEND_PAYMENT_PENDING', payload: action.payload }),
        takeUntil(action$.pipe(
          ofType('SEND_PAYMENT_ABORTED')
        )),
        catchError(error => getErrorResponse({ type: 'SEND_PAYMENT_ERROR', error })),
        defaultIfEmpty({ type: 'SEND_PAYMENT_CANCELED' }),
      );
    })
  );
