/* eslint-disable import/prefer-default-export */
import api from "../utils/api";
import { getErrorResponse } from "../utils/apiErrorHandler";
import { ofType } from "redux-observable";
import {
  map,
  takeUntil,
  defaultIfEmpty,
  startWith,
  catchError,
  mergeMap,
} from "rxjs/operators";

export const getStoreName = (action$) => {
  return action$.pipe(
    ofType("GET_STORE_NAME"),
    // Let's merge the redux observable with the ajax observable
    mergeMap((action) => {
      // Note: Removing the unncessary "from" used in other epics
      return api.getStoreName().pipe(
        map((response) => {
          console.log("payload:", response.response);
          return {
            type: "GET_STORE_NAME_SUCCESS",
            payload: response.response,
          };
        }),
        // Provide an initial value
        startWith({
          type: "GET_STORE_NAME_PENDING",
          payload: action.payload,
        }),
        // Stop the ajax observable when the redux observable emits this
        takeUntil(action$.pipe(ofType("GET_STORE_NAME_ABORTED"))),
        catchError((error) =>
          getErrorResponse({ type: "GET_STORE_NAME_ERROR", error })
        ),
        // If we abort above before any values are emitted
        defaultIfEmpty({
          type: "GET_STORE_NAME_CANCELED",
        })
      );
    })
  );
};
