import baseAttributes from "../storeBaseAttributes";
import { omit } from "lodash";

const adminReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_ADMIN_PAYOUTS_PENDING":
      return {
        ...state,
        payouts: {
          ...state.payouts,
          ...baseAttributes,
          isLoading: true,
        },
      };
    case "GET_ADMIN_PAYOUTS_ERROR":
      return {
        ...state,
        payouts: {
          ...state.payouts,
          ...baseAttributes,
          errors: {
            ...state.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case "GET_ADMIN_PAYOUTS_ABORTED":
      return {
        ...state,
        payouts: {
          ...state.payouts,
          ...baseAttributes,
        },
      };
    case "GET_ADMIN_PAYOUTS_SUCCESS":
      return {
        ...state,
        payouts: {
          ...state.payouts,
          ...baseAttributes,
          data: action.payload.payouts,
          pagination: action.payload.pagination,
          rehydrated: true,
        },
      };
    case "GET_ADMIN_PAYOUT_PAYMENTS_PENDING":
      return {
        ...state,
        payouts: {
          ...state.payouts,
          data: state.payouts.data.map((payout) => {
            console.log(payout.id, action.payload.payoutId);
            if (payout.id === action.payload.payoutId) {
              return {
                ...payout,
                payments: {
                  ...payout.payments,
                  ...baseAttributes,
                  isLoading: true,
                },
              };
            } else {
              return payout;
            }
          }),
        },
      };
    case "GET_ADMIN_PAYOUT_PAYMENTS_ERROR":
      return {
        ...state,
        payouts: {
          ...state.payouts,
          data: state.payouts.data.map((payout) => {
            if (payout.id === action.payload.payoutId) {
              return {
                ...payout,
                payments: {
                  ...payout.payments,
                  ...baseAttributes,
                  errors: {
                    ...state.errors,
                    hasError: action.payload.error,
                    code: action.payload.code,
                    message: action.payload.message,
                    all: action.payload.errors,
                  },
                },
              };
            } else {
              return payout;
            }
          }),
        },
      };
    case "GET_ADMIN_PAYOUT_PAYMENTS_ABORTED":
      return {
        ...state,
        payouts: {
          ...state.payouts,
          data: state.payouts.data.map((payout) => {
            if (payout.id === action.payload.payoutId) {
              return {
                ...payout,
                payments: {
                  ...payout.payments,
                  ...baseAttributes,
                },
              };
            } else {
              return payout;
            }
          }),
        },
      };
    case "GET_ADMIN_PAYOUT_PAYMENTS_SUCCESS":
      return {
        ...state,
        payouts: {
          ...state.payouts,
          data: state.payouts.data.map((payout) => {
            if (payout.id === action.payload.payoutId) {
              return {
                ...payout,
                payments: {
                  ...payout.payments,
                  ...baseAttributes,
                  data: action.payload.payments,
                  rehydrated: true,
                },
              };
            } else {
              return payout;
            }
          }),
        },
      };
    case "GET_USERS_PENDING":
      return {
        ...state,
        users: {
          ...state.users,
          ...baseAttributes,
          isLoading: true,
        },
      };
    case "GET_USERS_ERROR":
      return {
        ...state,
        users: {
          ...state.users,
          ...baseAttributes,
          errors: {
            ...state.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case "GET_USERS_ABORTED":
      return {
        ...state,
        users: {
          ...state.users,
          ...baseAttributes,
        },
      };
    case "GET_USERS_SUCCESS":
      return {
        ...state,
        users: {
          ...state.users,
          ...baseAttributes,
          data: action.payload.users,
          pagination: action.payload.pagination,
          rehydrated: true,
        },
      };
    case "SELECT_USER_TO_EDIT":
      return {
        ...state,
        users: {
          ...state.users,
          selectedUser: action.payload.user,
        },
      };
    case "SET_DOWNLOADING_PDF":
      return {
        ...state,
        users: {
          ...state.users,
          isDownloadingPDF: action.payload.isDownloadingPDF,
        },
      };
    case "SET_DOWNLOADING_QR":
      return {
        ...state,
        users: {
          ...state.users,
          isDownloadingQR: action.payload.isDownloadingQR,
        },
      };
    case "GET_ADMIN_PAYMENTS_PENDING":
      return {
        ...state,
        payments: {
          ...state.payments,
          ...baseAttributes,
          isLoading: true,
        },
      };
    case "GET_ADMIN_PAYMENTS_ERROR":
      return {
        ...state,
        payments: {
          ...state.payments,
          ...baseAttributes,
          errors: {
            ...state.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case "GET_ADMIN_PAYMENTS_ABORTED":
      return {
        ...state,
        payments: {
          ...state.payments,
          ...baseAttributes,
        },
      };
    case "GET_ADMIN_PAYMENTS_SUCCESS":
      return {
        ...state,
        payments: {
          ...state.payments,
          ...baseAttributes,
          data: action.payload.payments,
          pagination: action.payload.pagination,
          rehydrated: true,
        },
      };
    default:
      return state;
  }
};

export default adminReducer;
