import React from "react";
import classNames from "classnames";
import { Route, Switch, Redirect } from "react-router-dom";
import { Row, Col, Affix } from "antd";

import MyAccountMenu from "./MyAccountMenu";
import Profile from "./profile/Profile";
import FAQ from "./faq/FAQ";
import MyMoney from "./my-money/MyMoney";
import Messages from "./messages/Messages";
import Users from "./admin/users/Users";
import Payouts from "./admin/payouts/Payouts";
import Payments from "./admin/payments/Payments";
import Marketing from "./marketing/Marketing";
import Merch from "./merch/Merch";

import "./MyAccount.scss";

export default function MyAccount() {
  return (
    <Row
      className={classNames("MyAccount", { inRoot: true })}
      justify="center"
      align="middle"
    >
      <Col xs={22} sm={22} md={22} lg={22} xl={23} xxl={23}>
        <Row>
          <Col xs={24} sm={24} md={6} lg={5} xl={4} xxl={4}>
            <Route path="/my-account" component={MyAccountMenu} />
          </Col>
          <Col
            className="MyAccount__content"
            xs={24}
            sm={24}
            md={{ span: 17, offset: 1 }}
            lg={{ span: 18, offset: 1 }}
            xl={{ span: 19, offset: 1 }}
            xxl={{ span: 19, offset: 1 }}
          >
            <Switch>
              <Route
                exact
                path="/my-account"
                component={() => <Redirect to="/my-account/edit-profile" />}
              />
              <Route exact path="/my-account/messages" component={Messages} />
              <Route
                exact
                path="/my-account/edit-profile"
                component={Profile}
              />
              <Route exact path="/my-account/faq" component={FAQ} />
              <Route exact path="/my-account/my-money" component={MyMoney} />
              <Route exact path="/my-account/admin/users" component={Users} />
              <Route
                exact
                path="/my-account/admin/payouts"
                component={Payouts}
              />
              <Route
                exact
                path="/my-account/admin/payments"
                component={Payments}
              />
              <Route exact path="/my-account/marketing" component={Marketing} />
              <Route exact path="/my-account/merch" component={Merch} />
            </Switch>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
