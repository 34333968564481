import React from 'react'
import './ArtBubbles.scss'

export default function ArtBubbles () {
  return (
    <div className="ArtBubbles">
      <div className="customer-circle customer-circle-01"></div>
      <div className="customer-circle customer-circle-02"></div>
      <div className="customer-circle customer-circle-03"></div>
      <div className="customer-circle customer-circle-04"></div>
      <div className="customer-circle customer-circle-05"></div>
      <div className="customer-circle customer-circle-06"></div>
      <div className="customer-circle customer-circle-07"></div>
      <div className="bg-element bg-social"></div>
    </div>
  )
}
