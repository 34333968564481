import React, { useEffect, useState, useRef } from "react";
import { QRCode } from 'react-qrcode-logo'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import { Alert, Form, Input, Button, Checkbox, Divider, message, Upload, Row, Col, notification, Tooltip, Tag, DatePicker, Menu, Dropdown } from 'antd'
import qrLogo from './assets/qr-logo.png'
import { LoadingOutlined, PlusOutlined, InfoCircleOutlined, CheckCircleOutlined, MinusCircleOutlined, CloudDownloadOutlined } from '@ant-design/icons'
// import domtoimage from 'dom-to-image';
import * as htmlToImage from 'html-to-image';
import { jsPDF } from "jspdf";
import classNames from 'classnames'
import { setDownloadingPDF, setDownloadingQR } from '../../../../actions'


import waves from './assets/waves.png'
import googlePay from './assets/google-pay.png'
import applePay from './assets/apple-pay.png'
import creditCard from './assets/credit-card.png'
import TextareaAutosize from 'react-autosize-textarea';

import './Advertising.scss'

let pdf = new jsPDF({
    orientation: 'p', // landscape
    unit: 'px', // points, pixels won't work properly
    format: 'a4' // set needed dimensions for any element
});

export default function AdvertisingGenerator() {
    const QRImageRef = useRef(null);
    // const QRImagePreviewRef = useRef(null);
    const { selectedUser: user, isDownloadingPDF, isDownloadingQR } = useSelector(state => state.admin.users)
    const dispatch = useDispatch()

    const [saved, setSaved] = useState(false)
    const [isEditing, setEditing] = useState(false)
    
    const [imageLoaded, setImageLoaded] = useState(false)
    const [isDownloading, setIsDownloading] = useState(false)
    const [isPreparing, setIsPreparing] = useState(false)


    // const [QRImageData, setQRImageData] = useState(null)
    const [editableFields, updateFields] = useState({
        headline: 'Scan qr code to send us a tip 🙂',
        musicianName: ((user && user.musician && user.musician.name) ? user.musician.name : 'No Name'),
        poweredBy: 'Powered By Museit.co'
    })

    const friendlyURL = `${window.location.hostname}/u/${ (user && user.musician) ? user.musician.slug : ''}`;
    //`app.museit.co/u/teesting-for-big-slugs-123-test-original-url`;

    useEffect(() => {
        // Cleaning the PDF document
        pdf = new jsPDF({
            orientation: 'p', // landscape
            unit: 'px', // points, pixels won't work properly
            format: 'a4' // set needed dimensions for any element
        })

        updateFields({
            headline: 'Scan qr code to send us a tip 🙂',
            musicianName: (user && user.musician && user.musician.name ? user.musician.name : 'No Name'),
            poweredBy: 'Powered By Museit.co'
        })

    }, [user])

    // External Trigger to Automatically Download PDF
    useEffect(() => {
        if (isDownloadingPDF && !isPreparing){
            console.log('is Downloading PDF')
            downloadPDF()
        }
    }, [isDownloadingPDF, isPreparing])

    // External Trigger to Automatically Download PDF
    useEffect(() => {
        if (isDownloadingQR) {
            console.log('is Downloading QR')
            downloadQR()
        }
    }, [isDownloadingQR])

    const downloadQR = () => {
        const canvas = document.getElementById("react-qrcode-logo");
        const pngFile = canvas.toDataURL("image/png");
        const downloadLink = document.createElement("a");
        downloadLink.download = `${(user && user.musician && user.musician.name ? user.musician.name.replace(/\s/g, '_') : 'No Name')}_QR_320x320`;
        downloadLink.href = `${pngFile}`;
        downloadLink.click();
        dispatch(setDownloadingQR(false));
    };


    const handleChange = (value, key) => {
        if(isEditing){
            const element = document.getElementById(`preview_field_${key}`)
            if ((key === 'musicianName' && element.offsetHeight <= 220) || (key === 'headline' && element.offsetHeight <= 39)){
                updateFields({
                    ...editableFields,
                    [key]: value
                })
            } else if ((key === 'musicianName' && element.offsetHeight > 220) || (key === 'headline' && element.offsetHeight > 39)) {
                updateFields({
                    ...editableFields,
                    [key]: value.slice(0, -1).trim()
                })
            }
        }
    }

    const saveEditChanges = () => {
        if(isEditing){
            setEditing(false)
            setSaved(true)
            setTimeout(() => setSaved(false), 600)
        } else {
            setEditing(true)
        }
    }
    
    // 1. Obtaining Updated QRCode (PDF)
    const downloadPDF = () => {
        setIsPreparing(true)
        // This is the first step of 4 Async steps:
        // 1. Obtain QRCode as an image from the react-qrcode-logo canvas element, and load it as an image tag in the preview zone.
        // 2. Once the image is loaded (using the onLoad) listener, setImageLoaded(true), this will be catch by a useEffect.
        // 3. On Image Loaded useEffect we set up the pdf config, and convert the HTML to Image and add it to the PDF.
        // 4. Ready for Dowload useEffect, triggers the download pdf.
    
        const canvas = document.getElementById("react-qrcode-logo");
        const pngFile = canvas.toDataURL("image/png");
        
        QRImageRef.current.onload = () => {
            setImageLoaded(true)
        };
        QRImageRef.current.src = pngFile;
        // setQRImageData(pngFile);
    }

    // 2. Setting Up PDF Config.
    useEffect(() => {
        if (imageLoaded) {
            // Cleaning the PDF.
            pdf = new jsPDF({
                orientation: 'p', // landscape
                unit: 'px', // points, pixels won't work properly
                format: 'a4' // set needed dimensions for any element
            });

            // Setting up PDF config.
            const page = document.getElementById('MainPage');

            const pageWidth = page.offsetWidth / 2;
            const pageHeight = page.offsetHeight / 2;
            console.log('Image Dimentions: ', pageWidth, pageHeight);

            const PDFWidth = pdf.internal.pageSize.width;
            const PDFHeight = pdf.internal.pageSize.height;
            console.log('PDF Dimentions: ', PDFWidth, PDFHeight);

            htmlToImage.toPng(page).then((dataUrl) => {
                const x = (PDFWidth - pageWidth) / 2;
                pdf.addImage(dataUrl, 'JPEG', x, 40, pageWidth, pageHeight);
                // pdf.output('dataurlnewwindow');
                setIsDownloading(true);
            });
        }
    }, [imageLoaded])

    // 4. Ready to Download (PDF)
    useEffect(() => {
        if (isDownloading) {
            setIsDownloading(false);
            setIsPreparing(false);
            setImageLoaded(false);
            // Notify Globally it's not downloading anymore
            dispatch(setDownloadingPDF(false));
            pdf.save(`${(user && user.musician && user.musician.name ? user.musician.name.replace(/\s/g, '_') : 'No Name')}_Flyer_${new Date().toISOString()}.pdf`);
        }
    })

    const isiOS = () => {
        return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
            // iPad on iOS 13 detection
            || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }

    return (<section className="AdvertisingGenerator">
        <section className="AdvertisingGenerator__tool">
            <section className="tool-bar">
                <span className="preview-span">Preview:</span>
                <Button
                    onClick={() => saveEditChanges()}
                    className="save-changes"
                    type="primary">
                    { isEditing ? 'Save' : 'Edit' }
                </Button>
            </section>
            <section className="Preview__wrapper">
                <section className={classNames("MainPage preview", { editing: isEditing })}>
                    <section className="MainPage__wrapper">
                        <TextareaAutosize
                            id="preview_field_headline"
                            className="Headline"
                            rows={1}
                            disabled={!isEditing}
                            value={editableFields.headline}
                            spellCheck="false"
                            onChange={(e) => handleChange(e.target.value, 'headline')} />
                        <TextareaAutosize
                            id="preview_field_musicianName"
                            className="MusicianName"
                            rows={1}
                            disabled={!isEditing}
                            value={editableFields.musicianName}
                            spellCheck="false"
                            onChange={(e) => handleChange(e.target.value, 'musicianName')} />

                        <div className="waves" style={{ backgroundImage: `url('${waves}')` }} ></div>
                        <section className="donation-methods">
                            <div className="method-logo" style={{ backgroundImage: `url('${googlePay}')` }} />
                            <div className="method-logo" style={{ backgroundImage: `url('${applePay}')` }} />
                            <div className="method-logo" style={{ backgroundImage: `url('${creditCard}')` }} />
                        </section>
                        <div className="ExportedImageQR">
                            {(user && user.musician && user.musician.slug) &&
                                <QRCode
                                    size={420}
                                    value={`${window.location.origin}/u/${user.musician.slug}`}
                                    logoImage={qrLogo}
                                    logoWidth={130}
                                    logoHeight={160}
                                    logoOpacity={0.8}
                                    // quietZone={0}
                                    ecLevel="H"
                                />
                            }
                        </div>
                        <h2 className="plain-musician-url">Or visit us at: <div className={classNames("band-url", { block: friendlyURL.length > 40 })}>{friendlyURL}</div></h2>
                        <h2 className="PoweredBy">{editableFields.poweredBy}</h2>
                    </section>
                </section>
                <section className={classNames("SavedConfirmation", { saved: saved })}>
                    <div className="SavedConfirmation__message">
                        Saved!
                    </div>
                </section>
            </section>
            <Button
                onClick={() => downloadPDF()}
                type="primary"
                loading={(isDownloading || isPreparing)}
                disabled={(isDownloading || isPreparing || isEditing)}
                >
                Download PDF
            </Button>
            {isiOS() && <p className="ios-users-message">
                <InfoCircleOutlined twoToneColor="rgb(105 53 254)" className="anticon-info-circle ant-alert-icon" style={{ color: "rgb(105 53 254)" }} /> iOS users may have to download the flyer twice to see the QR code.
            </p>}
        </section>

        <section className="HiddenElements">
            <section className="PDFOverflowHider">
                <section id="MainPage" className="MainPage hidden">
                    <section className="MainPage__wrapper">
                        <div className="Headline">{editableFields.headline}</div>
                        <div className="MusicianName">
                            {editableFields.musicianName && editableFields.musicianName.split('\n').map((item, key) => {
                                return <React.Fragment key={key}>{item}<br /></React.Fragment>
                            })}
                        </div>
                        <div className="waves" style={{ backgroundImage: `url('${waves}')` }} ></div>
                        <section className="donation-methods">
                            <div className="method-logo" style={{ backgroundImage: `url('${googlePay}')` }} />
                            <div className="method-logo" style={{ backgroundImage: `url('${applePay}')` }} />
                            <div className="method-logo" style={{ backgroundImage: `url('${creditCard}')` }} />
                        </section>
                        <img className="ExportedImageQR" ref={QRImageRef} />
                        <h2 className="plain-musician-url">Or visit us at: <span className={classNames("band-url", { block: friendlyURL.length > 40 })}>{friendlyURL}</span></h2>
                        <h2 className="PoweredBy">{editableFields.poweredBy}</h2>
                    </section>
                </section>
            </section>
        </section>
    </section>)
}
