import React, { useState } from "react";
import QueueAnim from "rc-queue-anim";
import { Tooltip, Button, Form, Input } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { CardElement, useStripe } from "@stripe/react-stripe-js";

import "./CCForm.scss";

const cardOptions = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#6935ff",
      color: "rgba(0, 0, 0, 0.65)",
      fontWeight: 500,
      fontFamily: "Segoe UI, Roboto, Open Sans, , sans-serif",
      fontSize: "15px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": { color: "#fce883" },
      "::placeholder": { color: "#bfbfbf" },
    },
    invalid: {
      iconColor: "#ff4d4f",
      color: "#ff4d4f",
    },
  },
};

const CCForm = (props) => {
  const [form] = Form.useForm();
  const {
    success: paymentSuccess,
    isLoading: isPaymentLoading,
    paymentErrors,
  } = useSelector((state) => state.home.payment);
  const stripe = useStripe();

  return (
    <div className="cc-form">
      <QueueAnim
        className="AnimationWrapper"
        type={["bottom", "top"]}
        delay={[1600, 500]}
        interval={[100, 100]}
        duration={[400, 400]}
        component={Form}
        componentProps={{
          layout: "vertical",
          size: "large",
          form: form,
          name: "signin",
          onFinish: props.onFinish,
          initialValues: {},
          scrollToFirstError: true,
        }}
      >
        {props.render && (
          <h4 key="1" className="CreditCardInfo__title">
            Complete your payment info
          </h4>
        )}
        {props.render && (
          <Form.Item
            key="2"
            name="name"
            rules={[
              {
                required: true,
                message: "Card holder name is required.",
              },
            ]}
          >
            <Input
              placeholder="Card Holder Name*"
              suffix={
                <Tooltip title="This won't be public">
                  <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                </Tooltip>
              }
            />
          </Form.Item>
        )}
        {props.render && (
          <Form.Item
            key="3"
            name="email"
            rules={[
              {
                type: "email",
                message: "Please enter a valid Email",
              },
              {
                required: true,
                message: "Email is required",
              },
            ]}
          >
            <Input
              type="email"
              placeholder="Email*"
              autoCorrect="off"
              autoCapitalize="none"
              suffix={
                <Tooltip title="This won't be public, either :)">
                  <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                </Tooltip>
              }
            />
          </Form.Item>
        )}
        {props.render && (
          <Form.Item key="4" name="creditCard" className="CreditCardWrapper">
            <CardElement options={cardOptions} />
          </Form.Item>
        )}
        {props.render && (
          <Form.Item key="5" justify="center">
            <Button
              className={"btn-custom-submit checkout-button"}
              type="primary"
              htmlType="submit"
              disabled={isPaymentLoading || !stripe}
              loading={isPaymentLoading}
            >
              {props.btnText}
            </Button>
          </Form.Item>
        )}
      </QueueAnim>
    </div>
  );
};

export default CCForm;
