// import baseAttributes from '../storeBaseAttributes';
import { getToken } from "../store";

const configReducer = (state = {}, action) => {
  switch (action.type) {
    case "TOGGLE_MAIN_MENU":
      return {
        ...state,
        isMainMenuOpen: !state.isMainMenuOpen,
      };
    case "CLOSE_MAIN_MENU":
      return {
        ...state,
        isMainMenuOpen: false,
      };

    case "SET_NOTIFICATION_SOCKET":
      return {
        ...state,
        notificationSocket: action.payload.socket,
      };
    default:
      return state;
  }
};

export default configReducer;
