import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import moment from 'moment'
import { Table, Space, Tooltip, Tag, Avatar, Dropdown, Menu, Drawer} from 'antd'
import { NavLink } from 'react-router-dom'
import {
  CheckCircleOutlined,
  SyncOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  ClockCircleOutlined,
  MinusCircleOutlined,
  RollbackOutlined,
  BankOutlined,
  UserOutlined,
  LinkOutlined,
  QrcodeOutlined,
  SolutionOutlined,
  MoreOutlined,
  FileSyncOutlined
} from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux'
import { getUsers, selectUserToEdit, setDownloadingPDF, setDownloadingQR } from '../../../../actions'
import { EmptyTableMessage } from '../../../../utils/CustomIcons'

import AdvertisingGenerator from './AdvertisingGenerator'

import './UsersList.scss'

const { Column, ColumnGroup } = Table;

export default function UsersList() {
  const { isLoading, data, pagination, errors, rehydrated, selectedUser } = useSelector(state => state.admin.users)

  const s3Base = useSelector(state => state.config.s3Base)
  const dispatch = useDispatch()
  const [expandedRows, setExpandedRows] = useState([]);

  useEffect(() => {
    if(!rehydrated){
      // Requesting the payments on first load.
      dispatch(getUsers({
        sortField: 'createdAt',
        sortOrder: 'descend',
        pagination
      }))
    }
  }, [dispatch, pagination, rehydrated])
  
  // Drawer Controls:
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };


  const onRow = (record, rowIndex) => expandedRows.includes(record.id) && { className: "expanded" }

  const onExpandedRowsChange = (expandedRows) => {
    setExpandedRows(expandedRows)
  }

  const columns = [
    {
      title: '',
      dataIndex: 'musician',
      // sorter: (a, b) => a.amount - b.amount,
      render: (musician, record) => {
        if(musician.avatar){
          return (
            <NavLink
              className=""
              activeClassName="active"
              to={`/u/${record.musician.slug}`}
              href={`/u/${record.musician.slug}`}
            >
              <section className="status-value">
                <div className="custom-row-avatar" style={{ backgroundImage: `url(${s3Base + musician.avatar})`}}></div>
              </section>
            </NavLink>)
        }
        else if(!musician.avatar){
          return (
            <NavLink
              className=""
              activeClassName="active"
              to={`/u/${record.musician.slug}`}
              href={`/u/${record.musician.slug}`}
            >
              <section className="status-value"><Avatar icon={<UserOutlined />} /></section>
            </NavLink>)
        }
      },
      width: '60px'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      // sorter: (a, b) => a.amount - b.amount,
      sorter: true,
      render: (email) => (<section className="displayName-value">{email ? email : 'Unknown'}</section>),
    },

    {
      title: "Band Name",
      dataIndex: 'musicianName',
      // sorter: (a, b) => {
      //     var textA = a.firstName ? a.firstName.toLowerCase() : '';
      //     var textB = b.firstName ? b.firstName.toLowerCase() : '';
      //     return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      // },
      sorter: true,
      render: (musicianName, record) => (<section className="date-value">

        <NavLink
          className=""
          activeClassName="active"
          to={`/u/${record.musician.slug}`}
          href={`/u/${record.musician.slug}`}
        >
          <Tooltip title="Go to Profile">
            {musicianName} <LinkOutlined />
          </Tooltip>

        </NavLink>
      </section>),

    },
    {
      title: "Name",
      dataIndex: 'firstName',
      // sorter: (a, b) => {
      //     var textA = a.firstName ? a.firstName.toLowerCase() : '';
      //     var textB = b.firstName ? b.firstName.toLowerCase() : '';
      //     return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      // },
      sorter: true,
      render: (firstName, record) => {
        return firstName ?
          (<section className="firstName-value">{record.firstName + " " + record.lastName}</section>) :
          (<section className="firstName-value no-message">- - -</  section>)
      },
    },
    {
      title: 'Member Since',
      dataIndex: 'createdAt',
      // sorter: (a, b) => a.amount - b.amount,
      sorter: true,
      defaultSortOrder: 'descend',
      render: (createdAt, record) => (<section className="date-value">{moment(createdAt).format('L hh:mm A')}</section>),
    },
    {
      title: 'Bank Account',
      dataIndex: 'stripe',
      // sorter: (a, b) => a.amount - b.amount,
      sorter: true,
      render: (stripe, record) => {
        if(stripe.connected){
          return (<section className="status-value"><Tag icon={<BankOutlined />} color="success">Connected</Tag></section>)
        }
        else if(!stripe.connected){
          return (<section className="status-value"><Tag icon={<BankOutlined />} color="error">No Connected</Tag></section>)
        }
      },
    },
    {
      // title: 'More',
      dataIndex: 'more',
      render: (m, record) => {
        const menu = (<Menu>
          {(record.musician.slug) && 
            <Menu.Item className="extra-user-menu" icon={<QrcodeOutlined style={{ fontSize: '20px' }} />} onClick={() => {
              dispatch(setDownloadingQR(true))            
            }}>
              Download QR
            </Menu.Item>}
          {(record.musician.slug) && 
            <Menu.Item className="extra-user-menu" icon={<SolutionOutlined style={{ fontSize: '20px' }} />} onClick={() => {
              dispatch(setDownloadingPDF(true))
            }}>
              Download Flyer
            </Menu.Item>}
          {(record.musician.slug) && 
            <Menu.Item className="extra-user-menu" icon={<FileSyncOutlined style={{ fontSize: '20px' }} />} onClick={() => {
              showDrawer()
            }}>
              Edit Flyer
            </Menu.Item>}
        </Menu>);
        return (<section>
          <Dropdown overlay={menu} trigger={['click']} onVisibleChange={(visible) => dispatch(selectUserToEdit(record))} placement="bottomRight" arrow >
            <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
              <MoreOutlined style={{ fontSize: '25px', transform: 'translateX(-33%)' }} />
            </div>
          </Dropdown>
        </section>)
      },
      width: '10px'
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    dispatch(getUsers({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      filters,
    }))
  }

  return (
    <section className="UsersList">
      <Table
        locale={{ emptyText: EmptyTableMessage }}
        onRow={onRow}
        columns={columns}
        rowKey={record => record.id}
        dataSource={data}
        pagination={pagination}
        loading={isLoading}
        onChange={handleTableChange}
        expandable={{
          indentSize: 30,
          expandRowByClick: true,
          expandedRowRender: record => {
            return (<section className="ExpandedDetails">
              <p style={{ margin: 0 }}>{record.message}</p>
            </section>)
          },
          rowExpandable: record => {
            // record.message !== 'Not Expandable'
            return false
          },
          onExpandedRowsChange: onExpandedRowsChange,
        }}
      />

      <Drawer forceRender={true} title={`Editing "${selectedUser ? selectedUser.musicianName : ''}" Flyer`} placement="right" onClose={onClose} visible={visible} width={768}>
        <section className="UsersDrawer">
          <AdvertisingGenerator />
        </section>
      </Drawer>
    </section>
  )
}
