import React from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import './Footer.scss';

export default function Footer () {
  return (
    <footer className={classNames('Main__Footer')}>
      <div className="Main__Footer__Wrapper">
        <section className="copyright">
          <h4>Museit© 2020. All rights reserved.</h4>
        </section>
        <nav className="navigation-bottom">
          <ul>
            <li><a href="https://www.museit.co/faq" target="_blank">FAQ</a></li>
            <li><a href="https://www.museit.co/legal/privacy" target="_blank">Privacy Policy</a></li>
            <li><a href="https://www.museit.co/legal/terms-and-conditions" target="_blank">Terms and Conditions</a></li>
          </ul>
        </nav>
      </div>
    </footer>
	)
}
