import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { Table, Space, Tooltip, Tag } from 'antd';
import {
  CheckCircleOutlined,
  SyncOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  ClockCircleOutlined,
  MinusCircleOutlined,
  RollbackOutlined,
  SendOutlined,
} from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { getPayments } from '../../../actions';
import { EmptyTableMessage } from '../../../utils/CustomIcons';
import './PaymentsList.scss';

const { Column, ColumnGroup } = Table;

export default function PaymentsList() {
  const { isLoading, data, pagination, errors, rehydrated } = useSelector(
    (state) => state.user.payments
  );
  const dispatch = useDispatch();
  const [expandedRows, setExpandedRows] = useState([]);

  useEffect(() => {
    if (!rehydrated) {
      // Requesting the payments on first load.
      dispatch(
        getPayments({
          sortField: 'createdAt',
          sortOrder: 'descend',
          pagination,
        })
      );
    }
  }, [dispatch, pagination, rehydrated]);

  const onRow = (record, rowIndex) =>
    expandedRows.includes(record.id) && { className: 'expanded' };

  const onExpandedRowsChange = (expandedRows) => {
    setExpandedRows(expandedRows);
  };

  const columns = [
    {
      title: 'Backer',
      dataIndex: 'displayName',
      // sorter: (a, b) => a.amount - b.amount,
      sorter: false,
      render: (displayName) => (
        <section className='displayName-value'>
          {displayName ? displayName : 'Unknown'}
        </section>
      ),
    },
    {
      title: 'Message',
      dataIndex: 'supportMessage',
      // sorter: (a, b) => {
      //     var textA = a.supportMessage ? a.supportMessage.toLowerCase() : '';
      //     var textB = b.supportMessage ? b.supportMessage.toLowerCase() : '';
      //     return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      // },
      sorter: false,
      render: (supportMessage) => {
        return supportMessage ? (
          <section className='supportMessage-value'>{supportMessage}</section>
        ) : (
          <section className='supportMessage-value no-message'>- - -</section>
        );
      },
      width: '400px',
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      // sorter: (a, b) => a.amount - b.amount,
      sorter: true,
      defaultSortOrder: 'descend',
      render: (createdAt, record) => (
        <section className='date-value'>
          {moment(createdAt).format('L hh:mm A')}
        </section>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      // sorter: (a, b) => a.amount - b.amount,
      sorter: false,
      render: (amount, record) => (
        <section className='amount-value'>
          ${amount / 100} {record.currency}
        </section>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '300px',
      // filters: [
      //   { text: 'Male', value: 'male' },
      //   { text: 'Female', value: 'female' },
      // ],
      // sorter: (a, b) => {
      //     var textA = a.event ? a.event.toLowerCase() : '';
      //     var textB = b.event ? b.event.toLowerCase() : '';
      //     return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      // },
      render: (status, record) => {
        if (status === 'refunded') {
          return (
            <section className='status-value'>
              <Tag icon={<RollbackOutlined />} color='processing'>
                refunded
              </Tag>
            </section>
          );
        }
        if (
          status === 'succeeded' &&
          record.amount_refunded > 0 &&
          record.refunded === true
        ) {
          return (
            <section className='status-value'>
              <Tag icon={<RollbackOutlined />} color='processing'>
                refunded
              </Tag>
            </section>
          );
        }
        if (
          status === 'succeeded' &&
          record.amount_refunded > 0 &&
          record.refunded === false
        ) {
          return (
            <section className='status-value'>
              <Tag icon={<RollbackOutlined />} color='processing'>
                partially refunded
              </Tag>
            </section>
          );
        } else if (status === 'on-manual-hold') {
          return (
            <section className='status-value'>
              <Tag icon={<ExclamationCircleOutlined />} color='warning'>
                on-manual-hold
              </Tag>
            </section>
          );
        } else if (status === 'succeeded-on-hold') {
          return (
            <section className='status-value'>
              <Tag icon={<ExclamationCircleOutlined />} color='warning'>
                On Hold
              </Tag>
            </section>
          );
        } else if (status === 'succeeded' && !record.payout) {
          return (
            <section className='status-value'>
              <Tag icon={<CheckCircleOutlined />} color='success'>
                Succeeded
              </Tag>
            </section>
          );
        } else if (status === 'succeeded' && record.payout) {
          return (
            <section className='status-value'>
              <Tag icon={<CheckCircleOutlined />} color='success'>
                Succeeded
              </Tag>
              <Tag icon={<SendOutlined />} color='success'>
                Transferred
              </Tag>
            </section>
          );
        }
      },
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    dispatch(
      getPayments({
        sortField: sorter.field,
        sortOrder: sorter.order,
        pagination,
        filters,
      })
    );
  };

  return (
    <section className='PaymentsList'>
      <Table
        locale={{ emptyText: EmptyTableMessage }}
        onRow={onRow}
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={data}
        pagination={pagination}
        loading={isLoading}
        onChange={handleTableChange}
        expandable={{
          indentSize: 30,
          expandRowByClick: true,
          expandedRowRender: (record) => {
            return (
              <section className='ExpandedDetails'>
                <p style={{ margin: 0 }}>{record.message}</p>
              </section>
            );
          },
          rowExpandable: (record) => {
            // record.message !== 'Not Expandable'
            return false;
          },
          onExpandedRowsChange: onExpandedRowsChange,
        }}
      />
    </section>
  );
}
