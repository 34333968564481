import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Form, Button, Input, Checkbox, Divider, notification } from 'antd'
import { NavLink } from 'react-router-dom'
import { signIn } from '../../actions'
import './SignInForm.scss'


export default function SignInForm () {
  const [form] = Form.useForm()
  const { isLoading, errors: authErrors } = useSelector(state => state.auth)
  const dispatch = useDispatch()

  const onFinish = values => {
    dispatch(signIn(values));
  };

  useEffect(() => {
    if(authErrors.hasError){
      if(['EMAIL_IN_USE', 'EMAIL_NOT_VALID', 'EMAIL_REQUIRED'].includes(authErrors.code)){
        form.setFields([
           {
             name: 'identity',
             errors: [authErrors.message],
           },
        ]);
      }
      else {
        notification.error({
          message: 'Error',
          description: authErrors.message,
          duration: 0,
          key: 'sign-in-notification',
          onClick: () => {
            notification.close('sign-in-notification')
          }
        })
      }

    }
  }, [authErrors, form])


  return (
    <Form
      layout="vertical"
      size="large"
      form={form}
      name="signin"
      onFinish={onFinish}
      initialValues={{}}
      scrollToFirstError
    >
      <Form.Item
        name="identity"
        rules={[
          {
            type: 'email',
            message: 'Please enter a valid Email',
          },
          {
            required: true,
            message: 'Email is required',
          },
        ]}
      >
        <Input type="email" placeholder="Email*" autoCorrect="off" autoCapitalize="none" />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: 'Password is required!',
          }
        ]}
      >
        <Input.Password placeholder="Password*" autoComplete="on" />
      </Form.Item>
      <Form.Item>
        <Form.Item name="remember" valuePropName="checked" noStyle>
          <Checkbox>Remember me</Checkbox>
        </Form.Item>

        <NavLink className="forgot-your-password" to="/recover">Forgot your password?</NavLink>
      </Form.Item>
      <Form.Item justify="center" noStyle>
        <Button className={'btn-custom-submit'} type="primary" htmlType="submit" disabled={isLoading} loading={isLoading}>
          Sign In
        </Button>
      </Form.Item>
      <Divider plain>or</Divider>
      <Form.Item className="signup-link">
        <NavLink to="/signup">Create a Museit account</NavLink>
      </Form.Item>
    </Form>)
}
