import React, { useEffect } from 'react';
import { Tabs, Tooltip } from 'antd';
import { Swiper, SwiperSlide } from 'swiper/react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import EarningsByMonth from './EarningsByMonth';
import PayoutsList from './PayoutsList';
import PaymentsList from './PaymentsList';
import MerchSalesList from './MerchSalesList';
import { getEarnings, getInitialInfo } from '../../../actions';

import './MyMoney.scss';
// Import Swiper styles
import 'swiper/swiper.scss';

const { TabPane } = Tabs;

export default function MyMoney() {
  const { isLoading, data, errors } = useSelector(
    (state) => state.user.earnings
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEarnings());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getInitialInfo());
  }, []);

  const note = (
    <React.Fragment>
      Fees have already been deducted in payment balances shown in above boxes,
      please see our{' '}
      <a href='https://www.museit.co/faq' target='_blank'>
        FAQ
      </a>{' '}
      to learn more about fees
    </React.Fragment>
  );
  const note1 =
    'Earnings (after fees) that have not been paid out to you, these will be processed in the upcoming payout cycles.';
  const note2 =
    'Earnings (after fees) that have been deposited into your account.';
  const note3 =
    'Your all-time total earnings after fees (Museit fees: 3% and Stripe Transaction fee: $.30 + 2.9%/transaction)';

  return (
    <section className='MyMoney'>
      <Swiper
        className='MyMoney__numbers'
        spaceBetween={10}
        slidesPerView='auto'
      >
        <SwiperSlide className='MyMoney__numbers--card EarningsCard EarningsCard__current'>
          <h5 className='EarningsCard__title'>
            CURRENT EARNINGS{' '}
            <Tooltip title={note1}>
              <InfoCircleOutlined />
            </Tooltip>
          </h5>
          <div className='EarningsCard__amount'>
            $
            {(data.currentEarnings / 100)
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
          </div>
        </SwiperSlide>
        <SwiperSlide className='MyMoney__numbers--card EarningsCard EarningsCard__paid'>
          <h5 className='EarningsCard__title'>
            PAID EARNINGS{' '}
            <Tooltip title={note2}>
              <InfoCircleOutlined />
            </Tooltip>
          </h5>
          <div className='EarningsCard__amount'>
            $
            {(data.paidEarnings / 100)
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
          </div>
        </SwiperSlide>
        <SwiperSlide className='MyMoney__numbers--card EarningsCard EarningsCard__total'>
          <h5 className='EarningsCard__title'>
            TOTAL EARNINGS{' '}
            <Tooltip title={note3}>
              <InfoCircleOutlined />
            </Tooltip>
          </h5>
          <div className='EarningsCard__amount'>
            $
            {(data.totalEarnings / 100)
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
          </div>
        </SwiperSlide>
      </Swiper>
      <section>
        <Tabs className='MyMoney__tabs' defaultActiveKey='4'>
          <TabPane tab='Payments' key='4'>
            <PaymentsList />
            <p className='payments-note last'>
              <InfoCircleOutlined /> {note}
            </p>
          </TabPane>
          <TabPane tab='Merch Sales' key='3'>
            <MerchSalesList />
            <p className='payments-note last'>
              <InfoCircleOutlined /> {note}
            </p>
          </TabPane>
          <TabPane tab='Payout history' key='2'>
            <PayoutsList />
            <p className='payments-note last'>
              <InfoCircleOutlined /> {note}
            </p>
          </TabPane>
          <TabPane tab='Earnings by month' key='1'>
            <EarningsByMonth />
            <p className='payments-note last'>
              <InfoCircleOutlined /> {note}
            </p>
          </TabPane>
        </Tabs>
      </section>
    </section>
  );
}
