import React from "react";
import MarketingGenerator from "./MarketingGenerator";
import { Col, Row, Button } from "antd";
import cards from "./assets/cards.png";
import posters from "./assets/posters.png";
export default function Marketing() {
  return (
    <section className="Marketing">
      <MarketingGenerator />
      <section className="Shop">
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <h1 className="Shop__title">Shop</h1>
            <p className="Shop__details">
              Need us to do the printing? Shop premium QR codes.
            </p>
          </Col>
        </Row>
        <Row>
          <Col
            className="Shop__item--col"
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={12}
            xxl={12}
          >
            <section className="Shop__item">
              <h4 className="Shop__item--title">Creator Cards</h4>
              <div
                className="Shop__item--image"
                style={{ backgroundImage: `url('${cards}')` }}
              ></div>
              <div className="button-wrapper">
                <Button
                  href="https://museit-co.myshopify.com/products/creator-cards"
                  target="_blank"
                  type="primary"
                >
                  Buy
                </Button>
              </div>
            </section>
          </Col>
          <Col
            className="Shop__item--col"
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={12}
            xxl={12}
          >
            <section className="Shop__item">
              <h4 className="Shop__item--title">Posters</h4>
              <div
                className="Shop__item--image"
                style={{ backgroundImage: `url('${posters}')` }}
              ></div>
              <div className="button-wrapper">
                <Button
                  href="https://museit-co.myshopify.com/products/poster"
                  target="_blank"
                  type="primary"
                >
                  Buy
                </Button>
              </div>
            </section>
          </Col>
        </Row>
      </section>
    </section>
  );
}
