/* eslint-disable import/prefer-default-export */

export const toggleMainMenu = () => ({
  type: 'TOGGLE_MAIN_MENU'
});

export const closeMainMenu = () => ({
  type: 'CLOSE_MAIN_MENU'
});


export const setNotificationSocket = (socket) => ({
  type: 'SET_NOTIFICATION_SOCKET',
  payload: {
    socket,
  }
});
