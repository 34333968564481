import baseAttributes from "../storeBaseAttributes";

const shopifyReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_STORE_NAME_PENDING":
      return {
        ...state,
        storeName: {
          // TODO: Should the following be nested?
          //  In other Reducers sometimes it is, sometimes it isn't.
          ...state.storeName,
          ...baseAttributes,
          isLoading: true,
        },
      };
    case "GET_STORE_NAME_ERROR":
      return {
        ...state,
        storeName: {
          ...state.storeName,
          ...baseAttributes,
          errors: {
            ...state.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case "GET_STORE_NAME_ABORTED":
      return {
        ...state,
        storeName: {
          ...state.storeName,
          ...baseAttributes,
        },
      };
    case "GET_STORE_NAME_SUCCESS":
      return {
        ...state,
        storeName: {
          ...state.storeName,
          ...baseAttributes,
          data: action.payload.storeName,
        },
      };
    default:
      return state;
  }
};

export default shopifyReducer;
