import baseAttributes from '../storeBaseAttributes';
import jwtDecode from 'jwt-decode';
import { isString } from 'lodash';

const authReducer = (state = {}, action) => {
  switch (action.type) {
    case 'SIGN_IN_PENDING':
      return {
        ...state,
        ...baseAttributes,
        isLoading: true,
      };
    case 'SIGN_IN_ERROR':
      return {
        ...state,
        ...baseAttributes,
        errors: {
          ...state.errors,
          hasError: action.payload.error,
          code: action.payload.code,
          message: action.payload.message,
          all: action.payload.errors,
        },
      };
    case 'SIGN_IN_ABORTED':
      return {
        ...state,
        ...baseAttributes,
      };
    case 'SIGN_IN_SUCCESS':
      localStorage.setItem('token', action.payload.token);
      return {
        ...state,
        ...baseAttributes,
        token: action.payload.token,
        user: action.payload.user,
        isAuthenticated: true,
      };

    case 'SIGN_UP_PENDING':
      return {
        ...state,
        ...baseAttributes,
        isLoading: true,
      };
    case 'SIGN_UP_ERROR':
      return {
        ...state,
        ...baseAttributes,
        errors: {
          ...state.errors,
          hasError: action.payload.error,
          code: action.payload.code,
          message: action.payload.message,
          all: action.payload.errors,
        },
      };
    case 'SIGN_UP_ABORTED':
      return {
        ...state,
        ...baseAttributes,
      };
    case 'SIGN_UP_SUCCESS':
      localStorage.setItem('token', action.payload.token);
      return {
        ...state,
        ...baseAttributes,
        token: action.payload.token,
        user: action.payload.user,
        isAuthenticated: true,
      };
    case 'SUBSCRIBE_PENDING':
      return {
        ...state,
        ...baseAttributes,
        isLoading: true,
        subscribed: false,
      };
    case 'SUBSCRIBE_ERROR':
      return {
        ...state,
        ...baseAttributes,
        subscribed: false,
        errors: {
          ...state.errors,
          hasError: action.payload.error,
          code: action.payload.code,
          message: action.payload.message,
          all: action.payload.errors,
        },
      };
    case 'SUBSCRIBE_ABORTED':
      return {
        ...state,
        ...baseAttributes,
        subscribed: false,
      };
    case 'SUBSCRIBE_SUCCESS':
      return {
        ...state,
        ...baseAttributes,
        subscribed: true,
      };
    case 'RESET_SUBSCRIBE':
      return {
        ...state,
        ...baseAttributes,
        subscribed: false,
      };

    case 'RECOVER_PENDING':
      return {
        ...state,
        ...baseAttributes,
        isLoading: true,
        recoverEmailSent: false,
      };
    case 'RECOVER_ERROR':
      return {
        ...state,
        ...baseAttributes,
        recoverEmailSent: false,
        errors: {
          ...state.errors,
          hasError: action.payload.error,
          code: action.payload.code,
          message: action.payload.message,
          all: action.payload.errors,
        },
      };
    case 'RECOVER_ABORTED':
      return {
        ...state,
        ...baseAttributes,
        isLoading: false,
        recoverEmailSent: false,
      };
    case 'RECOVER_SUCCESS':
      return {
        ...state,
        ...baseAttributes,
        recoverEmailSent: true,
      };

    case 'RECOVER_VALIDATE_PENDING':
      return {
        ...state,
        ...baseAttributes,
        isLoading: true,
      };
    case 'RECOVER_VALIDATE_ERROR':
      return {
        ...state,
        ...baseAttributes,
        errors: {
          ...state.errors,
          hasError: action.payload.error,
          code: action.payload.code,
          message: action.payload.message,
          all: action.payload.errors,
        },
      };
    case 'RECOVER_VALIDATE_ABORTED':
      return {
        ...state,
        ...baseAttributes,
      };
    case 'RECOVER_VALIDATE_SUCCESS':
      return {
        ...state,
        ...baseAttributes,
      };
    case 'RECOVER_CHANGE_PASSWORD_PENDING':
      return {
        ...state,
        ...baseAttributes,
        isLoading: true,
      };
    case 'RECOVER_CHANGE_PASSWORD_ERROR':
      return {
        ...state,
        ...baseAttributes,
        errors: {
          ...state.errors,
          hasError: action.payload.error,
          code: action.payload.code,
          message: action.payload.message,
          all: action.payload.errors,
        },
      };
    case 'RECOVER_CHANGE_PASSWORD_ABORTED':
      return {
        ...state,
        ...baseAttributes,
      };
    case 'RECOVER_CHANGE_PASSWORD_SUCCESS':
      localStorage.setItem('token', action.payload.token);
      return {
        ...state,
        ...baseAttributes,
        token: action.payload.token,
        user: action.payload.user,
        isAuthenticated: true,
      };
    case 'CLEAR_RECOVER_EMAIL_FLAG':
      return {
        ...state,
        ...baseAttributes,
        recoverEmailSent: false,
      };
    case 'GET_AVATAR_UPLOAD_URL_PENDING':
      return {
        ...state,
        ...baseAttributes,
        isRequestingAvatarURL: true,
        tmpAuth: {
          user: null,
          token: null,
        },
      };
    case 'GET_AVATAR_UPLOAD_URL_ERROR':
      return {
        ...state,
        ...baseAttributes,
        isRequestingAvatarURL: false,
        tmpAuth: {
          user: null,
          token: null,
        },
        errors: {
          ...state.errors,
          hasError: action.payload.error,
          code: action.payload.code,
          message: action.payload.message,
          all: action.payload.errors,
        },
      };
    case 'GET_AVATAR_UPLOAD_URL_ABORTED':
      return {
        ...state,
        ...baseAttributes,
        isRequestingAvatarURL: false,
        tmpAuth: {
          user: null,
          token: null,
        },
      };
    case 'GET_AVATAR_UPLOAD_URL_SUCCESS':
      return {
        ...state,
        ...baseAttributes,
        isRequestingAvatarURL: false,
        signedAvatarUploadURL: action.payload.data.signedAvatarUploadURL,
        tmpAuth: {
          user: action.payload.data.user,
          token: action.payload.data.token,
        },
      };
    case 'UPLOAD_AVATAR_PENDING':
      return {
        ...state,
        ...baseAttributes,
        isUploadingAvatar: true,
      };
    case 'UPLOAD_AVATAR_ERROR':
      return {
        ...state,
        ...baseAttributes,
        isUploadingAvatar: false,
        errors: {
          ...state.errors,
          hasError: action.payload.error,
          code: action.payload.code,
          message: action.payload.message,
          all: action.payload.errors,
        },
      };
    case 'UPLOAD_AVATAR_ABORTED':
      return {
        ...state,
        ...baseAttributes,
        isUploadingAvatar: false,
      };
    case 'UPLOAD_AVATAR_SUCCESS':
      localStorage.setItem('token', state.tmpAuth.token);
      return {
        ...state,
        ...baseAttributes,
        isUploadingAvatar: false,
        signedAvatarUploadURL: '',
        // Here we assign the temprorary object to update the user and token.
        ...state.tmpAuth,
        // Here we clean the temprorary object after uploading the image.
        tmpAuth: {
          user: null,
          token: null,
        },
      };
    case 'CLEAR_UPLOADED_AVATAR_KEY':
      return {
        ...state,
        ...baseAttributes,
        isRequestingAvatarURL: false,
        isUploadingAvatar: false,
        signedAvatarUploadURL: '',
        tmpAuth: {
          user: null,
          token: null,
        },
      };

    case 'GET_COVER_UPLOAD_URL_PENDING':
      return {
        ...state,
        ...baseAttributes,
        isRequestingCoverURL: true,
        tmpAuth: {
          user: null,
          token: null,
        },
      };
    case 'GET_COVER_UPLOAD_URL_ERROR':
      return {
        ...state,
        ...baseAttributes,
        isRequestingCoverURL: false,
        tmpAuth: {
          user: null,
          token: null,
        },
        errors: {
          ...state.errors,
          hasError: action.payload.error,
          code: action.payload.code,
          message: action.payload.message,
          all: action.payload.errors,
        },
      };
    case 'GET_COVER_UPLOAD_URL_ABORTED':
      return {
        ...state,
        ...baseAttributes,
        isRequestingCoverURL: false,
        tmpAuth: {
          user: null,
          token: null,
        },
      };
    case 'GET_COVER_UPLOAD_URL_SUCCESS':
      return {
        ...state,
        ...baseAttributes,
        isRequestingCoverURL: false,
        signedCoverUploadURL: action.payload.data.signedCoverUploadURL,
        tmpAuth: {
          user: action.payload.data.user,
          token: action.payload.data.token,
        },
      };
    case 'UPLOAD_COVER_PENDING':
      return {
        ...state,
        ...baseAttributes,
        isUploadingCover: true,
      };
    case 'UPLOAD_COVER_ERROR':
      return {
        ...state,
        ...baseAttributes,
        isUploadingCover: false,
        errors: {
          ...state.errors,
          hasError: action.payload.error,
          code: action.payload.code,
          message: action.payload.message,
          all: action.payload.errors,
        },
      };
    case 'UPLOAD_COVER_ABORTED':
      return {
        ...state,
        ...baseAttributes,
        isUploadingCover: false,
      };
    case 'UPLOAD_COVER_SUCCESS':
      localStorage.setItem('token', state.tmpAuth.token);
      return {
        ...state,
        ...baseAttributes,
        isUploadingCover: false,
        signedCoverUploadURL: '',
        // Here we assign the temprorary object to update the user and token.
        ...state.tmpAuth,
        // Here we clean the temprorary object after uploading the image.
        tmpAuth: {
          user: null,
          token: null,
        },
      };
    case 'CLEAR_UPLOADED_COVER_KEY':
      return {
        ...state,
        ...baseAttributes,
        isRequestingCoverURL: false,
        isUploadingCover: false,
        signedCoverUploadURL: '',
        tmpAuth: {
          user: null,
          token: null,
        },
      };

    case 'UPDATE_PROFILE_INFO_PENDING':
      return {
        ...state,
        ...baseAttributes,
        isLoading: true,
        updated: false,
      };
    case 'UPDATE_PROFILE_INFO_ERROR':
      return {
        ...state,
        ...baseAttributes,
        updated: false,
        errors: {
          ...state.errors,
          hasError: action.payload.error,
          code: action.payload.code,
          message: action.payload.message,
          all: action.payload.errors,
        },
      };
    case 'UPDATE_PROFILE_INFO_ABORTED':
      return {
        ...state,
        ...baseAttributes,
        updated: false,
      };
    case 'UPDATE_PROFILE_INFO_SUCCESS':
      localStorage.setItem('token', action.payload.data.token);
      return {
        ...state,
        ...baseAttributes,
        token: action.payload.data.token,
        user: action.payload.data.user,
        updated: true,
      };
    case 'UPDATE_PROFILE_INFO_RESET':
      return {
        ...state,
        ...baseAttributes,
        updated: false,
      };
    case 'GET_STRIPE_OAUTH_LINK_PENDING':
      return {
        ...state,
        stripe: {
          ...state.stripe,
          ...baseAttributes,
          isLoading: true,
        },
      };
    case 'GET_STRIPE_OAUTH_LINK_ERROR':
      return {
        ...state,
        stripe: {
          ...state.stripe,
          ...baseAttributes,
          errors: {
            ...state.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'GET_STRIPE_OAUTH_LINK_ABORTED':
      return {
        ...state,
        stripe: {
          ...state.stripe,
          ...baseAttributes,
        },
      };
    case 'GET_STRIPE_OAUTH_LINK_SUCCESS':
      return {
        ...state,
        stripe: {
          ...state.stripe,
          ...baseAttributes,
          connectOAuthLink: action.payload.url,
        },
      };
    case 'AUTHORIZE_STRIPE_OAUTH_PENDING':
      return {
        ...state,
        stripe: {
          ...state.stripe,
          ...baseAttributes,
          isAuthorizing: true,
        },
      };
    case 'AUTHORIZE_STRIPE_OAUTH_ERROR':
      return {
        ...state,
        stripe: {
          ...state.stripe,
          ...baseAttributes,
          isAuthorizing: false,
          errors: {
            ...state.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'AUTHORIZE_STRIPE_OAUTH_ABORTED':
      return {
        ...state,
        stripe: {
          ...state.stripe,
          ...baseAttributes,
          isAuthorizing: false,
        },
      };
    case 'AUTHORIZE_STRIPE_OAUTH_SUCCESS':
      localStorage.setItem('token', action.payload.data.token);
      return {
        ...state,
        token: action.payload.data.token,
        user: action.payload.data.user,
        stripe: {
          ...state.stripe,
          ...baseAttributes,
          isAuthorizing: false,
          connectOAuthLink: action.payload.url,
        },
      };
    case 'LOGOUT':
      localStorage.removeItem('token');
      localStorage.removeItem('firstLogin');
      return {
        ...state,
        user: {},
        token: '',
        isAuthenticated: false,
      };
    case 'GLOBAL_INIT': {
      const token = localStorage.getItem('token');
      const firstLogin = localStorage.getItem('firstLogin');

      const decoded = isString(token) ? jwtDecode(token) : null;
      if (decoded) {
        const currentDate = new Date();
        const tokenExpDate = new Date(0);
        tokenExpDate.setUTCSeconds(decoded.exp);
        if (tokenExpDate > currentDate) {
          return {
            ...state,
            user: decoded.user,
            token,
            isAuthenticated: true,
            rehydrated: true,
            firstLogin,
          };
        }
        // Token Expired
        localStorage.removeItem('token');
        localStorage.removeItem('firstLogin');
        return { ...state, rehydrated: true };
      }
      return { ...state, rehydrated: true };
    }
    case 'UPDATE_PRODUCT_PENDING':
      return {
        ...state,
        ...baseAttributes,
        isUpdatingProduct: true,
      };
    case 'UPDATE_PRODUCT_ERROR':
      return {
        ...state,
        ...baseAttributes,
        isUpdatingProduct: false,
        errors: {
          ...state.errors,
          hasError: action.payload.error,
          code: action.payload.code,
          message: action.payload.message,
          all: action.payload.errors,
        },
      };
    case 'UPDATE_PRODUCT_ABORTED':
      return {
        ...state,
        ...baseAttributes,
        isUpdatingProduct: false,
      };
    case 'UPDATE_PRODUCT_SUCCESS':
      return {
        ...state,
        ...baseAttributes,
        isUpdatingProduct: false,
      };
    default:
      return state;
  }
};

export default authReducer;
