import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import moment from 'moment'
import CsvDownload from 'react-json-to-csv'
import { Table, Space, Tooltip } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { getEarningsByMonth, getEarningsByMonthCSV } from '../../../actions'
import { DownloadOutlined } from '@ant-design/icons'
import { EmptyTableMessage } from '../../../utils/CustomIcons'
import './EarningsByMonth.scss'

const { Column, ColumnGroup } = Table;


export default function EarningsByMonth() {
  const { isLoading, data, pagination, errors, rehydrated } = useSelector(state => state.user.earningsByMonth)
  const dispatch = useDispatch()
  const [expandedRows, setExpandedRows] = useState([]);

  useEffect(() => {
    if(!rehydrated){
      // Requesting the payments on first load.
      dispatch(getEarningsByMonth({
        sortField: 'createdAt',
        sortOrder: 'descend',
        pagination
      }))
    }
  }, [dispatch, pagination, rehydrated])

  const onRow = (record, rowIndex) => expandedRows.includes(record.id) && { className: "expanded" }

  const onExpandedRowsChange = (expandedRows) => {
    setExpandedRows(expandedRows)
  }

  const columns = [
    {
      title: "Month",
      dataIndex: 'month',
      sorter: (a, b) => moment(`${a.month} ${a.year}`, 'M YYYY').format('YYYYMM') - moment(`${b.month} ${b.year}`, 'M YYYY').format('YYYYMM'),
      defaultSortOrder: 'descend',
      // sorter: true,
      render: (month, record) => (<section className="month-value">{moment(`${record.month} ${record.year}`, 'MM YYYY').format('MMMM YYYY')}</section>),
    },
    {
      title: 'Earnings',
      dataIndex: 'amountAfterStripeAndAppFees',
      // sorter: true,
      render: (amountAfterStripeAndAppFees, record) => (<section className="earnings-value">${(amountAfterStripeAndAppFees/100).toFixed(2)} <span style={{textTransform: 'uppercase'}}>{record.currency}</span></section>),
    },
    {
      title: 'Donations',
      dataIndex: 'paymentsCount',
      render: paymentsCount => (<section className="id-value">{paymentsCount}</section>),
    },
    {
      title: '',
      dataIndex: 'payments',
      render: (payments, record) => (<section className="download-value">
        <CsvDownload data={payments} filename={`${record.month}-${record.year}-earnings.csv`}><DownloadOutlined /></CsvDownload>
      </section>),
    },
  ];

  const downladCSV = (payments) => {
    console.log(payments);

  };

  const handleTableChange = (pagination, filters, sorter) => {
    dispatch(getEarningsByMonth({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      filters,
    }))
  }

  return (
    <section className="EarningsByMonth">
      <Table
        locale={{ emptyText: EmptyTableMessage }}
        onRow={onRow}
        columns={columns}
        rowKey={record => record.id}
        dataSource={data}
        pagination={pagination}
        loading={isLoading}
        onChange={handleTableChange}
        expandable={{
          indentSize: 30,
          expandRowByClick: true,
          expandedRowRender: record => {
            return (<section className="ExpandedDetails">
              <p style={{ margin: 0 }}>{record.message}</p>
            </section>)
          },
          rowExpandable: record => {
            // record.message !== 'Not Expandable'
            return false
          },
          onExpandedRowsChange: onExpandedRowsChange,
        }}
      />
    </section>
  )
}
