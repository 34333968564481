import { combineEpics } from 'redux-observable';
import {
  getInitialInfo,
  getPayouts,
  getPayments,
  getMerchSales,
  getEarningsByMonth,
  getEarnings,
  getMerchCache,
} from './userEpic';
import {
  signIn,
  signUp,
  subscribe,
  recover,
  recoverValidate,
  recoverChangePassword,
  updateProfileInfo,
  getAvatarUploadURL,
  uploadAvatar,
  getCoverUploadURL,
  uploadCover,
  getStripeOAuthLink,
  authorizeStripeOAuth,
  updateProduct,
} from './authEpic';
import { getMusician, sendPayment } from './homeEpic';
import {
  getUsers,
  getAdminPayouts,
  getAdminPayments,
  getAdminPayoutPayments,
} from './adminEpic';
import { getStoreName } from './shopifyEpic';

const rootEpic = combineEpics(
  signIn,
  signUp,
  subscribe,
  recover,
  recoverValidate,
  recoverChangePassword,
  getInitialInfo,
  updateProfileInfo,
  getMusician,
  getAvatarUploadURL,
  uploadAvatar,
  getCoverUploadURL,
  uploadCover,
  getPayouts,
  getPayments,
  getMerchSales,
  getEarningsByMonth,
  sendPayment,
  getStripeOAuthLink,
  authorizeStripeOAuth,
  getEarnings,
  getUsers,
  getAdminPayouts,
  getAdminPayments,
  getAdminPayoutPayments,
  getStoreName,
  updateProduct,
  getMerchCache
);

export default rootEpic;
