import api from '../utils/api';
import { getErrorResponse } from '../utils/apiErrorHandler';

import { ofType } from 'redux-observable';
import {
  map,
  takeUntil,
  defaultIfEmpty,
  startWith,
  catchError,
  mergeMap,
} from 'rxjs/operators';
import { from } from 'rxjs';

export const signIn = (action$) =>
  action$.pipe(
    //fixed
    ofType('SIGN_IN'),
    mergeMap((action) => {
      return from(api.signIn(action.payload.credentials)).pipe(
        map((response) => ({
          type: 'SIGN_IN_SUCCESS',
          payload: response.response,
        })),
        startWith({ type: 'SIGN_IN_PENDING', payload: action.payload }),
        takeUntil(action$.pipe(ofType('SIGN_IN_ABORTED'))),
        catchError((error) =>
          getErrorResponse({ type: 'SIGN_IN_ERROR', error })
        ),
        defaultIfEmpty({ type: 'SIGN_IN_CANCELED' })
      );
    })
  );

export const signUp = (action$) =>
  action$.pipe(
    //fixed
    ofType('SIGN_UP'),
    mergeMap((action) => {
      return from(api.signUp(action.payload.userInfo)).pipe(
        map((response) => ({
          type: 'SIGN_UP_SUCCESS',
          payload: response.response,
        })),
        startWith({ type: 'SIGN_UP_PENDING', payload: action.payload }),
        takeUntil(action$.pipe(ofType('SIGN_UP_ABORTED'))),
        catchError((error) =>
          getErrorResponse({ type: 'SIGN_UP_ERROR', error })
        ),
        defaultIfEmpty({ type: 'SIGN_UP_CANCELED' })
      );
    })
  );

export const subscribe = (action$) =>
  action$.pipe(
    //fixed
    ofType('SUBSCRIBE'),
    mergeMap((action) => {
      return from(api.subscribe(action.payload.subscriberInfo)).pipe(
        map((response) => ({
          type: 'SUBSCRIBE_SUCCESS',
          payload: response.response,
        })),
        startWith({ type: 'SUBSCRIBE_PENDING', payload: action.payload }),
        takeUntil(action$.pipe(ofType('SUBSCRIBE_ABORTED'))),
        catchError((error) =>
          getErrorResponse({ type: 'SUBSCRIBE_ERROR', error })
        ),
        defaultIfEmpty({ type: 'SUBSCRIBE_CANCELED' })
      );
    })
  );

export const recover = (action$) =>
  action$.pipe(
    //fixed
    ofType('RECOVER'),
    mergeMap((action) => {
      return from(api.recover(action.payload.identity)).pipe(
        map((response) => ({
          type: 'RECOVER_SUCCESS',
          payload: response.response,
        })),
        startWith({ type: 'RECOVER_PENDING', payload: action.payload }),
        takeUntil(action$.pipe(ofType('RECOVER_ABORTED'))),
        catchError((error) =>
          getErrorResponse({ type: 'RECOVER_ERROR', error })
        ),
        defaultIfEmpty({ type: 'RECOVER_CANCELED' })
      );
    })
  );

export const recoverValidate = (action$) =>
  action$.pipe(
    //fixed
    ofType('RECOVER_VALIDATE'),
    mergeMap((action) => {
      return from(api.recoverValidate(action.payload.recoveryToken)).pipe(
        map((response) => ({
          type: 'RECOVER_VALIDATE_SUCCESS',
          payload: response.response,
        })),
        startWith({
          type: 'RECOVER_VALIDATE_PENDING',
          payload: action.payload,
        }),
        takeUntil(action$.pipe(ofType('RECOVER_VALIDATE_ABORTED'))),
        catchError((error) =>
          getErrorResponse({ type: 'RECOVER_VALIDATE_ERROR', error })
        ),
        defaultIfEmpty({ type: 'RECOVER_VALIDATE_CANCELED' })
      );
    })
  );

export const recoverChangePassword = (action$) =>
  action$.pipe(
    //fixed
    ofType('RECOVER_CHANGE_PASSWORD'),
    mergeMap((action) => {
      return from(
        api.recoverChangePassword(
          action.payload.recoveryToken,
          action.payload.password,
          action.payload.rePassword
        )
      ).pipe(
        map((response) => ({
          type: 'RECOVER_CHANGE_PASSWORD_SUCCESS',
          payload: response.response,
        })),
        startWith({
          type: 'RECOVER_CHANGE_PASSWORD_PENDING',
          payload: action.payload,
        }),
        takeUntil(action$.pipe(ofType('RECOVER_CHANGE_PASSWORD_ABORTED'))),
        catchError((error) =>
          getErrorResponse({ type: 'RECOVER_CHANGE_PASSWORD_ERROR', error })
        ),
        defaultIfEmpty({ type: 'RECOVER_CHANGE_PASSWORD_CANCELED' })
      );
    })
  );

export const getAvatarUploadURL = (action$) =>
  action$.pipe(
    //fixed
    ofType('GET_AVATAR_UPLOAD_URL'),
    mergeMap((action) => {
      return from(api.getAvatarUploadURL(action.payload.token)).pipe(
        map((response) => ({
          type: 'GET_AVATAR_UPLOAD_URL_SUCCESS',
          payload: response.response,
        })),
        startWith({
          type: 'GET_AVATAR_UPLOAD_URL_PENDING',
          payload: action.payload,
        }),
        takeUntil(action$.pipe(ofType('GET_AVATAR_UPLOAD_URL_ABORTED'))),
        catchError((error) =>
          getErrorResponse({ type: 'GET_AVATAR_UPLOAD_URL_ERROR', error })
        ),
        defaultIfEmpty({ type: 'GET_AVATAR_UPLOAD_URL_CANCELED' })
      );
    })
  );

export const uploadAvatar = (action$) =>
  action$.pipe(
    //fixed
    ofType('UPLOAD_AVATAR'),
    mergeMap((action) => {
      return from(
        api.uploadAvatar(action.payload.file, action.payload.signedURL)
      ).pipe(
        map((response) => ({
          type: 'UPLOAD_AVATAR_SUCCESS',
          payload: response.response,
        })),
        startWith({ type: 'UPLOAD_AVATAR_PENDING', payload: action.payload }),
        takeUntil(action$.pipe(ofType('UPLOAD_AVATAR_ABORTED'))),
        catchError((error) =>
          getErrorResponse({ type: 'UPLOAD_AVATAR_ERROR', error })
        ),
        defaultIfEmpty({ type: 'UPLOAD_AVATAR_CANCELED' })
      );
    })
  );

export const getCoverUploadURL = (action$) =>
  action$.pipe(
    //fixed
    ofType('GET_COVER_UPLOAD_URL'),
    mergeMap((action) => {
      return from(api.getCoverUploadURL(action.payload.token)).pipe(
        map((response) => ({
          type: 'GET_COVER_UPLOAD_URL_SUCCESS',
          payload: response.response,
        })),
        startWith({
          type: 'GET_COVER_UPLOAD_URL_PENDING',
          payload: action.payload,
        }),
        takeUntil(action$.pipe(ofType('GET_COVER_UPLOAD_URL_ABORTED'))),
        catchError((error) =>
          getErrorResponse({ type: 'GET_COVER_UPLOAD_URL_ERROR', error })
        ),
        defaultIfEmpty({ type: 'GET_COVER_UPLOAD_URL_CANCELED' })
      );
    })
  );

export const uploadCover = (action$) =>
  action$.pipe(
    //fixed
    ofType('UPLOAD_COVER'),
    mergeMap((action) => {
      return from(
        api.uploadCover(action.payload.file, action.payload.signedURL)
      ).pipe(
        map((response) => ({
          type: 'UPLOAD_COVER_SUCCESS',
          payload: response.response,
        })),
        startWith({ type: 'UPLOAD_COVER_PENDING', payload: action.payload }),
        takeUntil(action$.pipe(ofType('UPLOAD_COVER_ABORTED'))),
        catchError((error) =>
          getErrorResponse({ type: 'UPLOAD_COVER_ERROR', error })
        ),
        defaultIfEmpty({ type: 'UPLOAD_COVER_CANCELED' })
      );
    })
  );

export const updateProfileInfo = (action$) =>
  action$.pipe(
    //fixed
    ofType('UPDATE_PROFILE_INFO'),
    mergeMap((action) => {
      return from(api.updateProfileInfo(action.payload.profileInfo)).pipe(
        map((response) => ({
          type: 'UPDATE_PROFILE_INFO_SUCCESS',
          payload: response.response,
        })),
        startWith({
          type: 'UPDATE_PROFILE_INFO_PENDING',
          payload: action.payload,
        }),
        takeUntil(action$.pipe(ofType('UPDATE_PROFILE_INFO_ABORTED'))),
        catchError((error) =>
          getErrorResponse({ type: 'UPDATE_PROFILE_INFO_ERROR', error })
        ),
        defaultIfEmpty({ type: 'UPDATE_PROFILE_INFO_CANCELED' })
      );
    })
  );

export const getStripeOAuthLink = (action$) =>
  action$.pipe(
    ofType('GET_STRIPE_OAUTH_LINK'),
    mergeMap((action) => {
      return from(api.getStripeOAuthLink()).pipe(
        map((response) => ({
          type: 'GET_STRIPE_OAUTH_LINK_SUCCESS',
          payload: response.response,
        })),
        startWith({
          type: 'GET_STRIPE_OAUTH_LINK_PENDING',
          payload: action.payload,
        }),
        takeUntil(action$.pipe(ofType('GET_STRIPE_OAUTH_LINK_ABORTED'))),
        catchError((error) =>
          getErrorResponse({ type: 'GET_STRIPE_OAUTH_LINK_ERROR', error })
        ),
        defaultIfEmpty({ type: 'GET_STRIPE_OAUTH_LINK_CANCELED' })
      );
    })
  );

export const authorizeStripeOAuth = (action$) =>
  action$.pipe(
    ofType('AUTHORIZE_STRIPE_OAUTH'),
    mergeMap((action) => {
      return from(
        api.authorizeStripeOAuth(action.payload.code, action.payload.state)
      ).pipe(
        map((response) => ({
          type: 'AUTHORIZE_STRIPE_OAUTH_SUCCESS',
          payload: response.response,
        })),
        startWith({
          type: 'AUTHORIZE_STRIPE_OAUTH_PENDING',
          payload: action.payload,
        }),
        takeUntil(action$.pipe(ofType('AUTHORIZE_STRIPE_OAUTH_ABORTED'))),
        catchError((error) =>
          getErrorResponse({ type: 'AUTHORIZE_STRIPE_OAUTH_ERROR', error })
        ),
        defaultIfEmpty({ type: 'AUTHORIZE_STRIPE_OAUTH_CANCELED' })
      );
    })
  );

export const updateProduct = (action$) => {
  return action$.pipe(
    ofType('UPDATE_PRODUCT'),
    mergeMap((action) => {
      return api.updateProduct(action.payload.updates).pipe(
        map((response) => ({
          type: 'UPDATE_PRODUCT_SUCCESS',
          payload: response.response,
        })),
        startWith({
          type: 'UPDATE_PRODUCT_PENDING',
          payload: action.payload,
        }),
        takeUntil(action$.pipe(ofType('UPDATE_PRODUCT_ABORTED'))),
        catchError((error) =>
          getErrorResponse({ type: 'UPDATE_PRODUCT_ERROR', error })
        ),
        defaultIfEmpty({ type: 'UPDATE_PRODUCT_CANCELED' })
      );
    })
  );
};
