import classNames from 'classnames';
import './Header.scss';
import logo from '../images/logo.svg';
import React, { useEffect, useState } from 'react';
import {
  Badge,
  Avatar,
  Divider,
  Row,
  Col,
  Popover,
  Empty,
  Drawer,
  Alert,
  Button,
  Space,
} from 'antd';
import {
  UserOutlined,
  MenuFoldOutlined,
  IdcardOutlined,
  LogoutOutlined,
  WalletOutlined,
  CalendarOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { NavLink, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import {
  toggleMainMenu,
  closeMainMenu,
  logout,
  setNotificationSocket,
  getInitialInfo,
} from '../../actions';
import { BellIcon } from '../../utils/CustomIcons';
import MyAccountMenu from '../my-account/MyAccountMenu';

function Header() {
  const history = useHistory();
  const dispatch = useDispatch();
  const isMainMenuOpen = useSelector((state) => state.config.isMainMenuOpen);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const s3Base = useSelector((state) => state.config.s3Base);
  const user = useSelector((state) => state.auth.user);
  const token = useSelector((state) => state.auth.token);
  const [showMainMenu, setShowMainMenu] = useState(true);
  const [theWS, setTheWS] = useState();
  const [isVisibleNotificationPopover, setIsVisibleNotificationPopover] =
    useState(false);
  const [isVisibleMyAccountPopover, setIsVisibleMyAccountPopover] =
    useState(false);
  const pathname = history.location.pathname;

  // console.log(history.location.hash)

  const notificationsTitle = (
    <h4 className='Notifications__title'>Notifications</h4>
  );
  const notificationsContent = (
    <section className='Notifications__content'>
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={<span>No Notifications</span>}
      />
    </section>
  );

  const accountMenuTitle = <h4 className='AccountMenu__title'>My Account</h4>;

  const goToConnectAccount = () => {
    const { innerWidth: width } = window;
    const elementClass =
      window.innerWidth > 990 ? 'connect-account-lg' : 'connect-account';

    console.log(pathname);
    if (pathname !== '/my-account/edit-profile') {
      history.push(`/my-account/edit-profile`);
      setTimeout(() => {
        // window.location = '#';
        // window.location = `#${elementClass}`;

        const element = document.getElementById(`${elementClass}`);
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
        element.classList.add('highlight');
        setTimeout(() => {
          element.classList.remove('highlight');
        }, 4000);
      }, 1000);
    } else {
      const element = document.getElementById(`${elementClass}`);
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
      element.classList.add('highlight');
      setTimeout(() => {
        element.classList.remove('highlight');
      }, 4000);
    }
  };

  const accountMenuContent = (
    <section className='AccountMenu__content'>
      <nav className={classNames('navigation')}>
        <ul className='navigation__list'>
          <li className=''>
            <NavLink
              className=''
              activeClassName='active'
              to='/my-account/edit-profile'
              href='/my-account/edit-profile'
              onClick={() => setIsVisibleMyAccountPopover(false)}
            >
              <UserOutlined className='nav-item-icon' /> My Profile
            </NavLink>
          </li>
          {/*
          <li className="">
            <NavLink
              className=""
              activeClassName="active"
              to="/my-account/messages"
              href="/my-account/messages"
              onClick={() => setIsVisibleMyAccountPopover(false)}
            >
              <BellIcon className="nav-item-icon" />
              <Badge dot count={0} offset={[-15, 3]}>
                <BellIcon className={classNames("BellIcon")} />
              </Badge>
              Messages
            </NavLink>
          </li>
          */}
          {user && user.musician && user.musician.slug && (
            <li className=''>
              <NavLink
                className=''
                activeClassName='active'
                to={`/u/${user.musician.slug}`}
                href={`/u/${user.musician.slug}`}
                onClick={() => setIsVisibleMyAccountPopover(false)}
              >
                <IdcardOutlined className='nav-item-icon' /> My Page
              </NavLink>
            </li>
          )}
          <li className=''>
            <NavLink
              className=''
              activeClassName='active'
              to='/my-account/my-money'
              href='/my-account/my-money'
              onClick={() => setIsVisibleMyAccountPopover(false)}
            >
              <WalletOutlined className='nav-item-icon' /> My Money
            </NavLink>
          </li>
          {false && (
            <li className=''>
              <NavLink
                className=''
                activeClassName='active'
                to='#'
                href='#'
                onClick={() => setIsVisibleMyAccountPopover(false)}
              >
                <CalendarOutlined className='nav-item-icon' /> My Events
              </NavLink>
            </li>
          )}
          <li className=''>
            <a href='https://www.museit.co/faq' target='_blank'>
              <QuestionCircleOutlined className='nav-item-icon' /> FAQ
            </a>
          </li>
          <li className='li-logout'>
            <NavLink
              onClick={() => {
                setIsVisibleMyAccountPopover(false);
                history.replace('/logout');
              }}
              className='logout'
              activeClassName='active'
              to='/logout'
              href='/logout'
            >
              <LogoutOutlined className='nav-item-icon' /> Log out
            </NavLink>
          </li>
        </ul>
      </nav>
    </section>
  );

  useEffect(() => {
    if (isAuthenticated) {
      setTimeout(() => {
        if (!theWS) {
          const base_url = `${window.location.origin}`;
          window.io.sails.reconnection = true; // This will attempt to connect if user get disconected.
          // IP address is a DO droplet for development
          const isDeployed =
            base_url.includes('museit.co') ||
            base_url.includes('146.190.36.194');
          const notificationSocket = window.io.sails.connect(
            isDeployed ? base_url : 'http://localhost:1337'
          );
          notificationSocket.on('connect', () => {
            notificationSocket.request(
              {
                method: 'post',
                url: '/api/v1/notifications/subscribe',
                data: {
                  userId: user.id,
                },
                headers: {
                  Authorization: `JWT ${token}`,
                },
              },
              function (resData, jwRes) {
                console.log(jwRes.statusCode); // => 200
              }
            );

            notificationSocket.on(
              '[PaymentRecivedNotification]',
              function (notification) {
                console.log('[PaymentRecivedNotification]', notification);
                // Adding Notifications to the app popup

                // Adding notification to the browser:
                if (
                  'Notification' in window &&
                  Notification.permission === 'granted' &&
                  notification.title
                ) {
                  const webNotification = new Notification(notification.title, {
                    ...(notification.message
                      ? { body: notification.message }
                      : { body: '' }), //
                    ...(notification.icon
                      ? { icon: notification.icon }
                      : { icon: '' }), //
                  });
                  setTimeout(() => webNotification.close(), 10000); // Closing notification
                }
              }
            );
            // Setting up the notification web socket
            setTheWS(notificationSocket);
          });
        }
      }, 2000);
    }
  }, [isAuthenticated, user, token, theWS]);

  useEffect(() => {
    if (isAuthenticated) {
      // Let's check if the browser supports notifications
      if (!('Notification' in window)) {
        // alert("This browser does not support desktop notification");
      }

      // Otherwise, we need to ask the user for permission
      else if (
        Notification.permission !== 'denied' &&
        Notification.permission !== 'granted'
      ) {
        Notification.requestPermission(function (permission) {
          // If the user accepts, let's create a notification
          if (permission === 'granted') {
            var webNotification = new Notification(
              'Subscribed to notifications!'
            );
            setTimeout(() => webNotification.close(), 10000); // Closing notification
          }
        });
      }
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (pathname.includes('/my-account')) {
      setShowMainMenu(false);
    } else {
      setShowMainMenu(true);
    }
  }, [pathname]);

  return (
    <Row
      justify='center'
      align='middle'
      className={classNames('Master__Header', { musicianPage: true })}
    >
      {isAuthenticated &&
        !user.stripe.connected &&
        !user.stripe.isOverTheLimit && (
          <Alert
            className='site-wide-notification'
            message='Connect your bank account'
            description='Your profile is public and you can start receiving donations, but we will not be able to process payouts to you until you finish connecting your bank account.'
            type='warning'
            showIcon
            action={
              <Space direction='vertical'>
                <Button
                  size='small'
                  type='primary'
                  onClick={() => goToConnectAccount()}
                >
                  Connect Bank Account
                </Button>
                <Button size='small' type='ghost'>
                  More Information
                </Button>
              </Space>
            }
          />
        )}

      {isAuthenticated &&
        !user.stripe.connected &&
        user.stripe.isOverTheLimit &&
        !moment().isSameOrAfter(
          moment(user.stripe.willBeFullyRestrictedAt)
        ) && (
          <Alert
            className='site-wide-notification'
            message='Connect your bank account now'
            description={`Oops! Looks like you have not connected your bank account. Please connect your bank account within the next ${moment(
              user.stripe.willBeFullyRestrictedAt
            ).diff(
              moment(),
              'hours'
            )} hours to continue accepting payments on your profile.`}
            type='error'
            showIcon
            action={
              <Space direction='vertical'>
                <Button
                  size='small'
                  type='primary'
                  onClick={() => goToConnectAccount()}
                >
                  Connect Bank Account
                </Button>
                <Button size='small' type='ghost'>
                  More Information
                </Button>
              </Space>
            }
          />
        )}

      {isAuthenticated &&
        !user.stripe.connected &&
        user.stripe.isOverTheLimit &&
        moment().isSameOrAfter(moment(user.stripe.willBeFullyRestrictedAt)) && (
          <Alert
            className='site-wide-notification'
            message='Attention: Connect your bank account'
            description={`Your Museit account has been placed on hold and your profile is now private. In order to ensure we can distribute payouts to you, please connect your bank account to continue using Museit.`}
            type='error'
            showIcon
            action={
              <Space direction='vertical'>
                <Button
                  size='small'
                  type='primary'
                  onClick={() => goToConnectAccount()}
                >
                  Connect Bank Account
                </Button>
                <Button size='small' type='ghost'>
                  More Information
                </Button>
              </Space>
            }
          />
        )}

      <Col xs={22} sm={22} md={22} lg={22} xl={23} xxl={23}>
        <header className={classNames('Main__Header')}>
          <section className='Main__Header__wrapper'>
            <section
              className={classNames('LeftHeader', {
                hideOnMedium: !showMainMenu,
              })}
            >
              <NavLink className='logo' href='/' to='/'>
                <figure>
                  <img src={logo} alt='Museit Logo' />
                </figure>
              </NavLink>
              <nav
                className={classNames('navigation', { open: isMainMenuOpen })}
              >
                <ul className='navigation-right'>
                  {!isAuthenticated && (
                    <li className='li-signin'>
                      <NavLink
                        onClick={() => setIsVisibleMyAccountPopover(false)}
                        className='signin custom-btn'
                        activeClassName='active'
                        to='/signin'
                        href='/signin'
                      >
                        Sign In
                      </NavLink>
                    </li>
                  )}
                  {!isAuthenticated && (
                    <li className='li-signup'>
                      <NavLink
                        onClick={() => setIsVisibleMyAccountPopover(false)}
                        className='signup custom-btn'
                        activeClassName='active'
                        to='/signup'
                        href='/signup'
                      >
                        Sign Up
                      </NavLink>
                    </li>
                  )}
                  {isAuthenticated && false && (
                    <li className='li-my-account'>
                      <NavLink
                        onClick={() => setIsVisibleMyAccountPopover(false)}
                        className='my-account'
                        activeClassName='active'
                        to='/my-account'
                        href='/my-account'
                      >
                        My Account
                      </NavLink>
                    </li>
                  )}
                  {isAuthenticated && false && (
                    <li className='li-logout'>
                      <NavLink
                        onClick={() => {
                          dispatch(logout());
                          setIsVisibleMyAccountPopover(false);
                          history.replace('/');
                        }}
                        className='logout custom-btn'
                        activeClassName='active'
                        to='/logout'
                        href='/logout'
                      >
                        Logout
                      </NavLink>
                    </li>
                  )}
                </ul>
              </nav>
            </section>

            {!isAuthenticated && (
              <section className='RightHeader'>
                <button
                  className={classNames('toggle-mobile-menu', {
                    open: isMainMenuOpen,
                  })}
                  onClick={() =>
                    window.innerWidth < 768 && dispatch(toggleMainMenu())
                  }
                >
                  <MenuFoldOutlined
                    style={{ fontSize: 18, color: '#2D3958' }}
                  />
                </button>
              </section>
            )}

            {isAuthenticated && (
              <section className='RightHeader'>
                <button
                  className={classNames('toggle-mobile-menu', {
                    open: isMainMenuOpen,
                  })}
                  onClick={() =>
                    window.innerWidth < 768 && dispatch(toggleMainMenu())
                  }
                >
                  <Badge className='MyAvatar__badge' count={0} offset={[-5, 5]}>
                    {user && user.musician && user.musician.avatar ? (
                      <div
                        className='MyAvatar__image'
                        style={{
                          backgroundImage: `url(${
                            s3Base + user.musician.avatar
                          })`,
                        }}
                      />
                    ) : (
                      <Avatar size={40} icon={<UserOutlined />} />
                    )}
                  </Badge>
                </button>
                <section className='MyAvatar'>
                  <section className='MyAvatar__main'>
                    <Popover
                      overlayClassName='MuseItPopover AccountMenuPopover'
                      placement='bottomRight'
                      title={accountMenuTitle}
                      content={accountMenuContent}
                      trigger='click'
                      visible={isVisibleMyAccountPopover}
                      onVisibleChange={(visible) =>
                        setIsVisibleMyAccountPopover(visible)
                      }
                    >
                      <section className='MyAvatar__details'>
                        {user && user.firstName && user.lastName && (
                          <h4 className='MyAvatar__details--name'>
                            {user.firstName.split(' ')[0] +
                              ' ' +
                              user.lastName.split(' ')[0] +
                              ' '}
                          </h4>
                        )}
                        {user && user.musician && user.musician.name && (
                          <h5 className='MyAvatar__details--band-name'>
                            {user.musician.name}
                          </h5>
                        )}
                      </section>
                      <Badge
                        className='MyAvatar__badge'
                        count={0}
                        offset={[-5, 5]}
                      >
                        {user && user.musician && user.musician.avatar ? (
                          <div
                            className='MyAvatar__image'
                            style={{
                              backgroundImage: `url(${
                                s3Base + user.musician.avatar
                              })`,
                            }}
                          />
                        ) : (
                          <Avatar size={40} icon={<UserOutlined />} />
                        )}
                      </Badge>
                    </Popover>
                  </section>
                </section>
                {/*
                  <Divider className="RightHeader__divider" type="vertical" plain />
                  <Popover
                    overlayClassName="MuseItPopover NotificationsPopover"
                    arrowPointAtCenter
                    placement="bottomRight"
                    title={notificationsTitle}
                    content={notificationsContent}
                    trigger="click"
                    visible={isVisibleNotificationPopover}
                    onVisibleChange={(visible) => setIsVisibleNotificationPopover(visible)}
                  >
                    <section className="Notifications">
                      <Badge dot count={0} offset={[-5, 5]}>
                        <BellIcon className={classNames("BellIcon")} />
                      </Badge>
                    </section>
                  </Popover>
                  */}
              </section>
            )}
          </section>
          <Drawer
            className='MainMenuDrawer'
            width={230}
            placement='right'
            closable={false}
            onClose={() => dispatch(closeMainMenu())}
            visible={isMainMenuOpen}
          >
            <MyAccountMenu />
          </Drawer>
        </header>
      </Col>
    </Row>
  );
}

export default Header;
