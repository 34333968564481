/* eslint-disable import/prefer-default-export */
import { of } from 'rxjs';
import { message } from 'antd';

export const getErrorResponse = (response) => {
  if (
    response.error.xhr.response &&
    response.error.xhr.response.code &&
    response.error.xhr.response.code === 'E_FORCED_TOKEN_UPDATE'
  ) {
    // Saving the new token on localstorage for the refresh.
    localStorage.setItem('token', response.error.xhr.response.token);
    // Showing a message before to force logout.
    message.warning('Your session has expired, please login again.');
    window.location.reload();
  }
  if (
    response.error.xhr.response &&
    response.error.xhr.response.code &&
    response.error.xhr.response.code === 'E_FORCED_LOGOUT'
  ) {
    // Showing a message before to force logout.
    message.warning('Your session has expired, please login again.');
    window.location.replace(`${window.location.origin}/logout`);
  }
  if (
    response.error.xhr.response &&
    response.error.xhr.response.code &&
    response.error.xhr.response.message &&
    response.error.xhr.response.code === 'E_UNAUTHORIZED' &&
    response.error.xhr.response.message === 'jwt expired'
  ) {
    // Showing a message before to force logout.
    message.warning('Your session has expired, please login again.');
    window.location.replace(`${window.location.origin}/logout`);
  }

  const initialPayload = response.initialPayload ? response.initialPayload : {};
  if (response.error.xhr.response) {
    return of({
      type: response.type,
      payload: {
        initialPayload,
        error: true,
        message: response.error.xhr.response.message,
        code: response.error.xhr.response.code,
        errors: response.error.xhr.response.errors,
      },
    });
  }
  return of({
    type: response.type,
    payload: {
      initialPayload,
      error: true,
      message: 'No server response. (Generic)',
      code: 'GENERIC_ERROR_CODE',
      errors: {},
    },
  });
};
