import React, { useState } from "react";
import { Button } from "antd";
import QueueAnim from "rc-queue-anim";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CopyOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import classNames from "classnames";

import "./PaymentMethod.scss";

const PaymentMethod = (props) => {
  const [wasCopied, setWasCopied] = useState(false);
  const { data: currentMusician } = useSelector(
    (state) => state.home.currentMusician
  );
  const extraAttrs = useSelector((state) => state.home.buttonPayExtraAttrs);

  return (
    <div className="payment-method">
      <QueueAnim
        className="AnimationWrapper"
        type={["bottom", "top"]}
        delay={[1600, 500]}
        interval={[100, 100]}
        duration={[400, 400]}
      >
        {props.render && (
          <h4 className="PaymentMethod__title" key="1">
            Select a payment method
          </h4>
        )}
        {props.render && (
          <Button
            key="2"
            size="large"
            className="PaymentMethod__credit-card"
            type="primary"
            onClick={props.onCCClick}
          >
            Credit Card
          </Button>
        )}
        {props.render && (
          <section className="GAPButtonWrapper" key="3">
            <Button
              size="large"
              className="PaymentMethod__credit-card"
              type="primary"
              onClick={props.onAPClick}
            >
              Apple Pay
            </Button>
            {!extraAttrs.allowedPlaforms.applePay &&
              props.tmpPaymentMethod === "AP" && (
                <section className="GAPHelper not-allowed-helper">
                  <h4 className="GAPHelper__title">
                    Want to pay with Apple Pay?
                  </h4>
                  <p className="GAPHelper__details">
                    Apple Pay is only available on Safari browser on iOS
                    devices. Copy this link and paste it in your Safari Browser
                  </p>
                  <section className="GAPHelper__CTC">
                    <CopyToClipboard
                      text={`https://app.museit.co/u/${currentMusician.slug}`}
                      onCopy={() => {
                        setWasCopied(true);
                        setTimeout(() => setWasCopied(false), 500);
                      }}
                    >
                      <button
                        className={classNames("GAPHelper__CTC--button", {
                          copied: wasCopied,
                        })}
                      >
                        <span className="GAPHelper__CTC--text">{`app.museit.co/u/${currentMusician.slug}`}</span>
                        <CopyOutlined className="GAPHelper__CTC--icon" />
                      </button>
                    </CopyToClipboard>
                  </section>
                </section>
              )}
          </section>
        )}
        {props.render && (
          <section className="GAPButtonWrapper" key="4">
            <Button
              size="large"
              className="PaymentMethod__credit-card"
              type="primary"
              onClick={props.onGPClick}
            >
              Google Pay
            </Button>
            {!extraAttrs.allowedPlaforms.googlePay &&
              props.tmpPaymentMethod === "GP" && (
                <section className="GAPHelper not-allowed-helper">
                  <h4 className="GAPHelper__title">
                    Want to pay with Google Pay?
                  </h4>
                  <p className="GAPHelper__details">
                    Google Pay is only available on Chrome browser on Android
                    devices. Copy this link and paste it in your Chrome Broswer
                  </p>
                  <section className="GAPHelper__CTC">
                    <CopyToClipboard
                      text={`https://app.museit.co/u/${currentMusician.slug}`}
                      onCopy={() => {
                        setWasCopied(true);
                        setTimeout(() => setWasCopied(false), 500);
                      }}
                    >
                      <button
                        className={classNames("GAPHelper__CTC--button", {
                          copied: wasCopied,
                        })}
                      >
                        <span className="GAPHelper__CTC--text">{`app.museit.co/u/${currentMusician.slug}`}</span>
                        <CopyOutlined className="GAPHelper__CTC--icon" />
                      </button>
                    </CopyToClipboard>
                  </section>
                </section>
              )}
          </section>
        )}
      </QueueAnim>
    </div>
  );
};

export default PaymentMethod;
