import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Button, Switch, message } from 'antd';
import { fabric } from 'fabric';
import { useDispatch } from 'react-redux';
import { updateProduct } from '../../../actions';
import './Designer.scss';
import { reject } from 'lodash';

const printWidth = 1600;
const printHeight = 2285;
const printAspect = printWidth / printHeight;
const printScale = 0.55;
const strokeWidth = 1.5;

const Designer = ({ title, initProductImg, templateImg, enabled }) => {
  const [objLoading, setObjLoading] = useState(false);
  const [checked, setChecked] = useState(enabled);
  const canvasRef = useRef();
  const fabricCanvasRef = useRef();
  const dispatch = useDispatch();
  const fileInputRef = useRef();
  const [isEditing, setIsEditing] = useState(false);
  const [productImg, setProductImg] = useState(initProductImg);
  const imgRef = useRef();
  const rectRef = useRef();
  const borderRef = useRef();
  const objRef = useRef();
  const fileRef = useRef();

  const loadImg = useCallback(async (url) => {
    return new Promise((resolve, reject) => {
      fabric.Image.fromURL(
        url,
        function (img, isError) {
          if (isError) {
            reject(new Error('Error loading image'));
          } else {
            resolve(img);
          }
        },
        { crossOrigin: 'anonymous' }
      );
    });
  }, []);

  const update = useCallback(async () => {
    if (!fabricCanvasRef.current) {
      return;
    }

    // Canvas
    fabricCanvasRef.current.setWidth(imgRef.current.offsetWidth);
    fabricCanvasRef.current.setHeight(imgRef.current.offsetHeight);

    // Background image
    const img = fabricCanvasRef.current.backgroundImage;
    img.set({
      scaleX: fabricCanvasRef.current.width / img.width,
      scaleY: fabricCanvasRef.current.height / img.height,
    });

    // Rect
    const baseDim =
      Math.min(fabricCanvasRef.current.width, fabricCanvasRef.current.height) *
      printScale;
    let rectWidth = printAspect > 1 ? baseDim : baseDim * printAspect;
    let rectHeight = printAspect > 1 ? baseDim / printAspect : baseDim;
    const oldWidth = rectRef.current.width;
    rectRef.current.set({
      left: (fabricCanvasRef.current.width - rectWidth) / 2,
      top: (fabricCanvasRef.current.height - rectHeight) / 2,
      width: rectWidth,
      height: rectHeight,
    });
    const delta = rectRef.current.width / oldWidth;

    // Border
    borderRef.current.set({
      left:
        (fabricCanvasRef.current.width - rectRef.current.width - strokeWidth) /
        2,
      top:
        (fabricCanvasRef.current.height -
          rectRef.current.height -
          strokeWidth) /
        2,
      width: rectRef.current.width + strokeWidth,
      height: rectRef.current.height + strokeWidth,
    });

    // Obj
    if (objRef.current) {
      objRef.current.left *= delta;
      objRef.current.top *= delta;
      objRef.current.scaleX *= delta;
      objRef.current.scaleY *= delta;
    }

    fabricCanvasRef.current.renderAll();
  }, []);

  const create = useCallback(async () => {
    // Canvas
    fabricCanvasRef.current = new fabric.Canvas(canvasRef.current);
    fabricCanvasRef.current.stateful = true;

    // Background image
    const img = await loadImg(templateImg);
    fabricCanvasRef.current.setBackgroundImage(img);

    // Rect
    rectRef.current = new fabric.Rect({
      fill: 'transparent',
      selectable: false,
    });
    fabricCanvasRef.current.add(rectRef.current);

    // Border
    borderRef.current = new fabric.Rect({
      fill: 'transparent',
      selectable: false,
      stroke: 'gray',
      strokeWidth: strokeWidth,
      strokeDashArray: [5, 5],
    });
    fabricCanvasRef.current.add(borderRef.current);

    await update();

    // Obj
    setObjLoading(true);
    const reader = new FileReader();
    await new Promise((resolve) => {
      reader.onload = async (f) => {
        const data = f.target.result;
        const img = await loadImg(data);
        const maxScale = 0.6;
        const scale = Math.min(
          (rectRef.current.width * maxScale) / img.width,
          (rectRef.current.height * maxScale) / img.height
        );

        img.set({
          scaleX: scale,
          scaleY: scale,
          left: fabricCanvasRef.current.width / 2,
          top: fabricCanvasRef.current.height / 2,
          originX: 'center',
          originY: 'center',
        });

        objRef.current = img;
        fabricCanvasRef.current.add(img);

        setObjLoading(false);
        resolve(img);
      };
      reader.readAsDataURL(fileRef.current);
    });
  }, [loadImg, templateImg, update]);

  const handleObjMoving = useCallback(async (e) => {
    const obj = e.target;

    // TOOD: Re-add cushion?
    const cushion = 2;

    // If object is too big, ignore
    if (
      obj.currentHeight > rectRef.current.height || // Adjusted for buffer
      obj.currentWidth > rectRef.current.width // Adjusted for buffer
    ) {
      return;
    }

    obj.setCoords();

    // Top left
    if (
      obj.getBoundingRect().top < rectRef.current.top ||
      obj.getBoundingRect().left < rectRef.current.left
    ) {
      obj.top = Math.max(
        obj.top,
        obj.top - obj.getBoundingRect().top + rectRef.current.top
      );
      obj.left = Math.max(
        obj.left,
        obj.left - obj.getBoundingRect().left + rectRef.current.left
      );
    }

    // Bottom right
    if (
      obj.getBoundingRect().top + obj.getBoundingRect().height >
        rectRef.current.top + rectRef.current.height ||
      obj.getBoundingRect().left + obj.getBoundingRect().width >
        rectRef.current.left + rectRef.current.width
    ) {
      obj.top = Math.min(
        obj.top,
        rectRef.current.top +
          rectRef.current.height -
          obj.getBoundingRect().height / 2
      );
      obj.left = Math.min(
        obj.left,
        rectRef.current.left +
          rectRef.current.width -
          obj.getBoundingRect().width / 2
      );
    }
  }, []);

  useEffect(() => {
    let resizeObserver;

    const setup = async () => {
      await create();

      resizeObserver = new ResizeObserver(() => {
        update();
      });
      resizeObserver.observe(imgRef.current);

      fabricCanvasRef.current.on('object:moving', handleObjMoving);

      // TODO: Modularize this out
      const brOld = {};
      fabricCanvasRef.current.on('object:scaling', function (e) {
        const obj = e.target;
        obj.setCoords();
        let brNew = obj.getBoundingRect();

        const inBounds =
          brNew.width + brNew.left <
            rectRef.current.width + rectRef.current.left &&
          brNew.height + brNew.top <
            rectRef.current.height + rectRef.current.top &&
          brNew.left > rectRef.current.left &&
          brNew.top > rectRef.current.top;

        if (brOld.left !== undefined && !inBounds) {
          obj.left = brOld.left;
          obj.top = brOld.top;
          obj.scaleX = brOld.scaleX;
          obj.scaleY = brOld.scaleY;
          obj.width = brOld.width;
          obj.height = brOld.height;
        } else {
          brOld.left = obj.left;
          brOld.top = obj.top;
          brOld.scaleX = obj.scaleX;
          brOld.scaleY = obj.scaleY;
          brOld.width = obj.width;
          brOld.height = obj.height;
        }
      });
    };

    if (isEditing) {
      setup();
    }

    // Clean up
    // We already take care of fabricCanvasRef.current elsewhere
    let currentImgRef = imgRef.current;
    return () => {
      if (resizeObserver) {
        resizeObserver.unobserve(currentImgRef);
      }
    };
  }, [create, handleObjMoving, isEditing, update]);

  const handleSwitchChange = useCallback(
    async (checked) => {
      dispatch(updateProduct({ enabled: checked, title }));
      setChecked(checked);
    },
    [dispatch, title]
  );

  const handleInputChange = (e) => {
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
      return;
    }

    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
      return;
    }

    fileRef.current = file;

    setIsEditing(true);
  };

  const handleEditCancel = () => {
    if (isEditing) {
      // Clean up canvas
      fabricCanvasRef.current.dispose();
      setIsEditing(false);
    } else {
      fileInputRef.current.click();
      // Is editing triggered on successful image upload
    }
  };

  const handleSave = () => {
    // Remove border
    fabricCanvasRef.current.remove(borderRef.current);

    // Export main image
    const mainMultiplier =
      imgRef.current.naturalWidth / fabricCanvasRef.current.width;
    const mainImgUrl = fabricCanvasRef.current.toDataURL({
      multiplier: mainMultiplier,
      format: 'png', // Default but we'll specify for clarity
    });
    setProductImg(mainImgUrl);

    // Export print image
    fabricCanvasRef.current.setBackgroundImage(
      null,
      fabricCanvasRef.current.renderAll.bind(fabricCanvasRef.current)
    );
    const printMultiplier = printWidth / rectRef.current.width;
    const printImgUrl = fabricCanvasRef.current.toDataURL({
      left: rectRef.current.left,
      top: rectRef.current.top,
      width: rectRef.current.width,
      height: fabricCanvasRef.current.height,
      multiplier: printMultiplier,
      format: 'png', // Default but we'll specify for clarity
    });

    // Update in Shopify with backend API calls
    const mainImg = mainImgUrl.split(',')[1];
    const printImg = printImgUrl.split(',')[1];

    dispatch(updateProduct({ mainImg, printImg, title }));

    // Clean up canvas
    fabricCanvasRef.current.dispose();
    fabricCanvasRef.current = null;

    setIsEditing(false);
  };

  return (
    <div className='designer'>
      <div className='configurer'>
        <img ref={imgRef} className='shirt' src={productImg} alt='merch' />
        {isEditing && <canvas ref={canvasRef} className='shirt' />}
      </div>
      <div className='side-wrapper'>
        <div className='enable-text'>
          Enable Sales on <b>My Page</b>
        </div>
        <div>
          <Switch
            checked={checked}
            onChange={handleSwitchChange}
            className='switch'
          />
        </div>
        <div className='designer-btns'>
          <Button type='secondary' onClick={handleEditCancel}>
            {isEditing ? 'Cancel' : 'Edit'}
          </Button>
          {isEditing && (
            <Button type='primary' onClick={handleSave}>
              Save
            </Button>
          )}
        </div>
        <input
          type='file'
          accept='image/*'
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleInputChange}
          onClick={() => (fileInputRef.current.value = null)}
        />
      </div>
    </div>
  );
};

export default Designer;
