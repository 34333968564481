import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom'
import './Home.scss';

// import { useSelector, useDispatch } from 'react-redux'


function Home() {
  // const { data: currentMusician, isLoading, errors } = useSelector(state => state.home.currentMusician);
  // const dispatch = useDispatch();
  return (
    <section className="Home">
      <section className="Home__container">
        <section className="Hero">
          Welcome Home!
        </section>
        <section className="Content">

        </section>
      </section>
    </section>
  );
}

export default Home;
