import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import { ConnectedRouter } from 'connected-react-router';
import store, { history } from './store';

import 'normalize.css';
import './antd.less';
import './index.scss';
import './notifications.scss';

import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import Home from './components/home/Home';
import Musician from './components/musician/Musician';
import MyAccount from './components/my-account/MyAccount';

import SignUp from './components/sign-up/SignUp';
import SignIn from './components/sign-in/SignIn';
import Recover from './components/recover/Recover';
import Logout from './components/logout/Logout';
import NotFound from './components/not-found/NotFound';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { getEnv } from './utils/common';

const stripePK = {
  development: 'pk_test_ldX4NZv7Q07w8vvJtgyuGIkU003au1Q74E',
  staging: 'pk_test_ldX4NZv7Q07w8vvJtgyuGIkU003au1Q74E',
  production:
    'pk_live_51G1z9FBZmZXrTX06kkFrnWWr4abNoEBYiTPYn4srnN0kkMNYjcGcwjDS9cMAYul8S2xl3MW7w8ehFdBPtIk69E9500o26vx0y6',
};

console.log('env:', getEnv());
const stripePromise = loadStripe(stripePK[getEnv()]);

// import FAQ from './components/faq/Faq';

const router = (
  <Elements stripe={stripePromise}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        {' '}
        {/* place ConnectedRouter under Provider */}
        <Route path='/' component={Header} />
        <Switch>
          <Route exact path='/' component={SignUp} />
          <Route path='/u/:slug' component={Musician} />
          <Route path='/signup' component={SignUp} />
          <Route path='/signin' component={SignIn} />
          <Route path='/recover/:recoveryToken?' component={Recover} />
          <Route path='/my-account' component={MyAccount} />
          <Route path='/logout' component={Logout} />

          <Route component={NotFound} />

          {/*
         <Route path="/privacy-policy" component={PrivacyPolicy} />
         <Route path="/terms-of-use" component={TermsOfUse} />
         <Route path="/cookie-policy" component={CookiePolicy} />
         <Route path="/terms-of-service" component={TermsOfService} />
         */}
        </Switch>
        <Route path='/' component={Footer} />
      </ConnectedRouter>
    </Provider>
  </Elements>
);

ReactDOM.render(router, document.getElementById('root'));
