import React from 'react'
import classNames from 'classnames'

import { ChevronIcon } from '../../../utils/CustomIcons'

import { Collapse } from 'antd'
import './Messages.scss'

const { Panel } = Collapse


export default function Messages() {
  return (
    <section className="Messages">
      <Collapse
        accordion
        bordered={false}
        defaultActiveKey="0"
        expandIconPosition="right"
        expandIcon={({ isActive }) => <ChevronIcon className={classNames("ChevronIcon",{ active: isActive })} />}
        className="Collap"
      >
        <Panel header="This is panel header 1" key="1" >
          <div>Lorem ipsum dolor sit amet, conse ctetur adipiscing elit. Fusce velit tortor <br /> Donec vestibulum justo a diam ultricies pellentesque. Quisque mattis diam vel lacus tincidunt elementum. </div>
        </Panel>

        <Panel header="This is panel header 2" key="2" >
          <div>Lorem ipsum dolor sit amet, conse ctetur adipiscing elit. Fusce velit tortor <br /> Donec vestibulum justo a diam ultricies pellentesque. Quisque mattis diam vel lacus tincidunt elementum. </div>
        </Panel>

        <Panel header="This is panel header 3" key="3" >
          <div>Lorem ipsum dolor sit amet, conse ctetur adipiscing elit. Fusce velit tortor <br /> Donec vestibulum justo a diam ultricies pellentesque. Quisque mattis diam vel lacus tincidunt elementum. </div>
        </Panel>

        <Panel header="This is panel header 4" key="4" >
          <div>Lorem ipsum dolor sit amet, conse ctetur adipiscing elit. Fusce velit tortor <br /> Donec vestibulum justo a diam ultricies pellentesque. Quisque mattis diam vel lacus tincidunt elementum. </div>
        </Panel>

        <Panel header="This is panel header 5" key="5" >
          <div>Lorem ipsum dolor sit amet, conse ctetur adipiscing elit. Fusce velit tortor <br /> Donec vestibulum justo a diam ultricies pellentesque. Quisque mattis diam vel lacus tincidunt elementum. </div>
        </Panel>
      </Collapse>
    </section>
  )
}
