import React from 'react';
import { NavLink } from 'react-router-dom'
import './NotFound.scss';

export default function NotFound () {
	return(
    <section className="NotFound">
			<div className="NotFound__wrapper">
				<div className="NotFound__content">
					<figure>

					</figure>
					<div className="info-wrap">
						<div className="inner">
							<h1>Not found</h1>
							<p>
								Not found message.
							</p>
  						<NavLink className="back-home" to="/" href="/" >Home</NavLink>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}
