const storeBaseAttributes = {
  isLoading: false,
  errors: {
    hasError: false,
    code: '',
    message: '',
    all: {},
  }
};
export default storeBaseAttributes
