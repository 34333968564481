import React, { useEffect } from "react"
// import classNames from 'classnames'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Row, Col } from 'antd'

import ArtBubbles from '../art-bubbles/ArtBubbles';
import SignUpForm from './SignUpForm';
import './SignUp.scss';

export default function SignUp (){
  const { token, isAuthenticated } = useSelector(state => state.auth);
  let history = useHistory()

  useEffect(() => {
    if(token && isAuthenticated){
      history.replace('/my-account')
    }
  }, [token, isAuthenticated, history])

  return (
    <Row className="SignUp" justify="center" align="middle">
      {(!isAuthenticated) && (
        <Col xs={24} sm={24} md={22} lg={22} xl={20} xxl={18}>
          <Row className="SignUp__wrapper" justify="center" align="middle">
          <Col className="SignUp__art-wrapper" xs={22} sm={18} md={18} lg={{ span: 11 }} xl={{ span: 9 }} xxl={{ span: 9 }}>
            <ArtBubbles />
          </Col>
            <Col className="SignUp__form-wrapper" xs={22} sm={18} md={18} lg={{ span: 8, offset: 2 }} xl={{ span: 8, offset: 2 }} xxl={{ span: 7, offset: 2 }}>
              <h1 className="SignUp__title">Sign Up</h1>
              <p className="SignUp__details">Sign up and start collecting tips and messages from your fans.</p>
              <SignUpForm />
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  )
}
