import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  Avatar,
  Row,
  Col,
  Tooltip,
  Button,
  Form,
  Input,
  Modal,
  Result,
} from 'antd';
import {
  LeftOutlined,
  UserOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import QueueAnim from 'rc-queue-anim';
import classNames from 'classnames';
import {
  getMusician,
  cleanCurrentMusician,
  sendPayment,
  cleanPayment,
  setButtonPayExtraAttrs,
  cleanButtonPayExtraAttrs,
  getInitialInfo,
} from '../../actions';
import {
  FacebookIcon,
  SoundCloudIcon,
  TwitterIcon,
  InstagramIcon,
  SpotifyIcon,
  YoutubeIcon,
  GlobeIcon,
  LinkIcon,
} from '../../utils/CustomIcons';
import {
  CardElement,
  PaymentRequestButtonElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import Merch from './Merch';
import PaymentMethod from './PaymentMethod';
import CCForm from './CCForm';
import './Musician.scss';

const { TextArea } = Input;

function Musician() {
  const { slug } = useParams();
  const {
    data: currentMusician,
    isLoading,
    errors,
  } = useSelector((state) => state.home.currentMusician);
  const {
    success: paymentSuccess,
    isLoading: isPaymentLoading,
    paymentErrors,
  } = useSelector((state) => state.home.payment);
  const {
    clientSecret,
    isLoading: isPaymentRequestButtonLoading,
    paymentRequestButtonErrors,
  } = useSelector((state) => state.home.paymentRequestButton);
  const extraAttrs = useSelector((state) => state.home.buttonPayExtraAttrs);

  const s3Base = useSelector((state) => state.config.s3Base);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const dispatch = useDispatch();
  const [customAmount, setCustomAmount] = useState('Other');
  const [showCustomAmountBtn, setShowCustomAmountBtn] = useState(false);
  const [selectedAmount, setSelectedAmount] = useState(null);

  // Button Payment Utils:
  const [buttonPayAllowedPlatforms, setbuttonPayAllowedPlatforms] = useState({
    applePay: false,
    googlePay: false,
  });
  const [paymentMethodListening, setPaymentMethodListening] = useState(false);
  const [paymentRequestEventResult, setPaymentRequestEventResult] =
    useState(null);

  const [form] = Form.useForm();
  const stripe = useStripe();
  const elements = useElements();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [displayName, setDisplayName] = useState();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [supportMessage, setSupportMessage] = useState();
  const [currentStep, setCurrentStep] = useState(1);
  const [tmpPaymentMethod, setTmpPaymentMethod] = useState(null);
  const [gPayBtn, setGPayBtn] = useState(null);

  const handleCustomAmountEvents = (e) => {
    let tmpValue = e.target.value;
    if (tmpValue !== customAmount) {
      setCustomAmount('$' + tmpValue.replace(/[^0-9]/g, ''));
    }
    if (parseInt(tmpValue.replace(/[^0-9]/g, '')) >= 1) {
      setShowCustomAmountBtn(true);
    } else {
      setShowCustomAmountBtn(false);
    }
  };

  const handleCustomAmountFocus = (e) => {
    console.log(e.target.value);
    let tmpValue = e.target.value;
    if (
      (tmpValue === '' || tmpValue === '$' || tmpValue === 'Other') &&
      customAmount === 'Other'
    ) {
      setCustomAmount('$');
    }
  };

  const handleCustomAmountBlur = (e) => {
    let tmpValue = e.target.value;
    if (tmpValue === '' || tmpValue === '$') {
      setCustomAmount('Other');
    }
  };

  const resetForm = () => {
    setShowSuccessModal(false);
    setCustomAmount('Other');
    setShowCustomAmountBtn(false);
    setSelectedAmount(null);
    setDisplayName('');
    setSupportMessage('');
    setCurrentStep(1);
    setTmpPaymentMethod(null);
    dispatch(cleanPayment()); // Cleaning the payment status after cleaning the success message
    dispatch(cleanButtonPayExtraAttrs()); // Cleaning the extra fields.
    setPaymentRequestEventResult(null); // Cleaning the stripe payment event
  };

  // cDM, cDU & cWD
  useEffect(() => {
    dispatch(getMusician(slug));
    // Cleaning the musician when the component is unmounted:
    return () => {
      dispatch(cleanCurrentMusician(slug));
    };
  }, [dispatch, slug]);

  // catching the success payment serverside.
  useEffect(() => {
    if (!isPaymentLoading && paymentSuccess) {
      setShowSuccessModal(true);
      if (paymentRequestEventResult) {
        // Closing the stripe modal.
        paymentRequestEventResult.complete('success');
      }
    }
  }, [isPaymentLoading, paymentRequestEventResult, paymentSuccess]);

  const onFinish = async (values) => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        // Include any additional collected billing details.
        email: values.email,
        name: values.name,
      },
    });

    if (error) {
      console.log('[error]', error);
      if (
        [
          'incomplete_cvc',
          'incomplete_zip',
          'incomplete_number',
          'incomplete_expiry',
        ].includes(error.code)
      ) {
        form.setFields([
          {
            name: 'creditCard',
            errors: [error.message],
          },
        ]);
      }
    } else {
      form.setFields([{ name: 'creditCard', errors: [] }]); // Cleaning CC errors

      dispatch(
        sendPayment({
          payment_method_id: paymentMethod.id,
          amount: selectedAmount,
          displayName,
          supportMessage,
          currentMusician,
          ...values,
        })
      );
    }
  };

  useEffect(() => {
    if (
      stripe &&
      paymentRequest != null &&
      (selectedAmount || displayName || supportMessage) &&
      currentMusician
    ) {
      paymentRequest.update({
        total: {
          label: 'Tip to ' + currentMusician.name,
          amount: (selectedAmount ? selectedAmount : 1) * 100, // Initial Default Amount
        },
      });
      // Saving this variables on store.
      dispatch(
        setButtonPayExtraAttrs({
          amount: selectedAmount,
          displayName,
          supportMessage,
          currentMusician,
        })
      );
    }
    // return () => {
    //   dispatch(cleanButtonPayExtraAttrs())
    // }
  }, [
    clientSecret,
    currentMusician,
    dispatch,
    displayName,
    paymentRequest,
    selectedAmount,
    stripe,
    supportMessage,
  ]);

  // Updating Apple/Google Pay in local store.
  useEffect(() => {
    if (
      stripe &&
      paymentRequest &&
      !buttonPayAllowedPlatforms.applePay &&
      !buttonPayAllowedPlatforms.googlePay
    ) {
      (async () => {
        const platforms = await paymentRequest.canMakePayment();
        console.log(platforms);
        if (platforms) {
          if (!platforms.applePay) {
            const googlePayClient =
              new window.google.payments.api.PaymentsClient({
                environment: 'TEST',
              });
            if (googlePayClient) {
              const googlePayButton = googlePayClient.createButton({
                buttonColor: 'default',
                buttonType: 'long',
                onClick: () => paymentRequest.show(), // Manually show the paymentRequest
              });
              setGPayBtn(googlePayButton);
            }
          }
          setbuttonPayAllowedPlatforms({
            applePay: !platforms.applePay ? false : true,
            googlePay: platforms.applePay ? false : true,
          });
        }
      })();
    }
  }, [
    buttonPayAllowedPlatforms.applePay,
    buttonPayAllowedPlatforms.googlePay,
    paymentRequest,
    stripe,
  ]);

  // Updating Apple/Google Pay in global store.
  useEffect(() => {
    if (
      (buttonPayAllowedPlatforms.applePay ||
        buttonPayAllowedPlatforms.googlePay) &&
      !extraAttrs.allowedPlaforms.applePay &&
      !extraAttrs.allowedPlaforms.googlePay
    ) {
      console.log('saving in store');
      // Saving this variables in store.
      dispatch(
        setButtonPayExtraAttrs({
          allowedPlaforms: {
            applePay: buttonPayAllowedPlatforms.applePay,
            googlePay: buttonPayAllowedPlatforms.googlePay,
          },
        })
      );
    }
  }, [
    buttonPayAllowedPlatforms.applePay,
    buttonPayAllowedPlatforms.googlePay,
    dispatch,
    extraAttrs.allowedPlaforms.applePay,
    extraAttrs.allowedPlaforms.googlePay,
  ]);

  // Handle paymentMethod event
  useEffect(() => {
    if (stripe && paymentRequest && !paymentMethodListening) {
      setPaymentMethodListening(true);
      paymentRequest.on('paymentmethod', async (ev) => {
        setPaymentRequestEventResult(ev);
      });
    }
  }, [extraAttrs, paymentMethodListening, paymentRequest, stripe]);

  // Hadling Stripe Event Button Pay or Credit Card.
  useEffect(() => {
    if (paymentRequestEventResult) {
      // For button Pay:
      if (
        extraAttrs.allowedPlaforms.applePay ||
        extraAttrs.allowedPlaforms.googlePay
      ) {
        dispatch(
          sendPayment({
            payment_method_id: paymentRequestEventResult.paymentMethod.id,
            ...extraAttrs,
          })
        );
      }
    }
  }, [paymentRequestEventResult]);

  useEffect(() => {
    // Executed just once
    if (stripe && paymentRequest === null) {
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: 'Tip to ' + currentMusician.name,
          amount: (selectedAmount ? selectedAmount : 1) * 100, // Initial Default Amount
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      setPaymentRequest(pr);
    }
  }, [currentMusician.name, paymentRequest, selectedAmount, stripe]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getInitialInfo());
    }
  }, [isAuthenticated]);

  return (
    <Row className='Musician' justify='center' align='middle'>
      {isLoading && <Col>Loading...</Col>}

      {errors.hasError && <Col>{errors.message}</Col>}

      {currentMusician.cover ? (
        <div
          className='Musician__cover'
          style={{ backgroundImage: `url(${s3Base + currentMusician.cover})` }}
        />
      ) : (
        <div
          className='Musician__cover'
          style={{
            backgroundImage: `url(${s3Base}images/utils/default-banner.png)`,
            backgroundPosition: 'bottom',
          }}
        />
      )}

      {!isLoading && !errors.hasError && currentMusician && (
        <Col
          className='Musician__wrapper'
          xs={24}
          sm={22}
          md={22}
          lg={22}
          xl={20}
          xxl={8}
        >
          <Row justify='center' align='middle'>
            <Col
              className='CurrentMusician'
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              xxl={24}
              justify='center'
              align='middle'
            >
              <Modal
                bodyStyle={{
                  background: 'rgba(0,0,0,0)',
                }}
                wrapClassName='SuccessModal'
                title={null}
                centered
                visible={showSuccessModal}
                footer={null}
                onOk={() => {
                  resetForm();
                }}
                onCancel={() => {
                  resetForm();
                }}
              >
                <Result
                  status='success'
                  title={`Tip Successfully Sent!`}
                  subTitle={`Thanks ${displayName} for your $${selectedAmount} tip!, ${currentMusician.name} will be notified in a moment.`}
                  extra={[
                    <Button
                      type='primary'
                      key='console'
                      onClick={() => {
                        resetForm();
                      }}
                    >
                      Perfect, Thanks!
                    </Button>,
                  ]}
                />
              </Modal>
              {currentMusician && currentMusician.avatar ? (
                <div
                  className='Musician__avatar'
                  style={{
                    backgroundImage: `url(${s3Base + currentMusician.avatar})`,
                  }}
                />
              ) : (
                <Avatar
                  className='Musician__avatar'
                  alt={
                    currentMusician.name
                      ? currentMusician.name + ' profile'
                      : ''
                  }
                  size={100}
                  icon={<UserOutlined />}
                />
              )}
              {currentMusician.name && (
                <h1 className='Musician__name'>{currentMusician.name}</h1>
              )}
              {currentMusician.title && (
                <h3 className='Musician__band-name'>{currentMusician.title}</h3>
              )}
              {currentMusician.biography && (
                <p className='Musician__bio'>{currentMusician.biography}</p>
              )}

              <section className='Musician__sn' justify='center' align='middle'>
                {currentMusician &&
                  currentMusician.socialLinks &&
                  currentMusician.socialLinks.length > 0 &&
                  currentMusician.socialLinks.map((sl) => {
                    let slTitle = '';
                    let slIcon = <React.Fragment />;
                    let slLink = sl.link;
                    if (sl.type === 'facebook') {
                      slTitle = 'Facebook';
                      slIcon = <FacebookIcon style={{ width: '30px' }} />;
                    } else if (sl.type === 'instagram') {
                      slTitle = 'Instagram';
                      slIcon = <InstagramIcon style={{ width: '30px' }} />;
                    } else if (sl.type === 'youtube') {
                      slTitle = 'Youtube';
                      slIcon = <YoutubeIcon style={{ width: '30px' }} />;
                    } else if (sl.type === 'twitter') {
                      slTitle = 'Twitter';
                      slIcon = <TwitterIcon style={{ width: '30px' }} />;
                    } else if (sl.type === 'spotify') {
                      slTitle = 'Spotify';
                      slIcon = <SpotifyIcon style={{ width: '30px' }} />;
                    } else if (sl.type === 'soundCloud') {
                      slTitle = 'SoundCloud';
                      slIcon = <SoundCloudIcon style={{ width: '30px' }} />;
                    } else if (sl.type === 'website') {
                      slTitle = 'Website';
                      slIcon = <GlobeIcon style={{ width: '30px' }} />;
                    } else if (sl.type === 'link') {
                      slTitle = 'General Link';
                      slIcon = <LinkIcon style={{ width: '30px' }} />;
                    }
                    if (slLink.lastIndexOf('http://', 0) === 0) {
                      slLink = slLink.replace('http', 'https');
                    } else if (slLink.lastIndexOf('https://', 0) !== 0) {
                      slLink = `https://${slLink}`;
                    }
                    return (
                      <Tooltip className='Musician__sn-item' title={slTitle}>
                        <a
                          href={slLink}
                          target='_blank'
                          rel='noopener noreferrer'
                          className='Musician__sn-item-link'
                        >
                          {slIcon}
                        </a>
                      </Tooltip>
                    );
                  })}
              </section>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              xxl={24}
              className='SupportForMusician'
            >
              {currentMusician.canReceivePayments && (
                <section
                  current-step={currentStep}
                  className={classNames('GlobalReturn', {
                    'amount-selected-active': currentStep !== 1,
                  })}
                  onClick={() => {
                    setTimeout(() => setSelectedAmount(null), 1800 + 800);
                    setCurrentStep(1);
                  }}
                >
                  <LeftOutlined className='arrow-left' />{' '}
                  {'$' + selectedAmount + ' Tip to ' + currentMusician.name}
                </section>
              )}
              <div
                className={classNames('bg-layout', {
                  'amount-selected-active': currentStep !== 1,
                  'no-GoogleApple-btn': !paymentRequest,
                  'not-allowed': !currentMusician.canReceivePayments,
                })}
                current-step={currentStep}
              />
              {!currentMusician.canReceivePayments && (
                <section className='SupportForMusician__wrapper not-allowed'>
                  <h4 className='donations-not-allowed'>
                    This artist's profile is currently private.
                    <br /> Please check back later!
                  </h4>
                </section>
              )}
              {currentMusician.canReceivePayments && (
                <section
                  current-step={currentStep}
                  className={classNames('SupportForMusician__wrapper', {
                    'amount-selected-active': currentStep !== 1,
                  })}
                >
                  <section className='SupportForMusicianContainer'>
                    <section className='Step Step-1'>
                      <QueueAnim
                        appear={false}
                        className='Step--buttons AnimationWrapper'
                        type={['bottom', 'top']}
                        delay={[1600, 500]}
                        interval={[100, 100]}
                        duration={[400, 400]}
                      >
                        {currentStep === 1 && (
                          <h4 key='1' className='Step--title'>
                            Give a tip to the artist
                          </h4>
                        )}
                        {currentStep === 1 && (
                          <p key='2' className='Step--description'>
                            Apple Pay is now available on Safari browser on
                            compatible Apple devices. <br />
                            <br /> Google Pay is now available on Chrome browser
                            on compatible Android devices.
                          </p>
                        )}
                        {currentStep === 1 && (
                          <section key='3' className='amount-btn-wrapper'>
                            <Button
                              className='select-amount-btn'
                              onClick={() => {
                                setSelectedAmount(5);
                                setCurrentStep(2);
                              }}
                            >
                              <div className='select-amount-btn-wrapper'>
                                $5
                              </div>
                            </Button>
                          </section>
                        )}
                        {currentStep === 1 && (
                          <section key='4' className='amount-btn-wrapper'>
                            <Button
                              className='select-amount-btn'
                              onClick={() => {
                                setSelectedAmount(15);
                                setCurrentStep(2);
                              }}
                            >
                              <div className='select-amount-btn-wrapper'>
                                $15
                              </div>
                            </Button>
                          </section>
                        )}
                        {currentStep === 1 && (
                          <section key='5' className='amount-btn-wrapper'>
                            <Button
                              className='select-amount-btn'
                              onClick={() => {
                                setSelectedAmount(20);
                                setCurrentStep(2);
                              }}
                            >
                              <div className='select-amount-btn-wrapper'>
                                $20
                              </div>
                            </Button>
                          </section>
                        )}
                        {currentStep === 1 && (
                          <section key='6' className='amount-btn-wrapper'>
                            <div className='select-amount-custom'>
                              <div className='select-amount-custom-wrapper'>
                                <input
                                  className='custom_amount transparent-number-field'
                                  type='text'
                                  pattern='[0-9]*'
                                  name='custom_amount'
                                  value={customAmount}
                                  placeholder='$25'
                                  onBlur={handleCustomAmountBlur}
                                  onChange={handleCustomAmountEvents}
                                  onFocus={handleCustomAmountFocus}
                                  onKeyPress={(e) => {
                                    if (
                                      e.key === 'Enter' &&
                                      showCustomAmountBtn
                                    ) {
                                      setSelectedAmount(
                                        customAmount.replace(/[^0-9]/g, '')
                                      );
                                      setCurrentStep(2);
                                    }
                                  }}
                                />
                                <Button
                                  className={classNames(
                                    'select-amount-custom-btn',
                                    { show: showCustomAmountBtn }
                                  )}
                                  onClick={() => {
                                    setSelectedAmount(
                                      customAmount.replace(/[^0-9]/g, '')
                                    );
                                    setCurrentStep(2);
                                  }}
                                >
                                  NEXT
                                </Button>
                              </div>
                            </div>
                          </section>
                        )}
                      </QueueAnim>
                    </section>
                    <section className='Step Step-2' key='step-2'>
                      <PaymentMethod
                        render={currentStep === 2}
                        tmpPaymentMethod={tmpPaymentMethod}
                        onCCClick={() => {
                          setCurrentStep(3);
                          setTmpPaymentMethod('CC');
                        }}
                        onAPClick={() => {
                          if (extraAttrs.allowedPlaforms.applePay) {
                            setCurrentStep(3);
                            setTmpPaymentMethod('AP');
                          } else {
                            setTmpPaymentMethod('AP');
                          }
                        }}
                        onGPClick={() => {
                          if (extraAttrs.allowedPlaforms.googlePay) {
                            setCurrentStep(3);
                            setTmpPaymentMethod('GP');
                          } else {
                            setTmpPaymentMethod('GP');
                          }
                        }}
                      />
                    </section>
                    <section className='Step Step-3' key='step-3'>
                      <QueueAnim
                        className='AnimationWrapper'
                        type={['bottom', 'top']}
                        delay={[1600, 500]}
                        interval={[100, 100]}
                        duration={[400, 400]}
                      >
                        {currentStep === 3 && (
                          <h4 className='SupportMessage__title' key='1'>
                            Let your artist know...
                          </h4>
                        )}
                        {currentStep === 3 && (
                          <Input
                            className='DisplayName__input'
                            key='2'
                            value={displayName}
                            onChange={(e) => setDisplayName(e.target.value)}
                            placeholder='Display Name (optional)'
                            maxLength={25}
                            suffix={
                              <Tooltip title='Let your artist know who you are'>
                                <InfoCircleOutlined
                                  style={{ color: 'rgba(0,0,0,.45)' }}
                                />
                              </Tooltip>
                            }
                          />
                        )}
                        {currentStep === 3 && (
                          <h4 className='SupportMessage__title' key='3'>
                            Add a message
                          </h4>
                        )}
                        {currentStep === 3 && (
                          <TextArea
                            className='SupportMessage__textarea'
                            key='4'
                            value={supportMessage}
                            onChange={(e) => setSupportMessage(e.target.value)}
                            autoSize={{ minRows: 3 }}
                            placeholder='You can leave your comments here (optional)'
                          />
                        )}
                        {currentStep === 3 &&
                          tmpPaymentMethod === 'AP' &&
                          extraAttrs.allowedPlaforms.applePay &&
                          !gPayBtn && (
                            <PaymentRequestButtonElement
                              className='ApplePayBtn'
                              key='5'
                              options={{ paymentRequest }}
                            />
                          )}
                        {currentStep === 3 &&
                          tmpPaymentMethod === 'GP' &&
                          extraAttrs.allowedPlaforms.googlePay &&
                          gPayBtn && (
                            <div
                              className='GooglePayBtn'
                              key='5'
                              onClick={() => paymentRequest.show()}
                              dangerouslySetInnerHTML={{
                                __html: gPayBtn && gPayBtn.innerHTML,
                              }}
                            ></div>
                          )}
                        {currentStep === 3 && tmpPaymentMethod === 'CC' && (
                          <Button
                            key='5'
                            justify='center'
                            className={'btn-custom-submit checkout-button'}
                            type='primary'
                            htmlType='button'
                            onClick={() => setCurrentStep(4, 'CC')}
                          >
                            Continue to Payment
                          </Button>
                        )}
                      </QueueAnim>
                    </section>
                    <section className='Step Step-4' key='step-4'>
                      {currentStep === 4 && (
                        <CCForm
                          render={currentStep === 4}
                          onFinish={onFinish}
                          btnText='Submit Payment'
                        />
                      )}
                    </section>
                  </section>
                </section>
              )}
            </Col>
            <Merch />
          </Row>
        </Col>
      )}
    </Row>
  );
}

export default Musician;
