import { createBrowserHistory } from 'history';
import { applyMiddleware, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createEpicMiddleware } from 'redux-observable';
import thunk from 'redux-thunk';
import { createPromise } from 'redux-promise-middleware';
import jwtDecode from 'jwt-decode';
import rootReducer from './reducers/index';
import rootEpic from './epics/index';
import baseAttributes from './storeBaseAttributes';

const epicMiddleware = createEpicMiddleware();

const { token } = localStorage;
const decoded = token ? jwtDecode(token) : null;
const auth = decoded
  ? {
      user: decoded.user,
      token,
      isAuthenticated: true,
    }
  : {};

// create an object for the default data
const defaultState = {
  config: {
    isMainMenuOpen: false,
    s3Base: 'https://museit.s3.us-west-1.amazonaws.com/',
  },
  home: {
    currentMusician: {
      ...baseAttributes,
      data: {},
    },
    payment: {
      ...baseAttributes,
      requires_action: false,
      success: false,
    },
    paymentRequestButton: {
      ...baseAttributes,
      clientSecret: '',
    },
    buttonPayExtraAttrs: {
      amount: null,
      displayName: '',
      supportMessage: '',
      currentMusician: null,
      allowedPlaforms: {
        applePay: false,
        googlePay: false,
      },
      rehydrated: false,
    },
  },
  shopify: {
    storeName: {
      ...baseAttributes,
      data: {},
    },
  },
  auth: {
    isAuthenticated: false,
    rehydrated: false,
    token: '',
    user: {},
    isUpdating: false,
    isRequestingAvatarURL: false,
    isUploadingAvatar: false,
    signedAvatarUploadURL: '',
    isRequestingCoverURL: false,
    isUploadingCover: false,
    signedCoverUploadURL: '',
    recoverEmailSent: false,
    tmpAuth: {
      user: null,
      token: null,
    },
    updated: false, // Flag to catch when profile info is updated
    subscribed: false, // Flag to catch when potential user is subscribed to receive notifications.
    ...baseAttributes,
    ...auth, // This tries to update the token info if token exists in sessionStorage
    stripe: {
      connectOAuthLink: null,
      code: null,
      state: null,
      ...baseAttributes,
      isAuthorizing: false,
    },
    isUpdatingProduct: false,
  },
  user: {
    earningsByMonth: {
      ...baseAttributes,
      data: [],
      rehydrated: false,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
    },
    payments: {
      ...baseAttributes,
      data: [],
      rehydrated: false,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
    },
    merchSales: {
      ...baseAttributes,
      data: [],
      rehydrated: false,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
    },
    merchCache: {
      ...baseAttributes,
      data: [],
      rehydrated: false,
    },
    payouts: {
      ...baseAttributes,
      data: [],
      rehydrated: false,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
    },
    earnings: {
      ...baseAttributes,
      data: {
        currentEarnings: 0,
        paidEarnings: 0,
        totalEarnings: 0,
      },
    },
  },
  admin: {
    users: {
      ...baseAttributes,
      data: [],
      rehydrated: false,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      selectedUser: null,
      isDownloadingPDF: false,
      isDownloadingQR: false,
    },
    payouts: {
      ...baseAttributes,
      data: [],
      rehydrated: false,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
    },
    payments: {
      ...baseAttributes,
      data: [],
      rehydrated: false,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
    },
  },
};

const composeEnhancers = composeWithDevTools({
  // Specify here name, actionsBlacklist, actionsCreators and other options if needed
});

export const history = createBrowserHistory();

const store = createStore(
  rootReducer(history), // root reducer with router state
  defaultState,
  // compose(
  composeEnhancers(
    applyMiddleware(
      routerMiddleware(history), // for dispatching history actions
      epicMiddleware,
      thunk,
      createPromise()
      // ... other middlewares ...
    )
  )
);

epicMiddleware.run(rootEpic);

export default store;

// Function to get users current token
export const getToken = () => {
  return store.getState().auth.token;
};
