import baseAttributes from '../storeBaseAttributes';
// import { filter } from 'lodash';

const userReducer = (state = {}, action) => {
  switch (action.type) {
    case 'GET_PAYOUTS_PENDING':
      return {
        ...state,
        payouts: {
          ...state.payouts,
          ...baseAttributes,
          isLoading: true,
        },
      };
    case 'GET_PAYOUTS_ERROR':
      return {
        ...state,
        payouts: {
          ...state.payouts,
          ...baseAttributes,
          errors: {
            ...state.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'GET_PAYOUTS_ABORTED':
      return {
        ...state,
        payouts: {
          ...state.payouts,
          ...baseAttributes,
        },
      };
    case 'GET_PAYOUTS_SUCCESS':
      return {
        ...state,
        payouts: {
          ...state.payouts,
          ...baseAttributes,
          data: action.payload.payouts,
          pagination: action.payload.pagination,
          rehydrated: true,
        },
      };
    case 'GET_PAYMENTS_PENDING':
      return {
        ...state,
        payments: {
          ...state.payments,
          ...baseAttributes,
          isLoading: true,
        },
      };
    case 'GET_PAYMENTS_ERROR':
      return {
        ...state,
        payments: {
          ...state.payments,
          ...baseAttributes,
          errors: {
            ...state.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'GET_PAYMENTS_ABORTED':
      return {
        ...state,
        payments: {
          ...state.payments,
          ...baseAttributes,
        },
      };
    case 'GET_PAYMENTS_SUCCESS':
      return {
        ...state,
        payments: {
          ...state.payments,
          ...baseAttributes,
          data: action.payload.payments,
          pagination: action.payload.pagination,
          rehydrated: true,
        },
      };
    case 'GET_MERCH_SALES_PENDING':
      return {
        ...state,
        merchSales: {
          ...state.merchSales,
          ...baseAttributes,
          isLoading: true,
        },
      };
    case 'GET_MERCH_SALES_ERROR':
      return {
        ...state,
        merchSales: {
          ...state.merchSales,
          ...baseAttributes,
          errors: {
            ...state.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'GET_MERCH_SALES_ABORTED':
      return {
        ...state,
        merchSales: {
          ...state.merchSales,
          ...baseAttributes,
        },
      };
    case 'GET_MERCH_SALES_SUCCESS':
      return {
        ...state,
        merchSales: {
          ...state.merchSales,
          ...baseAttributes,
          data: action.payload.merchSales,
          pagination: action.payload.pagination,
          rehydrated: true,
        },
      };
    case 'GET_MERCH_CACHE_PENDING':
      return {
        ...state,
        merchCache: {
          ...state.merchCache,
          ...baseAttributes,
          isLoading: true,
        },
      };
    case 'GET_MERCH_CACHE_ERROR':
      return {
        ...state,
        merchCache: {
          ...state.merchCache,
          ...baseAttributes,
          errors: {
            ...state.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'GET_MERCH_CACHE_ABORTED':
      return {
        ...state,
        merchCache: {
          ...state.merchCache,
          ...baseAttributes,
        },
      };
    case 'GET_MERCH_CACHE_SUCCESS':
      return {
        ...state,
        merchCache: {
          ...state.merchCache,
          ...baseAttributes,
          data: action.payload.merchCache,
          pagination: action.payload.pagination,
          rehydrated: true,
        },
      };
    case 'UPDATE_PRODUCT_SUCCESS':
      return {
        ...state,
        merchCache: {
          ...baseAttributes,
          ...state.merchCache,
          rehydrated: false,
        },
      };
    case 'GET_EARNINGS_BY_MONTH_PENDING':
      return {
        ...state,
        earningsByMonth: {
          ...state.earningsByMonth,
          ...baseAttributes,
          isLoading: true,
        },
      };
    case 'GET_EARNINGS_BY_MONTH_ERROR':
      return {
        ...state,
        earningsByMonth: {
          ...state.earningsByMonth,
          ...baseAttributes,
          errors: {
            ...state.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'GET_EARNINGS_BY_MONTH_ABORTED':
      return {
        ...state,
        earningsByMonth: {
          ...state.earningsByMonth,
          ...baseAttributes,
        },
      };
    case 'GET_EARNINGS_BY_MONTH_SUCCESS':
      return {
        ...state,
        earningsByMonth: {
          ...state.earningsByMonth,
          ...baseAttributes,
          data: action.payload.earningsByMonth,
          pagination: action.payload.pagination,
          rehydrated: true,
        },
      };

    case 'GET_EARNINGS_PENDING':
      return {
        ...state,
        earnings: {
          ...state.earnings,
          ...baseAttributes,
          isLoading: true,
        },
      };
    case 'GET_EARNINGS_ERROR':
      return {
        ...state,
        earnings: {
          ...state.earnings,
          ...baseAttributes,
          errors: {
            ...state.errors,
            hasError: action.payload.error,
            code: action.payload.code,
            message: action.payload.message,
            all: action.payload.errors,
          },
        },
      };
    case 'GET_EARNINGS_ABORTED':
      return {
        ...state,
        earnings: {
          ...state.earnings,
          ...baseAttributes,
        },
      };
    case 'GET_EARNINGS_SUCCESS':
      return {
        ...state,
        earnings: {
          ...state.earnings,
          ...baseAttributes,
          data: action.payload.earnings,
        },
      };
    default:
      return state;
  }
};

export default userReducer;
