import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import homeReducer from "./homeReducer";
import authReducer from "./authReducer";
import userReducer from "./userReducer";
import configReducer from "./configReducer";
import adminReducer from "./adminReducer";
import shopifyReducer from "./shopifyReducer";

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    home: homeReducer,
    auth: authReducer,
    user: userReducer,
    config: configReducer,
    admin: adminReducer,
    shopify: shopifyReducer,
  });

export default rootReducer;
