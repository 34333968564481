import React, { useEffect } from "react"
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { Row, Col } from 'antd'

import SignInForm from './SignInForm';
import ArtBubbles from '../art-bubbles/ArtBubbles';
import './SignIn.scss';

export default function SignIn (){
  const { token, isAuthenticated, user } = useSelector(state => state.auth);
  let history = useHistory()

  useEffect(() => {
    if(token && isAuthenticated){
      if (user && user.musician && user.musician.avatar && user.musician.biography && user.musician.slug && user.stripe && user.stripe.connected === true && user.last4ssn) {
        history.replace('/my-account/my-money')
      } else {
        history.replace('/my-account')
      }
    }
  }, [token, isAuthenticated, history])

  return (
    <Row className="SignIn" justify="center" align="middle">
      {(!isAuthenticated) && (
        <Col xs={24} sm={24} md={22} lg={22} xl={20} xxl={18}>
          <Row className="SignIn__wrapper" justify="center" align="middle">
            <Col className="SignIn__art-wrapper" xs={22} sm={18} md={18} lg={{ span: 11 }} xl={{ span: 9 }} xxl={{ span: 9 }}>
              <ArtBubbles />
            </Col>
            <Col className="SignIn__form-wrapper" xs={22} sm={18} md={18} lg={{ span: 8, offset: 2 }} xl={{ span: 8, offset: 2 }} xxl={{ span: 7, offset: 2 }}>
              <h1 className="SignIn__title">Sign In</h1>
              <p className="SignIn__details">Sign into your Museit account</p>
              <SignInForm />
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  )
}
