import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { message, Button, Form, Input, notification } from 'antd'
import { recover, clearRecoverEmailFlag } from '../../actions'
import './RecoverForm.scss'



export default function RecoverForm () {
  const [form] = Form.useForm()
  const { isLoading, errors: authErrors, recoverEmailSent } = useSelector(state => state.auth);
  const dispatch = useDispatch()

  const onFinish = values => {
    dispatch(recover(values.identity))
  };

  // When the recover email is sent successfully.
  useEffect(() => {
    if(recoverEmailSent){
      message.success('We sent you an email to retrieve your password. Sometimes this email may take a couple of minutes before it reaches your mailbox.')
    }
    return () => {
      dispatch(clearRecoverEmailFlag())
    }
  }, [recoverEmailSent, dispatch])

  useEffect(() => {
    if(authErrors.hasError){
      if(['E_EMAIL_NOT_PROVIDED', 'E_EMAIL_NOT_FOUND'].includes(authErrors.code)){
        form.setFields([
           {
             name: 'identity',
             errors: [authErrors.message],
           },
        ]);
      }
      else {
        notification.error({
          message: 'Error',
          description: authErrors.message,
          duration: 0,
          key: 'recover-notification',
          onClick: () => {
            notification.close('recover-notification')
          }
        })
      }
    }
  }, [authErrors, form])

  return (
    <Form
      layout="vertical"
      size="large"
      form={form}
      name="signin"
      onFinish={onFinish}
      initialValues={{}}
      scrollToFirstError
    >
      <h1 className="Recover__title">Access Recovery</h1>
      <Form.Item
        name="identity"
        rules={[
          {
            type: 'email',
            message: 'Please enter a valid Email',
          },
          {
            required: true,
            message: 'Email is required',
          },
        ]}
      >
        <Input type="email" placeholder="Your Email*" autoCorrect="off" autoCapitalize="none" />
      </Form.Item>
      <Form.Item justify="center" noStyle>
        <Button className={'btn-custom-submit'} type="primary" htmlType="submit" disabled={isLoading} loading={isLoading}>
          Recover My Access
        </Button>
      </Form.Item>
    </Form>
  );
}
