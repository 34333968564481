/* eslint-disable import/prefer-default-export */

export const getUsers = (params) => ({
  type: 'GET_USERS',
  payload: {
    params
  },
});

export const getAdminPayouts = (params) => ({
  type: 'GET_ADMIN_PAYOUTS',
  payload: {
    params
  },
});

export const getAdminPayoutPayments = (payoutId) => ({
  type: 'GET_ADMIN_PAYOUT_PAYMENTS',
  payload: {
    payoutId
  },
});

export const abortAdminPayoutPayments = (payoutId) => ({
  type: 'GET_ADMIN_PAYOUT_PAYMENTS_ABORTED',
  payload: {
    payoutId
  },
});

export const getAdminPayments = (params) => ({
  type: 'GET_ADMIN_PAYMENTS',
  payload: {
    params
  },
});

export const selectUserToEdit = (user) => ({
  type: 'SELECT_USER_TO_EDIT',
  payload: {
    user
  },
});

export const setDownloadingPDF = (isDownloadingPDF) => ({
  type: 'SET_DOWNLOADING_PDF',
  payload: {
    isDownloadingPDF
  },
});

export const setDownloadingQR = (isDownloadingQR) => ({
  type: 'SET_DOWNLOADING_QR',
  payload: {
    isDownloadingQR
  },
});

